/* ------------------------------------------------------------------------------
 *
 *  # Components
 *
 *  Common less file with imports of plugins and pages
 *
 *  Version: 1.0
 *  Latest update: Sep 1st, 2015
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Sidebar layouts
 *
 *  Styles for sidebar components, main navigation and sidebar itself
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.sidebar {
  background-color: #263238;
  color: #fff;
  position: relative;
  width: 100%;
  display: none;
}
@media (min-width: 769px) {
  .sidebar {
    display: table-cell;
    vertical-align: top;
    width: 260px;
  }
}
.sidebar-main {
  z-index: 99;
}
.sidebar-secondary {
  z-index: 98;
}
.sidebar-opposite {
  display: none;
  z-index: 97;
}
@media (min-width: 769px) {
  .sidebar-opposite-visible .sidebar-opposite {
    display: table-cell;
  }
}
.sidebar:not(.sidebar-default) + .sidebar:not(.sidebar-default) {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebar-default {
  background-color: #ffffff;
  color: #333333;
  border-bottom: 1px solid #dddddd;
}
@media (min-width: 769px) {
  .sidebar-default {
    border-bottom: 0;
    border-right: 1px solid #dddddd;
  }
  .content-wrapper + .sidebar-default {
    border-left: 1px solid #dddddd;
  }
}
.sidebar-content {
  position: relative;
}
.sidebar-all-hidden .sidebar-main,
.sidebar-all-hidden .sidebar-secondary,
.sidebar-main-hidden .sidebar-main,
.sidebar-detached-hidden .sidebar-detached > .sidebar,
.sidebar-secondary-hidden .sidebar-secondary {
  display: none;
}
@media (max-width: 768px) {
  .sidebar-mobile-main .sidebar-main,
  .sidebar-mobile-secondary .sidebar-secondary,
  .sidebar-mobile-opposite .sidebar-opposite,
  .sidebar-mobile-detached .sidebar-detached > .sidebar {
    display: block;
  }
}
.category-title {
  position: relative;
  margin: 0;
  padding: 10px 20px;
  padding-right: 46px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.category-title > span {
  display: block;
  margin-top: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.82;
}
.category-title > i {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.category-title .icons-list {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.sidebar-default .category-title {
  border-bottom-color: #dddddd;
}
.category-content {
  position: relative;
  padding: 20px;
}
.navigation {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  position: relative;
}
.sidebar-user + .sidebar-category .navigation {
  padding-top: 0;
}
.navigation .hidden-ul {
  display: none;
}
.navigation li {
  position: relative;
}
.navigation li + li {
  margin-top: 1px;
}
.navigation li + .navigation-header {
  margin-top: 10px;
}
.navigation li a {
  color: rgba(255, 255, 255, 0.75);
  display: block;
  -webkit-transition: background 0.15s linear, color 0.15s linear;
  -o-transition: background 0.15s linear, color 0.15s linear;
  transition: background 0.15s linear, color 0.15s linear;
}
.navigation li a:hover,
.navigation li a:focus {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}
.navigation li a > i {
  float: left;
  top: 0;
  margin-top: 2px;
  margin-right: 15px;
  -webkit-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.navigation li a > i.pull-right {
  margin-right: 0;
  margin-left: 15px;
}
.navigation li.disabled > a,
.navigation li.disabled > a:hover,
.navigation li.disabled > a:focus {
  color: inherit;
  background-color: transparent;
  cursor: not-allowed;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.navigation li > .has-ul {
  position: relative;
  padding-right: 36px;
}
.navigation li > .has-ul:after {
  content: "\edbb";
  font-family: "icomoon";
  font-size: 16px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 20px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -o-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
}
.navigation li.active > .has-ul:after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.navigation li.navigation-divider {
  margin: 10px 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}
.sidebar-default .navigation li.navigation-divider {
  background-color: #eeeeee;
}
.navigation > li > a {
  padding: 12px 20px;
  min-height: 44px;
  font-weight: 500;
}
.navigation > li.active > a,
.navigation > li.active > a:hover,
.navigation > li.active > a:focus {
  background-color: #26a69a;
  color: #fff;
}
.navigation > li.active > a > [class*=text-] {
  color: #fff;
}
.navigation > li ul {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
}
.navigation > li ul li a {
  padding: 10px 20px;
  padding-left: 51px;
  min-height: 40px;
}
.navigation > li ul .navigation-header {
  padding-left: 51px;
}
.navigation > li > ul > li > ul > li > a,
.navigation > li > ul > li > ul > .navigation-header {
  padding-left: 71px;
}
.navigation > li > ul > li > ul > li > ul > li > a,
.navigation > li > ul > li > ul > li > ul .navigation-header {
  padding-left: 91px;
}
.navigation > li > ul li:first-child {
  padding-top: 10px;
}
.navigation > li > ul li:last-child {
  padding-bottom: 10px;
}
.navigation > li > ul li.active > a,
.navigation > li > ul li.active > a:hover,
.navigation > li > ul li.active > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}
.navigation .navigation-header {
  min-height: 30px;
  padding: 10px 20px;
  border-bottom: 0;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.82;
}
.navigation .navigation-header > span {
  display: block;
  margin-top: 2px;
}
.navigation .navigation-header > i {
  display: none;
}
.navigation .navigation-header > i.pull-right {
  margin-top: 2px;
}
.navigation .navigation-header,
.navigation .navigation-header a {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.navigation .navigation-header a:hover,
.navigation .navigation-header a:focus {
  color: #fff;
}
.navigation .navigation-header a i {
  float: none;
  margin: 0;
}
.sidebar-default .navigation .navigation-header,
.sidebar-default .navigation .navigation-header a {
  color: #999999;
}
.sidebar-default .navigation .navigation-header a:hover,
.sidebar-default .navigation .navigation-header a:focus {
  color: #333333;
}
.navigation .label,
.navigation .badge {
  float: right;
  margin-top: 1px;
}
.sidebar-default .navigation li > a {
  color: #333333;
}
.sidebar-default .navigation li > a:hover,
.sidebar-default .navigation li > a:focus {
  background-color: #f5f5f5;
}
.sidebar-default .navigation li.active > a,
.sidebar-default .navigation li.active > a:hover,
.sidebar-default .navigation li.active > a:focus {
  background-color: #f5f5f5;
  color: #333333;
}
.sidebar-default .navigation li.disabled > a,
.sidebar-default .navigation li.disabled > a:hover,
.sidebar-default .navigation li.disabled > a:focus {
  background-color: transparent;
}
.sidebar-default .navigation > li ul {
  background-color: transparent;
}
.sidebar-default .navigation > li.active > a,
.sidebar-default .navigation > li.active > a:hover,
.sidebar-default .navigation > li.active > a:focus {
  background-color: #26a69a;
  color: #fff;
}
.navigation-icons-right > li > a,
.navigation-icons-right > li > a.has-ul {
  padding-right: 20px;
}
.navigation-icons-right > li > a > i {
  float: right;
  margin-right: 0;
}
.navigation-icons-right > li > a.has-ul:after,
.navigation-icons-right > li.active > .has-ul:after {
  content: none;
}
.navigation-bordered > li {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.navigation-bordered > li:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.navigation-bordered > li.navigation-header {
  background-color: rgba(0, 0, 0, 0.1);
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 1;
}
.navigation-bordered > li + li {
  margin-top: 0;
}
.navigation-bordered > li ul {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navigation-bordered li + .navigation-header {
  margin-top: 0;
}
.sidebar-default .navigation-bordered > li {
  border-top: 1px solid #eeeeee;
}
.sidebar-default .navigation-bordered > li.navigation-header {
  background-color: #fafafa;
}
.navigation-lg > li > a,
.navigation-lg > li > a > span {
  padding-top: 14px;
  padding-bottom: 14px;
  min-height: 48px;
}
.navigation-lg > li ul li a {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 40px;
}
.sidebar-xs .sidebar-main .navigation-lg > li > ul {
  top: 48px;
}
.navigation-sm > li > a,
.navigation-sm > li > a > span {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 40px;
}
.navigation-sm > li ul li a {
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 32px;
}
.sidebar-xs .sidebar-main .navigation-sm > li > ul {
  top: 40px;
}
.navigation-xs > li > a,
.navigation-xs > li > a > span {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 36px;
}
.navigation-xs > li ul li a {
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 32px;
}
.sidebar-xs .sidebar-main .navigation-xs > li > ul {
  top: 36px;
}
@media (min-width: 769px) {
  .sidebar-fixed .sidebar-content {
    position: fixed;
    width: 260px;
    max-height: 100%;
    overflow: auto;
    top: 46px;
    bottom: 46px;
    margin-bottom: -46px;
  }
  .sidebar-xs .sidebar-fixed.sidebar-main .sidebar-content {
    width: 56px;
  }
  .sidebar-fixed-expanded .sidebar-fixed.sidebar-main {
    position: fixed;
    display: block;
    height: 100%;
    z-index: 1001;
  }
  .sidebar-fixed-expanded .sidebar-fixed.sidebar-main .sidebar-content {
    position: fixed;
  }
  .sidebar-xs .sidebar-fixed-expanded .sidebar-fixed.sidebar-main {
    width: 260px;
  }
  .sidebar-fixed-expanded .content-wrapper {
    padding-left: 56px;
  }
}
.sidebar-xs-indicator .sidebar-fixed .sidebar-content {
  top: 0!important;
}
@media (min-width: 769px) {
  .has-detached-left .container-detached {
    float: right;
    margin-left: -260px;
    width: 100%;
  }
  .has-detached-left .content-detached {
    margin-left: 280px;
  }
  .has-detached-left .sidebar-detached {
    float: left;
  }
  .has-detached-right .container-detached {
    float: left;
    margin-right: -260px;
    width: 100%;
  }
  .has-detached-right .content-detached {
    margin-right: 280px;
  }
  .has-detached-right .sidebar-detached {
    float: right;
  }
  .has-detached-right .sidebar-detached.affix {
    right: 20px;
  }
  .sidebar-detached-hidden .container-detached {
    float: none;
    margin: 0;
  }
  .sidebar-detached-hidden .content-detached {
    margin: 0;
  }
  .sidebar-detached-hidden .sidebar-detached {
    float: none;
  }
}
.sidebar-detached .navigation.nav > .active > .hidden-ul {
  display: block;
}
@media (max-width: 768px) {
  .sidebar-detached .navigation.nav > li > .hidden-ul {
    display: block;
  }
}
.sidebar-detached.affix {
  position: static;
}
@media (min-width: 769px) {
  .sidebar-detached {
    display: block;
    position: relative;
    margin-bottom: 0;
  }
  .sidebar-detached > .sidebar-default {
    border: 1px solid #dddddd;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }
  .sidebar-detached > .sidebar {
    margin-bottom: 0;
    display: block;
    border-radius: 3px;
  }
  .sidebar-detached.affix {
    position: fixed;
    top: 20px;
    bottom: 20px;
  }
  .sidebar-detached.affix > .sidebar {
    max-height: 100%;
    overflow-y: auto;
  }
  .sidebar-detached.fixed-sidebar-space {
    bottom: 60px;
  }
}
@media (min-width: 769px) {
  .sidebar-separate {
    background-color: transparent;
  }
  .sidebar-separate .sidebar-category {
    background-color: #263238;
    border-radius: 3px;
    margin-bottom: 20px;
  }
  .sidebar-separate.sidebar-default {
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .sidebar-separate.sidebar-default .sidebar-category {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }
}
@media (min-width: 769px) {
  .sidebar-xs .sidebar-main {
    width: 56px;
  }
  .sidebar-xs .sidebar-main .sidebar-category {
    display: none;
  }
  .sidebar-xs .sidebar-main .sidebar-category-visible {
    display: block;
  }
  .sidebar-xs .sidebar-main .category-title {
    padding: 0;
  }
  .sidebar-xs .sidebar-main .category-title > i {
    padding: 13px 0;
    float: none;
    display: block;
    top: 0;
  }
  .sidebar-xs .sidebar-main .category-title > span {
    display: none;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list {
    position: static;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list > li {
    display: block;
    margin-left: 0;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list > li + li {
    margin-top: 10px;
  }
  .sidebar-xs .sidebar-main .navigation-main > li > a {
    display: block;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .navigation-main > li > a > span {
    display: none;
    position: absolute;
    top: 0;
    right: -260px;
    background-color: #26a69a;
    border-color: #26a69a;
    padding: inherit;
    padding-left: 20px;
    padding-right: 20px;
    width: 260px;
    text-align: left;
    color: #fff;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
  .sidebar-xs .sidebar-main .navigation-main > li > a > span:after {
    content: "";
    width: 0;
    height: 0;
    border-right: 5px solid;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right-color: inherit;
    position: absolute;
    left: -5px;
    top: 50%;
    margin-top: -5px;
  }
  .sidebar-xs .sidebar-main .navigation-main > li > a > i {
    margin: 2px 0;
    display: block;
    float: none;
  }
  .sidebar-xs .sidebar-main .navigation-main > li > .has-ul:after,
  .sidebar-xs .sidebar-main .navigation-main > li.active > .has-ul:after {
    content: none;
  }
  .sidebar-xs .sidebar-main .navigation-main > li .has-ul > span {
    border-radius: 0 3px 0 0;
  }
  .sidebar-xs .sidebar-main .navigation-main > li.active > ul {
    display: none!important;
  }
  .sidebar-xs .sidebar-main .navigation-main > li.disabled:hover > ul,
  .sidebar-xs .sidebar-main .navigation-main > li.disabled:hover > a > span {
    display: none!important;
  }
  .sidebar-xs .sidebar-main .navigation-main > li:hover > ul {
    display: block!important;
  }
  .sidebar-xs .sidebar-main .navigation-main > li:hover > a > span {
    display: block;
    cursor: default;
  }
  .sidebar-xs .sidebar-main .navigation-main > li:hover:not(.active) > a {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
  }
  .sidebar-xs .sidebar-main .navigation-main > li > ul {
    position: absolute;
    right: -260px;
    top: 44px;
    width: 260px;
    display: none;
    background-color: #263238;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0 0 3px 0;
  }
  .sidebar-xs .sidebar-main .navigation-main > li > ul > li > a {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sidebar-xs .sidebar-main .navigation-main > li > ul > li > ul > li > a {
    padding-left: 30px;
  }
  .sidebar-xs .sidebar-main .navigation-main > li > ul > li > ul > li > ul > li > a {
    padding-left: 60px;
  }
  .sidebar-xs .sidebar-main .navigation-main > .navigation-header {
    padding: 0;
    text-align: center;
  }
  .sidebar-xs .sidebar-main .navigation-main > .navigation-header > i {
    display: block;
    top: 0;
    padding: 13px 0;
  }
  .sidebar-xs .sidebar-main .navigation-main > .navigation-header > span {
    display: none;
  }
  .sidebar-xs .sidebar-main .sidebar-user .category-content {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left,
  .sidebar-xs .sidebar-main .sidebar-user .media-right {
    padding: 0;
    text-align: center;
    display: block;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > img,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > img {
    max-width: 100%;
    height: auto!important;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > .img-sm,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > .img-sm {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > .img-xs,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > .img-xs {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-body,
  .sidebar-xs .sidebar-main .sidebar-user .media-right {
    display: none;
  }
  .sidebar-xs .sidebar-default .navigation-main > li > a > span {
    background-color: #26a69a;
    border-color: #26a69a;
  }
  .sidebar-xs .sidebar-default .navigation-main > li > ul {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-top: 0;
  }
  .sidebar-xs .sidebar-default .navigation-main > li:hover:not(.active) > a {
    background-color: #f5f5f5;
    color: #333333;
  }
}
.sidebar .row {
  margin-left: -5px;
  margin-right: -5px;
}
.sidebar .row [class*=col-] {
  padding-left: 5px;
  padding-right: 5px;
}
@media (max-width: 768px) {
  .sidebar .sp-container {
    display: block;
    width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}
.sidebar .sp-flat .sp-picker-container {
  display: block;
  width: 218px;
}
.sidebar .panel-group .panel {
  border-radius: 0;
  border-width: 0 0 1px 0;
}
.sidebar .panel-group .panel:first-child {
  border-top-width: 1px;
}
.sidebar .panel-group .panel + .panel {
  margin-top: 0;
}
.sidebar .media-list-bordered > li {
  border-top: 0;
  border-bottom: 1px solid #eeeeee;
}
.sidebar:not(.sidebar-default) .media .text-muted,
.sidebar:not(.sidebar-default) .media .media-annotation {
  color: rgba(255, 255, 255, 0.8);
}
.sidebar:not(.sidebar-default) .media .media-left > a,
.sidebar:not(.sidebar-default) .media .media-body > a,
.sidebar:not(.sidebar-default) .media .media-right > a {
  color: #fff;
}
.sidebar:not(.sidebar-default) .media .media-link:hover,
.sidebar:not(.sidebar-default) .media .media-link:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar:not(.sidebar-default) .media .media-badge {
  border-color: #263238;
}
.sidebar:not(.sidebar-default) .media-list-bordered > li {
  border-color: rgba(255, 255, 255, 0.1);
}
.sidebar .thumbnail {
  margin-bottom: 10px;
}
.sidebar .thumbnail:last-child {
  margin-bottom: 0;
}
.sidebar .thumbnail .zoom-image i {
  font-size: 16px;
  margin-top: -8px;
  margin-left: -8px;
}
.sidebar .sidebar-category .checkbox,
.sidebar .sidebar-category .radio {
  margin-top: 0;
}
.sidebar .sidebar-category .checkbox:last-child,
.sidebar .sidebar-category .radio:last-child {
  margin-bottom: 0;
}
.sidebar .form-group:last-child {
  margin-bottom: 0;
}
@media (min-width: 769px) {
  .sidebar .nav-tabs > li > a {
    border-bottom-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.6);
    border-top: 0;
    border-radius: 0;
  }
  .sidebar .nav-tabs > li > a:hover,
  .sidebar .nav-tabs > li > a:focus {
    color: #fff;
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .sidebar .nav-tabs > .active > a,
  .sidebar .nav-tabs > .active > a:hover,
  .sidebar .nav-tabs > .active > a:focus {
    border-top: 0;
    border-bottom: 0;
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  .sidebar .nav-tabs > .active:first-child > a {
    border-left-color: transparent!important;
  }
  .sidebar .nav-tabs > .active:last-child > a {
    border-right-color: transparent!important;
  }
  .sidebar .nav-tabs .dropdown-menu-right {
    right: -1px;
  }
  .sidebar .nav-tabs > .open > a {
    color: #fff;
  }
  .sidebar .nav-tabs > .open:not(.active) > a {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
@media (min-width: 769px) {
  .sidebar-default .nav-tabs > li > a {
    background-color: #fafafa;
    border-bottom-color: #dddddd;
    color: #999999;
  }
  .sidebar-default .nav-tabs > li > a:hover,
  .sidebar-default .nav-tabs > li > a:focus {
    color: #333333;
    border-bottom-color: #dddddd;
  }
  .sidebar-default .nav-tabs > .active > a,
  .sidebar-default .nav-tabs > .active > a:hover,
  .sidebar-default .nav-tabs > .active > a:focus {
    border-color: #dddddd;
    color: #333333;
  }
  .sidebar-default .nav-tabs > .open > a {
    border-bottom-color: #dddddd;
    color: #333333;
  }
  .sidebar-default .nav-tabs > .open:not(.active) > a {
    background-color: #fafafa;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Main content layout
*
*  Styles for main structure of content area
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.page-container {
  position: relative;
}
@media (min-width: 769px) {
  .page-container {
    width: 100%;
    display: table;
    table-layout: fixed;
  }
}
@-moz-document url-prefix() {
  .page-container {
    height: 1px;
  }
}
@media (min-width: 769px) {
  .page-content {
    display: table-row;
  }
}
.content-wrapper {
  padding-bottom: 20px;
  width: 100%;
}
@media (min-width: 769px) {
  .content-wrapper {
    display: table-cell;
    vertical-align: top;
  }
}
.content {
  padding: 20px 20px;
}
.content:after {
  content: '';
  display: table;
  clear: both;
}
.page-header + .content {
  padding-top: 0;
}
/* ------------------------------------------------------------------------------
*
*  # Boxed layout
*
*  Styles for main structure of content area in boxed layout
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.layout-boxed {
  background: url(../images/backgrounds/boxed_bg.png) repeat;
}
.layout-boxed > .navbar {
  margin-left: auto;
  margin-right: auto;
}
.layout-boxed .page-container {
  background-color: #f5f5f5;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 769px) {
  .layout-boxed .page-container,
  .layout-boxed > .navbar {
    width: 769px;
  }
}
@media (min-width: 1025px) {
  .layout-boxed .page-container,
  .layout-boxed > .navbar {
    width: 1025px;
  }
}
@media (min-width: 1200px) {
  .layout-boxed .page-container,
  .layout-boxed > .navbar {
    width: 1200px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .layout-boxed {
    background: url(../images/backgrounds/boxed_bg_retina.png) repeat;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Footer
*
*  Main footer styles, currently 1 version only
*
*  Version: 1.0
*  Latest update: May 22, 2015
*
* ---------------------------------------------------------------------------- */
.footer {
  position: absolute;
  bottom: 20px;
}
.footer-fixed {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 20px;
  background-color: #fcfcfc;
  border-top: 1px solid #dddddd;
  z-index: 1000;
}
/* ------------------------------------------------------------------------------
*
*  # Utilites
*
*  Additional animations, transitions, transforms and other utilities
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.rotate-45 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.rotate-90 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rotate-180 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate-45-inverse {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.rotate-90-inverse {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.rotate-180-inverse {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.spinner {
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
.spinner-reverse {
  display: inline-block;
  -webkit-animation: rotation_reverse 1s linear infinite;
  -o-animation: rotation_reverse 1s linear infinite;
  animation: rotation_reverse 1s linear infinite;
}
@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotation {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-ms-keyframes rotation {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@-o-keyframes rotation {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotation_reverse {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@-moz-keyframes rotation_reverse {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-ms-keyframes rotation_reverse {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(-360deg);
  }
}
@-o-keyframes rotation_reverse {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(-360deg);
  }
}
@keyframes rotation_reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes bounceOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Demo styles
*
*  Styles used for demostration purposes only
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.demo-color {
  height: 150px;
  position: relative;
  margin: 3px;
  margin-bottom: 0;
}
.demo-color > span {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 12px;
  color: #fff;
}
.overlay-demo {
  display: inline-block;
  background-color: #333;
  border-radius: 3px;
}
.overlay-demo-light {
  background-color: #f5f5f5;
}
.glyphs > div {
  padding: 7px 12px;
  cursor: pointer;
}
.glyphs > div > i {
  margin-right: 10px;
  width: 1em;
  text-align: center;
}
.glyphs > div:hover {
  background-color: #009688;
  color: #fff;
  border-radius: 2px;
}
.glyphs > div:hover span {
  color: #fff;
}
.grid-demo [class*="col-"] > div:not(.row) {
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;
  background-color: #F7F7FC;
  border: 1px solid #ddd;
  color: #333333;
}
.demo-velocity-box {
  padding: 12px 15px;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid #ccc;
  text-align: center;
  background-color: #fcfcfc;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 0 #2196f3;
  box-shadow: 0 0 0 0 #2196f3;
}
.demo-velocity-box > span {
  float: left;
}
.demo-velocity-box span {
  vertical-align: middle;
}
.demo-velocity-box .icons-list {
  float: right;
  margin-top: 2px;
}
.demo-velocity-box .icons-list a {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.demo-velocity-box .icons-list a:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.blockui-growl {
  display: none;
  text-align: left;
  padding: 15px;
  background-color: #455a64;
  color: #fff;
  border-radius: 3px;
}
.blockui-growl h6 {
  margin-top: 2px;
  margin-bottom: 8px;
}
.blockui-message {
  display: none;
  background-color: #455a64;
  color: #fff;
  border-radius: 3px;
  padding: 15px 15px 10px 15px;
}
.blockui-message > i {
  display: block;
  margin-bottom: 10px;
}
.multiple-messages-container,
.blockui-animation-container {
  display: none;
}
.blockui-animation-container {
  background-color: #555;
  padding: 10px;
  line-height: 1;
  border-radius: 3px;
}
.val-demo {
  display: block;
  margin-top: 20px;
}
.val-demo > span {
  font-weight: 500;
}
/* ------------------------------------------------------------------------------
 *
 *  # Pace. Default theme
 *
 *  Minimal preloader theme, used as a default theme
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pace .pace-progress {
  background: #4fc3f7;
  position: fixed;
  z-index: 2000;
  top: 0;
  width: 100%;
  right: 100%;
  height: 2px;
}
.pace-inactive {
  display: none;
}
/* ------------------------------------------------------------------------------
 *
 *  # Progress bars
 *
 *  Static demo of Pace themes. For demonstration purposes only. DO NOT use it in live project
 *
 *  Version: 1.0
 *  Latest update: Mar 2, 2015
 *
 * ---------------------------------------------------------------------------- */
.pace-demo {
  display: inline-block;
  background-color: #37474f;
  border-radius: 3px;
  margin-top: 1px;
  margin-bottom: 1px;
  vertical-align: middle;
  padding: 20px;
  position: relative;
}
.pace-demo .pace_progress {
  display: none;
}
.theme_xbox_xs,
.theme_xbox_sm,
.theme_xbox {
  position: relative;
  width: 44px;
  height: 44px;
}
.theme_xbox_xs .pace_activity,
.theme_xbox_sm .pace_activity,
.theme_xbox .pace_activity {
  width: 44px;
  height: 44px;
  -webkit-animation: rotation 1.5s ease-in-out infinite;
  -o-animation: rotation 1.5s ease-in-out infinite;
  animation: rotation 1.5s ease-in-out infinite;
}
.theme_xbox_xs .pace_activity,
.theme_xbox_xs .pace_activity:before,
.theme_xbox_xs .pace_activity:after,
.theme_xbox_sm .pace_activity,
.theme_xbox_sm .pace_activity:before,
.theme_xbox_sm .pace_activity:after,
.theme_xbox .pace_activity,
.theme_xbox .pace_activity:before,
.theme_xbox .pace_activity:after {
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #fff;
}
.theme_xbox_xs .pace_activity:before,
.theme_xbox_sm .pace_activity:before,
.theme_xbox .pace_activity:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  -webkit-animation: rotation 2s ease-in-out infinite;
  -o-animation: rotation 2s ease-in-out infinite;
  animation: rotation 2s ease-in-out infinite;
}
.theme_xbox_xs .pace_activity:after,
.theme_xbox_sm .pace_activity:after,
.theme_xbox .pace_activity:after {
  content: "";
  position: absolute;
  top: 12px;
  left: 12px;
  right: 12px;
  bottom: 12px;
  -webkit-animation: rotation 1s ease-in-out infinite;
  -o-animation: rotation 1s ease-in-out infinite;
  animation: rotation 1s ease-in-out infinite;
}
.theme_xbox_with_text span {
  display: block;
  margin-top: 7px;
  color: #fff;
}
.theme_xbox_sm .pace_activity,
.theme_xbox_sm .pace_activity:before,
.theme_xbox_sm .pace_activity:after {
  border-width: 2px;
}
.theme_xbox .pace_activity,
.theme_xbox .pace_activity:before,
.theme_xbox .pace_activity:after {
  border-width: 3px;
}
.theme_perspective {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 30px;
  height: 30px;
}
.theme_perspective .pace_activity {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #fff;
  -webkit-animation: perspective 1.2s infinite ease-in-out;
  -o-animation: perspective 1.2s infinite ease-in-out;
  animation: perspective 1.2s infinite ease-in-out;
}
@keyframes perspective {
  0% {
    transform: perspective(120px);
  }
  50% {
    transform: perspective(120px) rotateY(180deg);
  }
  100% {
    transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
@-webkit-keyframes perspective {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
@-moz-keyframes perspective {
  0% {
    -moz-transform: perspective(120px);
  }
  50% {
    -moz-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -moz-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
.theme_perspective_with_text span,
.theme_squares_with_text span,
.theme_tail_with_text span,
.theme_corners_with_text span,
.theme_radar_with_text span {
  display: block;
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 100%;
  color: #fff;
}
.theme_squares {
  width: 30px;
  height: 30px;
}
.theme_squares .pace_activity:before,
.theme_squares .pace_activity:after {
  position: absolute;
  content: "";
  border: 2px solid #fff;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: 5px;
}
.theme_squares .pace_activity:before {
  width: 30px;
  height: 30px;
  margin-left: -15px;
  margin-top: 0;
  -webkit-animation: rotation_reverse 2s linear infinite;
  -o-animation: rotation_reverse 2s linear infinite;
  animation: rotation_reverse 2s linear infinite;
}
.theme_squares .pace_activity:after {
  -webkit-animation: rotation 2s linear infinite;
  -o-animation: rotation 2s linear infinite;
  animation: rotation 2s linear infinite;
}
.theme_tail {
  width: 30px;
  height: 30px;
}
.theme_tail .pace_activity {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 3px solid transparent;
  border-left-color: #ffffff;
  -webkit-animation: rotation 1.1s infinite linear;
  -o-animation: rotation 1.1s infinite linear;
  animation: rotation 1.1s infinite linear;
}
.theme_tail_circle {
  width: 30px;
  height: 30px;
}
.theme_tail_circle .pace_activity {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffffff;
  -webkit-animation: rotation 1.1s infinite linear;
  -o-animation: rotation 1.1s infinite linear;
  animation: rotation 1.1s infinite linear;
}
.theme_corners {
  height: 30px;
  width: 30px;
}
.theme_corners .pace_activity {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: 9px;
  margin-bottom: 3px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-animation: corners 1.5s ease infinite;
  -o-animation: corners 1.5s ease infinite;
  animation: corners 1.5s ease infinite;
}
@keyframes corners {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: scale(1.5) rotate(180deg);
    border-radius: 0;
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes corners {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: scale(2) rotate(180deg);
    border-radius: 0;
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes corners {
  0% {
    -moz-transform: rotate(0deg);
  }
  50% {
    -moz-transform: scale(2) rotate(180deg);
    border-radius: 0;
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
.theme_radar {
  width: 30px;
  height: 30px;
}
.theme_radar .pace_activity {
  display: inline-block;
  margin-top: 3px;
  margin-bottom: 3px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #fff;
  border-bottom-color: #fff;
  -webkit-animation: rotation 1.1s infinite linear;
  -o-animation: rotation 1.1s infinite linear;
  animation: rotation 1.1s infinite linear;
}
.theme_bar,
.theme_bar_sm,
.theme_bar_xs {
  position: relative;
  width: 200px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 100px;
  -webkit-box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
}
.theme_bar .pace_progress,
.theme_bar_sm .pace_progress,
.theme_bar_xs .pace_progress {
  max-width: 198px;
  display: block;
  position: absolute;
  left: 1px;
  top: 1px;
  height: 4px;
  background: #3FCF96;
  color: #fff;
  line-height: 32px;
  border-radius: 100px;
}
.theme_bar_sm {
  height: 5px;
}
.theme_bar_sm .pace_progress {
  height: 3px;
}
.theme_bar_xs {
  height: 4px;
}
.theme_bar_xs .pace_progress {
  height: 2px;
}
/* ------------------------------------------------------------------------------
 *
 *  # Uniform form inputs plugin
 *
 *  Styles for uniform.min.js - form components styling
 *
 *  Version: 1.0
 *  Latest update: Mar 10, 2015
 *
 * ---------------------------------------------------------------------------- */
.checker {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}
.checker,
.checker span,
.checker input {
  width: 18px;
  height: 18px;
}
.checker span {
  color: #455a64;
  border: 2px solid #607d8b;
  display: inline-block;
  text-align: center;
  position: relative;
}
.checker span:after {
  content: "\e600";
  font-family: 'icomoon';
  display: none;
  font-size: 16px;
  line-height: 1;
  position: absolute;
  top: -1px;
  left: -1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.checker span.checked:after {
  display: block;
}
.checker input[type=checkbox],
.choice input[type=radio] {
  border: none;
  background: none;
  display: -moz-inline-box;
  display: inline-block;
  margin: 0;
  vertical-align: top;
  cursor: pointer;
  position: absolute;
  top: -2px;
  left: -2px;
  z-index: 2;
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox .checker,
.checkbox-inline .checker {
  position: absolute;
  top: 1px;
  left: 0;
}
.form-horizontal .checkbox .checker,
.form-horizontal .checkbox-inline .checker {
  top: 9px;
}
.checkbox-right .checker {
  left: auto;
  right: 0;
}
.checker.active span:after,
.checkbox > label:active .checker span:after,
.checkbox-inline:active .checker span:after {
  display: block;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.checker.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.checker.disabled,
.checker.disabled input[type=checkbox] {
  cursor: not-allowed;
}
.checkbox > label:active .checker.disabled span:after,
.checkbox-inline:active .checker.disabled span:after {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox > label:active .checker.disabled span.checked:after,
.checkbox-inline:active .checker.disabled span.checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
}
.checker[class*=border-] span {
  border-color: inherit;
  color: inherit;
}
.dropdown-menu > .active:not(.disabled) .checker span,
.dropdown-menu[class*=bg-] .checker span,
.navbar-inverse .navbar-form .checker span,
.sidebar:not(.sidebar-default) .checkbox .checker span {
  border-color: #fff;
  color: #fff;
}
.choice {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 100%;
}
.choice,
.choice span,
.choice input {
  width: 18px;
  height: 18px;
}
.choice span {
  border: 2px solid #607d8b;
  display: -moz-inline-box;
  display: inline-block;
  border-radius: 100%;
  text-align: center;
  position: relative;
}
.choice span:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  border: 4px solid;
  border-color: inherit;
  width: 0;
  height: 0;
  border-radius: 100%;
  display: none;
}
.choice span.checked:after {
  display: block;
}
.radio .choice,
.radio-inline .choice {
  position: absolute;
  top: 1px;
  left: 0;
}
.form-horizontal .radio .choice,
.form-horizontal .radio-inline .choice {
  top: 9px;
}
.radio-right .choice {
  left: auto;
  right: 0;
}
.choice.active span:after,
.radio > label:active .choice span:after,
.radio-inline:active .choice span:after {
  display: block;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.choice.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.choice.disabled,
.choice.disabled input[type=radio] {
  cursor: not-allowed;
}
.radio > label:active .choice.disabled span:after,
.radio-inline:active .choice.disabled span:after {
  opacity: 0;
  filter: alpha(opacity=0);
}
.radio > label:active .choice.disabled span.checked:after,
.radio-inline:active .choice.disabled span.checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
}
.choice[class*=border-] span {
  border-color: inherit;
}
.choice[class*=border-] span:after {
  border-color: inherit;
}
.dropdown-menu > .active .choice span,
.dropdown-menu[class*=bg-] .choice span,
.navbar-inverse .navbar-form .choice span,
.sidebar:not(.sidebar-default) .checkbox .choice span {
  border-color: #fff;
}
.uploader {
  width: 100%;
  position: relative;
  display: -moz-inline-box;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
}
.uploader .filename {
  color: #999999;
  height: 36px;
  padding: 7px 12px;
  padding-right: 45px;
  cursor: pointer;
  width: 100%;
  float: left;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
  border: 1px solid #dddddd;
  background-color: #fff;
  border-radius: 3px;
  text-align: left;
}
.uploader .action {
  position: absolute;
  color: #333333;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 1;
  border: 1px solid #dddddd;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.uploader .action > i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
}
.uploader[class*=bg-] .action {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
}
.uploader input[type=file] {
  width: 100%;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 36px;
  border: 0;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  filter: alpha(opacity=0);
}
.uploader.disabled .action {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.uploader.disabled .filename {
  background-color: #fafafa;
  cursor: not-allowed;
}
.uploader-lg input[type=file],
.uploader-lg .action,
.uploader-lg .filename {
  height: 40px;
}
.uploader-lg .action {
  width: 40px;
}
.uploader-lg .filename {
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 50px;
}
.uploader-sm input[type=file],
.uploader-sm .action,
.uploader-sm .filename {
  height: 34px;
}
.uploader-sm .action {
  width: 34px;
}
.uploader-sm .filename {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 42.5px;
}
.uploader-xs input[type=file],
.uploader-xs .action,
.uploader-xs .filename {
  height: 32px;
}
.uploader-xs .action {
  width: 32px;
}
.uploader-xs .filename {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 42.5px;
}
/* ------------------------------------------------------------------------------
*
*  # Switchery toggles
*
*  Styles for switchery.min.js - toggle switches
*
*  Version: 1.0
*  Latest update: Mar 25, 2015
*
* ---------------------------------------------------------------------------- */
.switchery {
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 100px;
  cursor: pointer;
  display: inline-block;
  width: 44px;
  height: 22px;
  vertical-align: middle;
  position: relative;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.checkbox-switchery .switchery {
  position: absolute;
  left: 0;
  margin-top: -2px;
}
.switchery > small {
  background-color: #fff;
  border-radius: 100px;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.checkbox.checkbox-switchery {
  margin-bottom: 14px;
  padding-left: 0;
}
.checkbox.checkbox-switchery.disabled .switchery {
  cursor: not-allowed;
}
.checkbox[class*=switchery-],
.checkbox-inline[class*=switchery-] {
  padding-left: 0;
  padding-right: 0;
}
.switchery-lg.checkbox-switchery {
  margin-bottom: 18px;
}
.switchery-lg.checkbox-switchery .switchery {
  margin-top: -4px;
}
label.switchery-lg {
  margin-bottom: 0;
}
.switchery-lg .switchery {
  height: 26px;
  width: 52px;
}
.switchery-lg .switchery > small {
  height: 26px;
  width: 26px;
}
.switchery-sm.checkbox-switchery {
  margin-bottom: 10px;
}
.switchery-sm.checkbox-switchery .switchery {
  margin-top: 0px;
}
label.switchery-sm {
  margin-bottom: 0;
}
.switchery-sm .switchery {
  height: 18px;
  width: 36px;
}
.switchery-sm .switchery > small {
  height: 18px;
  width: 18px;
}
.switchery-xs.checkbox-switchery {
  margin-bottom: 6px;
}
.switchery-xs.checkbox-switchery .switchery {
  margin-top: 2px;
}
label.switchery-xs {
  margin-bottom: 0;
}
.switchery-xs .switchery {
  height: 14px;
  width: 28px;
}
.switchery-xs .switchery > small {
  height: 14px;
  width: 14px;
}
.checkbox-switchery label,
label.checkbox-switchery {
  position: relative;
  padding-left: 56px;
  margin: 0;
  cursor: pointer;
}
.checkbox-switchery.switchery-lg label,
label.checkbox-switchery.switchery-lg {
  padding-left: 64px;
}
.checkbox-switchery.switchery-sm label,
label.checkbox-switchery.switchery-sm {
  padding-left: 48px;
}
.checkbox-switchery.switchery-xs label,
label.checkbox-switchery.switchery-xs {
  padding-left: 40px;
}
.checkbox-switchery.checkbox-inline {
  margin-bottom: 0;
}
.checkbox-switchery.checkbox-right .switchery {
  left: auto;
  right: 0;
}
.checkbox-switchery.checkbox-right label,
label.checkbox-switchery.checkbox-right {
  padding-left: 0;
  padding-right: 56px;
}
.checkbox-switchery.checkbox-right.switchery-lg label,
label.checkbox-switchery.checkbox-right.switchery-lg {
  padding-left: 0;
  padding-right: 64px;
}
.checkbox-switchery.checkbox-right.switchery-sm label,
label.checkbox-switchery.checkbox-right.switchery-sm {
  padding-left: 0;
  padding-right: 48px;
}
.checkbox-switchery.checkbox-right.switchery-xs label,
label.checkbox-switchery.checkbox-right.switchery-xs {
  padding-left: 0;
  padding-right: 40px;
}
.switchery-double .switchery {
  position: relative;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: -7px;
  margin-bottom: -5px;
}
.switchery-double.checkbox-switchery label,
label.switchery-double.checkbox-switchery {
  padding: 0;
}
.switchery-double.switchery-lg .switchery {
  margin-top: -8px;
  margin-bottom: -6px;
}
.switchery-double.switchery-sm .switchery {
  margin-top: -6px;
  margin-bottom: -4px;
}
.switchery-double.switchery-xs .switchery {
  margin-top: -5px;
  margin-bottom: -3px;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap switches
*
*  Styles for switch.min.js - checkbox/radio toggle switches
*
*  Version: 1.0
*  Latest update: MaY 25, 2015
*
* ---------------------------------------------------------------------------- */
.bootstrap-switch {
  display: inline-block;
  cursor: pointer;
  margin-top: -2px;
  margin-right: 7px;
  border-radius: 3px;
  border: 1px solid transparent;
  position: relative;
  text-align: left;
  overflow: hidden;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all ease-in-out 0.05s;
  -o-transition: all ease-in-out 0.05s;
  transition: all ease-in-out 0.05s;
}
.bootstrap-switch .bootstrap-switch-container {
  display: inline-block;
  top: 0;
  border-radius: 3px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.bootstrap-switch.bootstrap-switch-focused {
  outline: 0;
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  cursor: pointer;
  display: inline-block !important;
  height: 100%;
  padding: 7px 12px;
}
.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  filter: alpha(opacity=0);
}
.bootstrap-switch input[type='radio'].form-control,
.bootstrap-switch input[type='checkbox'].form-control {
  height: auto;
}
.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important;
}
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  cursor: default !important;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  -webkit-transition: margin-left 0.2s;
  -o-transition: margin-left 0.2s;
  transition: margin-left 0.2s;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch-handle-on,
.bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
}
.bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #333333;
  background-color: #eeeeee;
}
.bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background-color: #2196f3;
}
.bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background-color: #f44336;
}
.bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background-color: #4caf50;
}
.bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch-handle-off.bootstrap-switch-warning {
  color: #fff;
  background-color: #ff5722;
}
.bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background-color: #00bcd4;
}
.bootstrap-switch-handle-on {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch-handle-off {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch-label {
  text-align: center;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  z-index: 100;
  color: #333333;
  background-color: #fff;
  position: relative;
}
.bootstrap-switch-label:hover {
  background-color: #fcfcfc;
}
.bootstrap-switch-label:active {
  background-color: #fafafa;
}
.bootstrap-switch-label:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 10px;
  margin-top: -5px;
  margin-left: -1px;
  display: inline-block;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch-large .bootstrap-switch-label {
  padding: 9px 15px;
}
.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 5px 10px;
}
.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch-small .bootstrap-switch-label {
  padding: 6px 11px;
}
.checkbox-switch > label,
label.checkbox-switch.checkbox-inline {
  padding: 0;
}
.checkbox-switch.checkbox-right .bootstrap-switch {
  margin-right: 0;
  margin-left: 7px;
}
/* ------------------------------------------------------------------------------
*
*  # Select2 selects
*
*  Styles for select2.js - custom select plugin
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
/* # Single select
-------------------------------------------------- */
.select2-container {
  width: 100%;
  outline: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #333333;
  text-align: left;
  border-radius: 3px;
}
.select2-container[class*=border-] .select2-choice,
.select2-container[class*=border-].select2-dropdown-open.select2-drop-above .select2-choice,
.select2-container[class*=border-].select2-dropdown-open.select2-drop-above .select2-choices {
  border-color: inherit;
}
.select2-container.border-lg .select2-choice,
.select2-container.border-lg .select2-choices {
  border-width: 2px;
}
.select2-container[class*=bg-] .select2-choice {
  background-color: inherit;
  border-color: inherit;
  color: #fff;
}
.select2-container[class*=bg-] .select2-choice:hover,
.select2-container[class*=bg-].select2-dropdown-open .select2-choice {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}
.select2-container[class*=bg-].select2-container-disabled .select2-choice {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.select2-container.select2-container-disabled .select2-choice {
  cursor: not-allowed;
  background-color: #fafafa;
  color: #999999;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.select2-container.select2-container-disabled .select2-choice abbr {
  cursor: not-allowed;
}
.select2-container.select2-container-disabled[class*=bg-] {
  border-color: rgba(255, 255, 255, 0.4);
}
.select2-container.select2-container-disabled[class*=bg-] .select2-choice {
  background-color: rgba(255, 255, 255, 0.4);
  color: rgba(255, 255, 255, 0.8);
}
.select2-choice {
  display: block;
  background-color: #fff;
  height: 36px;
  padding: 7px 12px;
  padding-left: 11px;
  padding-right: 0;
  line-height: 1.5384616;
  position: relative;
  border: 1px solid #dddddd;
  white-space: nowrap;
  border-radius: 3px;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-choice,
.select2-choice:hover,
.select2-choice:focus {
  color: #333333;
}
.select2-choice:hover {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.select2-dropdown-open .select2-choice {
  border-radius: 3px 3px 0 0;
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
}
.select2-drop-above .select2-choice {
  border-radius: 0 0 3px 3px;
}
.select2-choice .select2-chosen {
  margin-right: 28px;
  padding-left: 1px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: none;
  width: auto;
}
.select2-choice .select2-chosen > i {
  margin-right: 10px;
}
.select2-choice .select2-chosen > i.icon-undefined {
  margin-right: 0;
}
.select2-choice abbr {
  display: none;
  position: absolute;
  right: 9px;
  top: 50%;
  margin-top: -8px;
  border: 0;
  cursor: pointer;
  outline: 0;
  border-radius: 3px;
  line-height: 1;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.select2-choice abbr:hover {
  opacity: 1;
}
.select2-choice abbr:after {
  content: '\ed6b';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-container[class*=bg-] .select2-choice abbr,
.select2-container[class*=bg-] .select2-choice abbr:hover {
  color: #fff;
}
.select2-allowclear .select2-choice abbr {
  display: inline-block;
}
.select2-choice .select2-arrow:after {
  content: '\e9c5';
  font-family: 'Icomoon';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 12px;
  width: 16px;
  text-align: right;
  margin-top: -8px;
  font-size: 16px;
  line-height: 1;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-dropdown-open .select2-choice .select2-arrow:after {
  content: '\e9c6';
}
.select2-allowclear .select2-choice .select2-arrow:after {
  content: none;
}
.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  z-index: 9998;
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
}
.select2-drop {
  width: 100%;
  position: absolute;
  z-index: 9999;
  top: 100%;
  background-color: #fff;
  color: #333333;
  border: 1px solid #dddddd;
  border-top-width: 0;
  border-radius: 0 0 3px 3px;
}
.select2-drop-above {
  border-top-width: 1px;
  border-bottom-width: 0;
  border-radius: 3px 3px 0 0;
}
.select2-drop-active {
  border-radius: 0 0 3px 3px;
}
.select2-drop.select2-drop-above.select2-drop-active {
  border-radius: 3px 3px 0 0;
}
.select2-drop-auto-width {
  width: auto;
}
.select2-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.select2-search {
  display: block;
  width: 100%;
  margin: 0;
  padding: 12px;
  padding-bottom: 7px;
  position: relative;
  z-index: 10000;
  white-space: nowrap;
}
.select2-search:after {
  content: '\e98e';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  left: 24px;
  color: inherit;
  display: block;
  font-size: 12px;
  margin-top: -4px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.select2-search input {
  width: 100%;
  height: 36px;
  padding: 7px 12px;
  padding-left: 36px;
  border-radius: 3px;
  border: 1px solid #dddddd;
  outline: 0;
}
.select2-drop[class*=bg-] .select2-search input {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: transparent;
  color: #fff;
}
.select2-results {
  max-height: 250px;
  margin: 0;
  padding: 5px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.select2-results .select2-result-sub {
  margin: 0;
  padding-left: 0;
}
.select2-results li {
  list-style: none;
  display: list-item;
}
.select2-results li em {
  font-style: normal;
}
.select2-results li.select2-result-with-children > .select2-result-label {
  font-size: 11px;
  line-height: 1.82;
  text-transform: uppercase;
  cursor: default;
  font-weight: 500;
  margin-top: 2px;
  margin-bottom: 2px;
}
.select2-results li.select2-result-with-children:first-child > .select2-result-label {
  margin-top: 0;
}
.select2-results li.select2-result-with-children .select2-result > .select2-result-label {
  padding-left: 24px;
  padding-right: 24px;
}
.select2-results .select2-more-results {
  background: #f8f8f8;
  display: list-item;
}
.select2-results .select2-result-label {
  padding: 7px 12px;
  margin: 0;
  position: relative;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-results .select2-result-label > i {
  margin-right: 10px;
}
.select2-results .select2-result-label > i.icon-undefined {
  display: none;
}
.select2-results .select2-result-label > span {
  left: 10px;
}
.select2-results .select2-highlighted {
  background-color: #f5f5f5;
}
.select2-results .select2-highlighted ul {
  background-color: #fff;
  color: #333333;
}
.select2-results .select2-highlighted em {
  background-color: transparent;
}
.select2-drop[class*=bg-] .select2-results .select2-highlighted {
  background-color: rgba(0, 0, 0, 0.1);
}
.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
  background-color: #f8f8f8;
  padding: 7px 12px;
  color: #999999;
  border-top: 1px solid #dddddd;
  margin-bottom: -5px;
  border-radius: 0;
}
.select2-drop[class*=bg-] .select2-results .select2-no-results,
.select2-drop[class*=bg-] .select2-results .select2-searching,
.select2-drop[class*=bg-] .select2-results .select2-selection-limit {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.select2-results .select2-disabled {
  color: #999999;
  display: list-item;
}
.select2-results .select2-disabled .select2-result-label {
  cursor: not-allowed;
}
.select2-drop[class*=bg-] .select2-results .select2-disabled {
  color: #fff;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.select2-results .select2-selected {
  display: none;
}
.select2-results ul ul > li .select2-result-label {
  padding-left: 24px;
}
.select2-results ul ul ul > li .select2-result-label {
  padding-left: 36px;
}
.select2-results ul ul ul ul > li .select2-result-label {
  padding-left: 48px;
}
.select2-results ul ul ul ul ul > li .select2-result-label {
  padding-left: 60px;
}
.select2-results ul ul ul ul ul ul > li .select2-result-label {
  padding-left: 72px;
}
.select2-results ul ul ul ul ul ul ul > li .select2-result-label {
  padding-left: 84px;
}
/* # Multiple select
-------------------------------------------------- */
.select2-container-multi .select2-choices {
  margin: 0;
  border-radius: 3px;
  padding: 0 0 3px 0;
  position: relative;
  outline: 0;
  border: 1px solid #dddddd;
  cursor: text;
  overflow: hidden;
  background-color: #fff;
}
.select2-container-multi .select2-choices:after {
  content: '';
  display: table;
  clear: both;
}
.select2-container-multi .select2-choices li {
  float: left;
  list-style: none;
}
.select2-container-multi .select2-choices.ui-sortable > li {
  cursor: move;
}
.select2-container-multi .select2-choices .select2-search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.select2-container-multi .select2-choices .select2-search-field input {
  padding: 7px 12px;
  margin-top: 3px;
  color: #333333;
  outline: 0;
  border: 0;
  background: transparent !important;
}
.select2-container-multi .select2-choices .select2-search-choice {
  margin: 3px 0 0 3px;
  position: relative;
  cursor: default;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
html[dir="rtl"] .select2-container-multi .select2-choices .select2-search-choice {
  margin-left: 0;
  margin-right: 3px;
}
.select2-container-multi .select2-choices .select2-search-choice > div {
  border-radius: 3px;
  color: #fff;
  padding: 7px 12px;
  padding-right: 30px;
  background-color: #455a64;
}
.select2-container-multi .select2-choices .select2-search-choice > div > i {
  margin-right: 10px;
}
.select2-container-multi .select2-choices .select2-search-choice.select2-locked > div {
  padding: 7px 12px;
}
.select2-container-multi .select2-choices .select2-search-choice[class*=bg-] > div {
  background-color: inherit;
}
.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
  cursor: default;
}
.select2-container-multi .select2-choices .select2-search-choice-close {
  position: absolute;
  right: 9px;
  top: 50%;
  margin-top: -8px;
  line-height: 1;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.select2-container-multi .select2-choices .select2-search-choice-close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
html[dir="rtl"] .select2-container-multi .select2-choices .select2-search-choice-close {
  right: auto;
  left: 6px;
}
.select2-container-multi .select2-choices .select2-search-choice-close:after {
  content: '\ed6b';
  font-family: 'icomoon';
  display: block;
  font-size: 16px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-container-multi[class*=bg-] .select2-choices {
  background-color: inherit;
  border-color: inherit;
}
.select2-container-multi[class*=bg-] .select2-choices .select2-search-choice > div {
  background-color: rgba(0, 0, 0, 0.3);
}
.select2-container-multi[class*=border-] .select2-choices {
  border-color: inherit;
}
.select2-container-multi.select2-dropdown-open .select2-choices {
  border-radius: 3px 3px 0 0;
}
.select2-container-multi.select2-dropdown-open.select2-drop-above .select2-choices {
  border-radius: 0 0 3px 3px;
}
.select2-container-multi .select2-default,
.select2-container-multi .select2-default:hover,
.select2-container-multi .select2-default:focus {
  color: #999;
}
.select2-container-multi .select2-default .select2-arrow,
.select2-container-multi .select2-default:hover .select2-arrow,
.select2-container-multi .select2-default:focus .select2-arrow {
  color: #333333;
}
.select2-container-multi.select2-container-disabled .select2-choices {
  cursor: default;
  background-color: #f8f8f8;
}
.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  color: #fff;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice > div {
  padding-right: 12px;
}
.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
  display: none;
}
/* # Additional sizing
-------------------------------------------------- */
.select-lg .select2-choice {
  height: 40px;
  padding: 9px 15px;
  padding-right: 0;
  font-size: 14px;
}
.select-lg .select2-choice abbr,
.select-lg .select2-choice .select2-arrow:after {
  right: 15px;
}
.select-lg.select2-container-multi .select2-choices .select2-search-choice > div {
  padding: 9px 15px;
  padding-right: 37.5px;
  font-size: 14px;
}
.select-lg.select2-container-multi .select2-choices .select2-search-choice-close {
  right: 12px;
}
html[dir="rtl"] .select-lg.select2-container-multi .select2-choices .select2-search-choice-close {
  right: auto;
  left: 12px;
}
.select-lg.select2-container-multi .select2-choices .select2-search-field input {
  padding: 9px 15px;
}
.select-sm .select2-choice {
  height: 34px;
  padding: 6px 11px;
  padding-right: 0;
}
.select-sm .select2-choice abbr,
.select-sm .select2-choice .select2-arrow:after {
  right: 11px;
}
.select-sm.select2-container-multi .select2-choices .select2-search-choice > div {
  padding: 6px 11px;
  padding-right: 27.5px;
}
.select-sm.select2-container-multi .select2-choices .select2-search-choice-close {
  right: 8px;
}
html[dir="rtl"] .select-sm.select2-container-multi .select2-choices .select2-search-choice-close {
  right: auto;
  left: 8px;
}
.select-sm.select2-container-multi .select2-choices .select2-search-field input {
  padding: 6px 11px;
}
.select-xs .select2-choice {
  height: 32px;
  padding: 5px 10px;
  padding-right: 0;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-xs .select2-choice abbr,
.select-xs .select2-choice .select2-arrow:after {
  right: 10px;
}
.select-xs.select2-container-multi .select2-choices .select2-search-choice > div {
  padding: 5px 10px;
  padding-right: 25px;
  font-size: 12px;
}
.select-xs.select2-container-multi .select2-choices .select2-search-choice-close {
  right: 7px;
}
html[dir="rtl"] .select-xs.select2-container-multi .select2-choices .select2-search-choice-close {
  right: auto;
  left: 7px;
}
.select-xs.select2-container-multi .select2-choices .select2-search-field input {
  padding: 5px 10px;
  font-size: 12px;
}
/* # Other Select2 styles
-------------------------------------------------- */
.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
  text-decoration: underline;
}
.select2-offscreen,
.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}
.select2-display-none {
  display: none;
}
.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}
.movie-title {
  font-size: 15px;
  font-weight: 500;
}
.movie-image img {
  margin-right: 12px;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap multiselect
*
*  Styles for multiselect.js - custom multiple select plugin
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.multiselect {
  width: 100%;
  min-width: 100%;
  text-align: left;
  padding-left: 12px;
  padding-right: 29px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.multiselect.btn-default,
.multiselect.btn-default.disabled {
  background-color: #ffffff;
  border-color: #dddddd;
}
.multiselect.btn-default:active,
.btn-group.open .multiselect.btn-default {
  border-color: #dddddd;
}
.multiselect .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -8px;
  width: 16px;
  text-align: right;
}
.multiselect.btn-lg {
  padding-left: 15px;
  padding-right: 30px;
}
.multiselect.btn-lg .caret {
  right: 15px;
}
.multiselect.btn-sm {
  padding-left: 11px;
  padding-right: 22px;
}
.multiselect.btn-sm .caret {
  right: 11px;
}
.multiselect.btn-xs {
  padding-left: 10px;
  padding-right: 20px;
}
.multiselect.btn-xs .caret {
  right: 10px;
}
.multiselect-container {
  min-width: 180px;
  max-height: 250px;
  overflow-y: auto;
}
.multiselect-container > li {
  padding: 0;
}
.multiselect-container > li > a {
  padding: 0;
}
.multiselect-container > li > a > label {
  margin: 0;
  height: 100%;
  cursor: pointer;
  padding: 8px 12px;
  padding-left: 40px;
}
.multiselect-container > li > a .checker,
.multiselect-container > li > a .choice {
  top: 50%;
  margin-top: -9px;
  left: 12px;
}
.multiselect-container > li > a.multiselect-all label {
  font-weight: 500;
}
.multiselect-container > li.disabled > a {
  background-color: transparent;
}
.multiselect-container > li.disabled > a > label {
  cursor: not-allowed;
}
.multiselect-container > li.multiselect-group > label,
.multiselect-container > li.multiselect-group.active > label {
  margin: 0;
  padding: 8px 15px;
  font-size: 11px;
  line-height: 1.82;
  color: #999999;
  text-transform: uppercase;
  margin-top: 10px;
  background-color: transparent;
  cursor: default;
}
.multiselect-container > li.multiselect-group.disabled {
  color: #999999;
}
.multiselect-container > li.multiselect-group:first-child > label,
.multiselect-container > li.multiselect-group:first-child.active > label {
  margin-top: 5px;
}
.multiselect-container > li.multiselect-group.multiselect-group-clickable > label {
  cursor: pointer;
}
.multiselect-container > li.multiselect-group.disabled label,
.multiselect-container > li.multiselect-group.disabled:hover label,
.multiselect-container > li.multiselect-group.disabled:focus label {
  background-color: transparent;
  cursor: not-allowed;
}
.multi-select-full > .btn-group {
  width: 100%;
}
.multi-select-full > .btn-group .multiselect-container {
  width: inherit;
}
.multi-select-fixed > .btn-group > .btn {
  width: 250px;
}
.multi-select-auto > .btn-group > .btn {
  width: auto;
}
div[class*=border-] > .btn-group {
  border-color: inherit;
}
div[class*=border-] > .btn-group > .multiselect {
  border-color: inherit;
}
li.multiselect-filter {
  position: relative;
  padding: 12px;
  padding-top: 7px;
  margin-bottom: 0;
}
li.multiselect-filter i {
  font-size: 13px;
  position: absolute;
  left: 24px;
  top: 50%;
  margin-top: -9px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
li.multiselect-filter .form-control {
  padding-left: 36px;
}
.input-group-btn + .multi-select-full .multiselect:first-child,
.input-group-addon + .multi-select-full .multiselect:first-child,
.input-group-btn + .multi-select-auto .multiselect:first-child,
.input-group-addon + .multi-select-auto .multiselect:first-child,
.input-group-btn + .multi-select-fixed .multiselect:first-child,
.input-group-addon + .multi-select-fixed .multiselect:first-child,
.input-group-btn + .btn-group .multiselect:first-child,
.input-group-addon + .btn-group .multiselect:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.btn-group-multiselect {
  width: 100%;
}
.btn-group-multiselect .btn {
  float: none;
}
.btn-group-multiselect .multiselect {
  min-width: 0;
}
.btn-group > .btn-group:nth-child(2) > .multiselect.btn {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
/* ------------------------------------------------------------------------------
*
*  # SelectBoxIt
*
*  Styles for selectbox.js - custom select boxes plugin
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
/* # Core
-------------------------------------------------- */
.selectboxit-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  border-radius: 3px;
}
.selectboxit-container * {
  -webkit-touch-callout: none;
  outline: 0;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.selectboxit-container .selectboxit {
  width: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  height: 36px;
  padding: 7px 12px;
  padding-right: 29px;
}
.selectboxit-container .selectboxit:focus {
  outline: 0;
}
.selectboxit-container span,
.selectboxit-container .selectboxit-options a {
  display: block;
}
.selectboxit-container .selectboxit-options {
  width: 100%;
  min-width: 200px;
  max-height: 250px;
  margin: 0;
  padding: 5px 1px;
  list-style: none;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  display: none;
  z-index: 999;
  text-align: left;
  border-radius: 3px;
}
.selectboxit.selectboxit-disabled,
.selectboxit-options .selectboxit-disabled,
.selectboxit.selectboxit-disabled:hover,
.selectboxit-options .selectboxit-disabled:hover,
.selectboxit.selectboxit-disabled:focus,
.selectboxit-options .selectboxit-disabled:focus {
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
}
.selectboxit-rendering {
  display: inline-block !important;
  visibility: visible !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
.selectboxit-list {
  background-color: #ffffff;
  border: 1px solid #dddddd;
}
.selectboxit-list .selectboxit-option-anchor {
  color: #333333;
}
.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  display: block;
  background-color: #f5f5f5;
}
.selectboxit-list > .selectboxit-disabled > .selectboxit-option-anchor {
  color: #999999;
  cursor: not-allowed;
}
.selectbox-container[class*=border-] > .selectboxit-list {
  border-color: inherit;
}
.selectbox-container[class*=bg-] > .selectboxit-list {
  background-color: inherit;
  border-color: inherit;
}
.selectbox-container[class*=bg-] > .selectboxit-list .selectboxit-option-anchor {
  color: #fff;
}
.selectbox-container[class*=bg-] > .selectboxit-list > .selectboxit-focus .selectboxit-option-anchor {
  background-color: rgba(0, 0, 0, 0.1);
}
.selectboxit-btn {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  color: #333333;
  border-radius: 3px;
}
.selectboxit-btn:hover {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.selectboxit-btn.selectboxit-open,
.selectboxit-btn:focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
}
.selectboxit-container[class*=bg-] .selectboxit-btn {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
}
.selectboxit-container[class*=border-] .selectboxit-btn {
  border-color: inherit;
}
.selectboxit-btn[class*=bg-]:hover,
.selectboxit-container[class*=bg-] .selectboxit-btn:hover {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}
.selectboxit-btn[class*=bg-].selectboxit-open,
.selectboxit-container[class*=bg-] .selectboxit-btn.selectboxit-open,
.selectboxit-btn[class*=bg-]:focus,
.selectboxit-container[class*=bg-] .selectboxit-btn:focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.selectboxit-text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}
.selectboxit-default-arrow {
  position: relative;
  font-style: normal;
}
.selectboxit-default-arrow:after {
  content: '\e9c5';
  display: block;
  font-family: 'icomoon';
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.selectboxit-open .selectboxit-default-arrow:after {
  content: '\e9c6';
}
.selectboxit-option-icon-url {
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  margin-top: 3px;
  margin-right: 10px;
}
.selectboxit-option {
  margin: 0;
  list-style-type: none;
}
.selectboxit-option .selectboxit-option-anchor {
  padding: 7px 12px;
}
.selectboxit-optgroup-header {
  margin: 0;
  list-style-type: none;
  padding: 7px 12px;
  font-weight: 700;
  color: #333333;
  font-size: 11px;
  line-height: 1.82;
  text-transform: uppercase;
}
.selectboxit-optgroup-header:hover {
  cursor: default;
}
.selectboxit-optgroup-option .selectboxit-option-anchor {
  padding-left: 24px;
  padding-right: 24px;
}
.selectboxit-arrow-container {
  position: absolute;
  top: 50%;
  width: 16px;
  right: 12px;
  margin-top: -8px;
  text-align: right;
  line-height: 1;
}
.selectboxit-arrow-container .selectboxit-arrow {
  margin-top: 0;
}
.selectboxit-arrow-container .selectboxit-arrow[class*=icon-] {
  top: 0;
}
.selectboxit-option-icon-container {
  float: left;
}
.selectboxit-option-icon-container > [class*=icon-] {
  margin-right: 10px;
  width: auto;
}
.selectboxit-option-icon-container .selectboxit-option-icon-url {
  height: 16px;
  width: 16px;
  background-size: 16px 16px;
  margin-top: 2px;
}
.selectboxit-option-first {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.selectboxit-optgroup-header + .selectboxit-option-first {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.selectboxit-option-last {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
/* # Sizing
-------------------------------------------------- */
.selectbox-fixed + .selectboxit-container,
.selectbox-fixed + .selectboxit-options {
  width: 250px;
}
.selectbox-auto + .selectboxit-container,
.selectbox-auto + .selectboxit-options {
  width: auto;
}
.selectbox-lg + .selectboxit-container .selectboxit {
  padding: 9px 15px;
  padding-right: 30px;
  height: 40px;
  font-size: 14px;
}
.selectbox-lg + .selectboxit-container .selectboxit .selectboxit-arrow-container {
  right: 15px;
}
.selectbox-sm + .selectboxit-container .selectboxit {
  padding: 6px 11px;
  padding-right: 22px;
  height: 34px;
}
.selectbox-sm + .selectboxit-container .selectboxit .selectboxit-arrow-container {
  right: 11px;
}
.selectbox-xs + .selectboxit-container .selectboxit {
  padding: 5px 10px;
  padding-right: 25px;
  height: 32px;
  font-size: 12px;
  line-height: 1.6666667;
}
.selectbox-xs + .selectboxit-container .selectboxit .selectboxit-arrow-container {
  right: 10px;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap select
*
*  Styles for bootstrap_select.js - custom select boxes plugin
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.bootstrap-select {
  width: 100%;
}
.bootstrap-select > .btn {
  width: 100%;
  padding-right: 29px;
}
.bootstrap-select > .btn.btn-default,
.bootstrap-select > .btn.btn-default.disabled {
  background-color: #ffffff;
  border-color: #dddddd;
}
.bootstrap-select > .btn.btn-default:active,
.btn-group.open .bootstrap-select > .btn.btn-default {
  border-color: #dddddd;
}
.bootstrap-select.form-control {
  padding: 0;
  border: 0;
}
.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}
.bootstrap-select .popover-title {
  font-size: 15px;
  margin: 5px 0;
  padding: 7px 12px;
}
.bootstrap-select[class*=border-] .btn,
.bootstrap-select[class*=border-] .dropdown-menu {
  border-color: inherit;
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px;
}
.bootstrap-select.fit-width {
  width: auto !important;
}
.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0;
}
.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right;
}
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
}
.bootstrap-select.btn-group > .disabled:focus {
  outline: 0;
}
.bootstrap-select.btn-group .btn .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
}
.bootstrap-select.btn-group .btn .filter-option > i {
  margin-right: 7px;
}
.bootstrap-select.btn-group .btn .caret {
  position: absolute;
  top: 50%;
  width: 16px;
  text-align: right;
  right: 12px;
  margin-top: -8px;
}
.bootstrap-select.btn-group[class*="col-"] .btn {
  width: 100%;
}
.bootstrap-select.btn-group .btn-lg > .caret {
  right: 15px;
}
.bootstrap-select.btn-group .btn-sm > .caret {
  right: 11px;
}
.bootstrap-select.btn-group .btn-xs > .caret {
  right: 10px;
}
.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
}
.bootstrap-select.btn-group .dropdown-menu > li > a [class*=icon-] {
  margin-right: 7px;
}
.bootstrap-select.btn-group .dropdown-menu > li > a .check-mark {
  display: none;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 12px;
  margin-right: 0;
}
.bootstrap-select.btn-group .dropdown-menu > li > a .text {
  display: inline-block;
}
.bootstrap-select.btn-group .dropdown-menu > li > a > .label,
.bootstrap-select.btn-group .dropdown-menu > li > a > .badge {
  float: none;
}
.bootstrap-select.btn-group .dropdown-menu > li > a.special-class,
.bootstrap-select.btn-group .dropdown-menu > li > a.special-class:hover {
  font-weight: 700;
  color: #fff;
  background: #f44336;
}
.bootstrap-select.btn-group .dropdown-menu > li.disabled a {
  cursor: not-allowed;
}
.bootstrap-select.btn-group .dropdown-menu > li small {
  padding-left: 10px;
}
.bootstrap-select.btn-group .dropdown-menu > .active > a small {
  color: #fff;
}
.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 7px 12px;
  background-color: #f8f8f8;
  border-top: 1px solid #dddddd;
  pointer-events: none;
}
.bootstrap-select.btn-group .dropdown-header ~ li > a {
  padding-left: 24px;
}
.bootstrap-select.btn-group .no-results {
  padding: 7px 12px;
  background: #f8f8f8;
  margin-bottom: -5px;
  border-top: 1px solid #dddddd;
}
.bootstrap-select.btn-group.fit-width .btn .filter-option {
  position: static;
}
.bootstrap-select.btn-group.fit-width .btn .caret {
  position: static;
  margin-top: -1px;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu > li a .text {
  margin-right: 40px;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu > li.active a .check-mark {
  display: inline-block;
}
.error .bootstrap-select .btn {
  border: 1px solid #f44336;
}
.control-group.error .bootstrap-select .dropdown-toggle {
  border-color: #f44336;
}
.bs-searchbox,
.bs-actionsbox {
  padding: 12px;
  padding-top: 7px;
}
.bs-searchbox {
  position: relative;
}
.bs-searchbox > .form-control {
  padding-left: 36px;
}
.bs-searchbox:after {
  content: '\e98e';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  left: 24px;
  color: inherit;
  display: block;
  font-size: 12px;
  margin-top: -9px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.bs-actionsbox {
  float: left;
  width: 100%;
  position: relative;
}
.bs-actionsbox .btn-group button {
  width: 50%;
}
.bs-searchbox + .bs-actionsbox {
  padding: 0 12px 7px;
}
.mobile-device {
  position: absolute;
  top: 0;
  left: 0;
  display: block !important;
  width: 100%;
  height: 100% !important;
  opacity: 0;
}
select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}
/* ------------------------------------------------------------------------------
*
*  # Passy
*
*  Styles for passy.min.js - password length checker and password generator
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.visual-password-indicator {
  display: inline-block;
  background-color: #999999;
  width: 40px;
  height: 4px;
}
.password-indicator-group {
  min-width: 80px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.label-indicator-absolute {
  position: relative;
}
.label-indicator-absolute .password-indicator-label-absolute {
  position: absolute;
  top: 50%;
  margin-top: -9px;
  right: 7px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.form-horizontal .label-indicator-absolute .label {
  right: 27px;
}
/* ------------------------------------------------------------------------------
 *
 *  # Twiter Typeahead
 *
 *  Styles for typeahead.bundle.min.js - input suggestion engine
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.twitter-typeahead {
  width: 100%;
}
.typeahead,
.tt-query,
.tt-hint {
  outline: 0;
}
.tt-hint {
  color: #999999;
}
.tt-dropdown-menu {
  width: 100%;
  margin-top: 1px;
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 3px;
  max-height: 300px;
  overflow-y: auto;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.typeahead-scrollable .tt-dropdown-menu {
  max-height: 150px;
}
.typeahead-rtl .tt-dropdown-menu {
  text-align: right;
}
.tt-suggestion {
  padding: 8px 15px;
  cursor: pointer;
}
.tt-suggestion.tt-cursor {
  background-color: #f5f5f5;
}
.tt-suggestion p {
  margin: 0;
}
.tt-dataset-group .tt-suggestion {
  padding-left: 24px;
  padding-right: 24px;
}
.tt-heading {
  font-size: 11px;
  line-height: 1.82;
  padding: 8px 15px;
  text-transform: uppercase;
  display: block;
  font-weight: 500;
  margin-top: 2px;
  margin-bottom: 2px;
}
.typeahead-template .empty-message {
  padding: 8px 15px;
  text-align: center;
}
/* ------------------------------------------------------------------------------
 *
 *  # Form Validation
 *
 *  jQuery plugin for simple clientside form validation
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.validation-error-label,
.validation-valid-label {
  margin-top: 7px;
  margin-bottom: 7px;
  display: block;
  color: #f44336;
  position: relative;
  padding-left: 26px;
}
.validation-valid-label {
  color: #4caf50;
}
.validation-error-label:before,
.validation-valid-label:before {
  font-family: 'icomoon';
  position: absolute;
  top: 2px;
  left: 0;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
.validation-error-label:empty,
.validation-valid-label:empty {
  display: none;
}
.validation-error-label:before {
  content: '\ed63';
}
.validation-valid-label:before {
  content: '\ee73';
}
/* ------------------------------------------------------------------------------
*
*  # X-editable
*
*  In-place editing with Twitter Bootstrap, jQuery UI or pure jQuery
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.editable {
  background-color: transparent;
}
.form-horizontal .editable {
  padding-top: 8px;
  display: inline-block;
}
.editable-pre-wrapped {
  white-space: pre-wrap;
}
.editableform .form-group:last-child {
  margin-bottom: 0;
}
.editableform .form-control {
  min-width: 150px;
}
.editableform .checkbox,
.editableform .radio {
  margin-top: 0;
  margin-bottom: 0;
}
.editableform .checkbox > div,
.editableform .radio > div {
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
}
.editable-buttons {
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
}
.editable-buttons.editable-buttons-bottom {
  display: block;
  margin-top: 20px;
  margin-left: 0;
  text-align: center;
}
.editable-buttons .editable-cancel {
  margin-left: 5px;
}
.editable-input {
  vertical-align: top;
  display: inline-block;
  width: auto;
  white-space: normal;
}
.editable-input .help-block {
  margin-bottom: 0;
}
.editable-input .input-group {
  width: 250px;
}
.editable-input .elastic {
  height: 100px;
}
.editableform-loading {
  width: 16px;
  height: 16px;
  margin: auto;
}
.editableform-loading:after {
  content: '\eb55';
  font-family: 'icomoon';
  font-size: 16px;
  display: block;
  line-height: 1;
  margin: auto;
  width: 16px;
  height: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation: rotation 1s ease infinite;
  -o-animation: rotation 1s ease infinite;
  animation: rotation 1s ease infinite;
}
.editable-error {
  color: #f44336;
}
.editable-error-block {
  max-width: 300px;
  margin: 7px 0 0 0;
  width: auto;
  white-space: normal;
}
.editable-bg-transition {
  color: inherit;
  background-color: transparent;
  -webkit-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
}
.editable-clear {
  clear: both;
  text-decoration: none;
  text-align: right;
}
.editable-clear-x {
  position: absolute;
  right: 9px;
  top: 10px;
  line-height: 1;
  cursor: pointer;
  z-index: 100;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.editable-clear-x:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.editable-clear-x:after {
  content: '\ed6b';
  font-family: 'icomoon';
  display: block;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.editable-click,
a.editable-click,
.editable-click:hover,
a.editable-click:hover {
  border-bottom: 1px dashed #1e88e5;
}
.editable-click.editable-disabled,
a.editable-click.editable-disabled,
.editable-click.editable-disabled:hover,
a.editable-click.editable-disabled:hover {
  color: #999999;
  cursor: default;
  border-bottom: 0;
}
.editable-click .label {
  margin-top: 1px;
  margin-bottom: 1px;
}
.editable-empty,
.editable-empty:hover,
.editable-empty:focus {
  font-style: italic;
  color: #f44336;
}
a.editable-empty,
a.editable-empty:hover,
a.editable-empty:focus {
  border-bottom-color: #f44336;
}
.combodate {
  white-space: nowrap;
}
.combodate .day,
.combodate .year,
.combodate .hour,
.combodate .minute {
  min-width: 80px;
}
.combodate .form-control {
  display: inline-block;
}
.input-daterange input {
  text-align: center;
}
.input-daterange input:first-child {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.input-daterange input:last-child {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.editable-address {
  display: block;
}
.editable-address > label > span {
  width: 70px;
  display: inline-block;
  margin-bottom: 7px;
}
.editable-date {
  padding: 0;
  margin: 0;
  float: left;
}
.editable-checklist label {
  white-space: nowrap;
}
.editable-checklist label > input[type="checkbox"] {
  vertical-align: middle;
  margin: 0;
}
.editable-wysihtml5 {
  width: 566px;
  height: 250px;
}
.editable-container.editable-popup {
  max-width: none !important;
}
.editable-container.editable-inline {
  display: inline-block;
  vertical-align: middle;
  width: auto;
}
.editable-container.editable-inline .editableform-loading {
  background-position: left 5px;
}
.editable-container.editable-inline .add-on .icon-th {
  margin-top: 3px;
  margin-left: 1px;
}
.editable-container.popover {
  width: auto;
  min-width: 200px;
}
/* ------------------------------------------------------------------------------
*
*  # Tokenfield for Bootstrap
*
*  Styles for tokenfield.js - Advanced tagging/tokenizing plugin for Bootstrap
*
*  Version: 1.0
*  Latest update: Mar 10, 2015
*
* ---------------------------------------------------------------------------- */
/* # Core
-------------------------------------------------- */
.tokenfield {
  height: auto;
  padding: 0 0 3px 0;
}
.tokenfield:after {
  content: '';
  display: table;
  clear: both;
}
.tokenfield .token {
  margin: 3px 0 0 3px;
  cursor: default;
  float: left;
  position: relative;
  border-radius: 2px;
}
.tokenfield .token.invalid {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  border-bottom: 1px dotted #f44336;
}
.tokenfield .token.invalid.active {
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
}
.tokenfield .token .token-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 10px;
  padding-right: 28px;
  background-color: #455a64;
  color: #fff;
  font-size: 12px;
  line-height: 1.6666667;
  border-radius: 2px;
}
.tokenfield .token .close {
  font-size: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 8px;
  line-height: 1;
  margin-top: -8px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.tokenfield .token .close:after {
  content: '\ed6b';
  font-family: 'icomoon';
  display: block;
  font-size: 16px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tokenfield .token .close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.tokenfield .token[class*=bg-] .token-label {
  background-color: inherit;
}
.tokenfield .token[class*=bg-] .close {
  color: inherit;
}
.tokenfield .twitter-typeahead {
  width: auto;
}
.tokenfield .token-input {
  background: none;
  width: 60px!important;
  min-width: 60px;
  height: 28px;
  padding-left: 7px;
  margin-top: 3px;
  font-size: 12px;
  line-height: 1.6666667;
  border: 0;
  outline: 0;
}
.tokenfield.readonly .token .token-label {
  padding-right: 10px;
}
.tokenfield.readonly .token .close {
  display: none;
}
.tokenfield.disabled {
  background-color: #fafafa;
  color: #999999;
}
.tokenfield.disabled .token,
.tokenfield.disabled .token .close {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.tokenfield.disabled,
.tokenfield.disabled .token-input,
.tokenfield.disabled .token,
.tokenfield.disabled .token .close {
  cursor: not-allowed;
}
.tokenfield.rtl {
  direction: rtl;
  text-align: right;
}
.tokenfield.rtl .token {
  margin-left: 0;
  margin-right: 3px;
}
.tokenfield.rtl .token .token-label {
  padding-left: 28px;
  padding-right: 10px;
}
/* # Sizing
-------------------------------------------------- */
.tokenfield.input-lg,
.input-group-lg .tokenfield {
  padding: 0 0 3px 0;
}
.input-group-lg .token > .token-label,
.tokenfield.input-lg .token > span {
  padding-top: 6px;
  padding-bottom: 6px;
}
.input-group-lg .token-input,
.tokenfield.input-lg .token-input {
  height: 32px;
}
.tokenfield.input-sm,
.input-group-sm .tokenfield {
  padding: 0 0 3px 0;
}
.input-group-sm .token > .token-label,
.tokenfield.input-sm .token > span {
  padding-top: 3px;
  padding-bottom: 3px;
}
.input-group-sm .token-input,
.tokenfield.input-sm .token-input {
  height: 26px;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap tags input
*
*  Styles for tagsinput.js - tags input for Bootstrap
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.bootstrap-tagsinput {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  display: inline-block;
  padding: 3px 3px 0 3px;
  vertical-align: middle;
  border-radius: 3px;
  width: 100%;
}
.bootstrap-tagsinput input {
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 28px;
  padding-left: 3px;
  padding-right: 3px;
  margin: 0;
  width: auto !important;
  min-width: 100px;
  font-size: 12px;
  line-height: 1.6666667;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}
.bootstrap-tagsinput .twitter-typeahead {
  width: auto;
}
.bootstrap-tagsinput .tt-dropdown-menu {
  margin-top: 5px;
  min-width: 200px;
}
.bootstrap-tagsinput .tag {
  margin-right: 3px;
  margin-bottom: 3px;
  color: #FFF;
  border: 0;
  border-radius: 2px;
  padding: 4px 10px;
  padding-right: 28px;
  float: left;
  font-size: 12px;
  line-height: 1.6666667;
  font-weight: 400;
  text-transform: none;
  position: relative;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 8px;
  line-height: 1;
  margin-top: -8px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: '\ed6b';
  font-family: 'icomoon';
  display: block;
  font-size: 16px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* ------------------------------------------------------------------------------
*
*  # Touchspin spinners
*
*  Styles for touchspin.min.js - spinner form control library
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.bootstrap-touchspin .input-group-btn-vertical {
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn {
  height: 36px;
  line-height: 1.5384616;
  margin-left: -1px;
}
.bootstrap-touchspin .input-group-btn-vertical i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -8px;
  margin-top: -8px;
  font-weight: normal;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border: 1px solid #dddddd;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border: 1px solid #dddddd;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.bootstrap-touchspin.input-group-lg .input-group-btn-vertical > .btn {
  height: 40px;
  line-height: 1.4285715;
  padding-left: 15px;
  padding-right: 15px;
}
.bootstrap-touchspin.input-group-sm .input-group-btn-vertical > .btn {
  height: 34px;
  line-height: 1.6666667;
  padding-left: 11px;
  padding-right: 11px;
}
.bootstrap-touchspin-prefix {
  border-right: 0;
}
.bootstrap-touchspin-postfix {
  border-left: 0;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap Duallistbox
*
*  Styles for listbox.js - A responsive dual listbox widget optimized for Bootstrap
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.bootstrap-duallistbox-container .box1 {
  margin-bottom: 20px;
}
.bootstrap-duallistbox-container select.form-control {
  padding: 5px;
}
.bootstrap-duallistbox-container option {
  display: block;
  padding: 7px 12px;
  border-radius: 2px;
}
.bootstrap-duallistbox-container.moveonselect .move,
.bootstrap-duallistbox-container.moveonselect .remove {
  display: none;
}
.bootstrap-duallistbox-container.moveonselect .moveall,
.bootstrap-duallistbox-container.moveonselect .removeall {
  width: 100%;
}
.bootstrap-duallistbox-container.moveonselect .btn-group .btn + .btn {
  border-left-width: 1px;
}
.bootstrap-duallistbox-container .buttons {
  width: 100%;
  margin-bottom: -1px;
}
.bootstrap-duallistbox-container .clear1,
.bootstrap-duallistbox-container .clear2 {
  display: none;
  margin-top: 20px;
}
.bootstrap-duallistbox-container .clear1,
.bootstrap-duallistbox-container .clear2,
.bootstrap-duallistbox-container .clear1:hover,
.bootstrap-duallistbox-container .clear2:hover,
.bootstrap-duallistbox-container .clear1:focus,
.bootstrap-duallistbox-container .clear2:focus {
  background-color: transparent;
  border: 0;
  color: #1e88e5;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bootstrap-duallistbox-container .box1.filtered .clear1,
.bootstrap-duallistbox-container .box2.filtered .clear2 {
  display: inline-block;
}
.bootstrap-duallistbox-container .move,
.bootstrap-duallistbox-container .remove,
.bootstrap-duallistbox-container .moveall,
.bootstrap-duallistbox-container .removeall {
  width: 50%;
}
.bootstrap-duallistbox-container .btn-group .btn {
  margin: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bootstrap-duallistbox-container .btn-group .btn + .btn {
  border-left-width: 0;
}
.bootstrap-duallistbox-container .btn-group .btn > i {
  float: none;
  text-align: center;
}
.bootstrap-duallistbox-container .filter {
  margin-bottom: 20px;
  position: relative;
}
.bootstrap-duallistbox-container .filter.placeholder {
  color: #999999;
}
.bootstrap-duallistbox-container .info-container .info {
  display: inline-block;
  margin-top: 20px;
}
.bootstrap-duallistbox-container .info-container .info .label {
  margin-right: 7px;
}
.sidebar .bootstrap-duallistbox-container {
  text-align: center;
}
.sidebar .bootstrap-duallistbox-container .box1,
.sidebar .bootstrap-duallistbox-container .box2 {
  float: none;
  width: 100%;
}
.sidebar .bootstrap-duallistbox-container .box1 {
  padding-bottom: 5px;
}
.sidebar .bootstrap-duallistbox-container .box1:after {
  content: '\e9c9';
  font-size: 16px;
  font-family: 'icomoon';
  display: block;
  text-align: center;
  line-height: 1;
  margin: 10px 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
@media (min-width: 1025px) {
  .bootstrap-duallistbox-container .box1 {
    margin-bottom: 0;
  }
  .sidebar .bootstrap-duallistbox-container .box1 {
    margin-bottom: 0;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Steps wizard
*
*  An all-in-one wizard plugin that is extremely flexible, compact and feature-rich
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.wizard {
  width: 100%;
  overflow: hidden;
}
.wizard > .steps .current-info,
.wizard > .content > .title {
  position: absolute;
  left: -99999px;
}
.wizard > .content {
  overflow: hidden;
  position: relative;
  width: auto;
  padding: 0;
}
.wizard > .content > .body {
  padding: 0 20px;
}
.wizard > .content > iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}
.wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
}
.wizard > .steps > ul {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin: 0;
  padding: 0;
  list-style: none;
}
.wizard > .steps > ul > li {
  display: table-cell;
  width: auto;
  vertical-align: top;
  text-align: center;
  position: relative;
}
.wizard > .steps > ul > li a {
  position: relative;
  padding-top: 48px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}
.wizard > .steps > ul > li:before,
.wizard > .steps > ul > li:after {
  content: '';
  display: block;
  position: absolute;
  top: 43px;
  width: 50%;
  height: 2px;
  background-color: #00bcd4;
  z-index: 9;
}
.wizard > .steps > ul > li:before {
  left: 0;
}
.wizard > .steps > ul > li:after {
  right: 0;
}
.wizard > .steps > ul > li:first-child:before,
.wizard > .steps > ul > li:last-child:after {
  content: none;
}
.wizard > .steps > ul > li.current:after,
.wizard > .steps > ul > li.current ~ li:before,
.wizard > .steps > ul > li.current ~ li:after {
  background-color: #eeeeee;
}
.wizard > .steps > ul > li.current > a {
  color: #333333;
  cursor: default;
}
.wizard > .steps > ul > li.current .number {
  font-size: 0;
  border-color: #00bcd4;
  background-color: #fff;
  color: #00bcd4;
}
.wizard > .steps > ul > li.current .number:after {
  content: '\e913';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 34px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.wizard > .steps > ul > li.disabled a,
.wizard > .steps > ul > li.disabled a:hover,
.wizard > .steps > ul > li.disabled a:focus {
  color: #999999;
  cursor: default;
}
.wizard > .steps > ul > li.done a,
.wizard > .steps > ul > li.done a:hover,
.wizard > .steps > ul > li.done a:focus {
  color: #999999;
}
.wizard > .steps > ul > li.done .number {
  font-size: 0;
  background-color: #00bcd4;
  border-color: #00bcd4;
  color: #fff;
}
.wizard > .steps > ul > li.done .number:after {
  content: '\ed6f';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  line-height: 34px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.wizard > .steps > ul > li.error .number {
  border-color: #f44336;
  color: #f44336;
}
@media (max-width: 768px) {
  .wizard > .steps > ul {
    margin-bottom: 20px;
  }
  .wizard > .steps > ul > li {
    display: block;
    float: left;
    width: 50%;
  }
  .wizard > .steps > ul > li > a {
    margin-bottom: 0;
  }
  .wizard > .steps > ul > li:first-child:before,
  .wizard > .steps > ul > li:last-child:after {
    content: '';
  }
  .wizard > .steps > ul > li:last-child:after {
    background-color: #00bcd4;
  }
}
@media (max-width: 480px) {
  .wizard > .steps > ul > li {
    width: 100%;
  }
  .wizard > .steps > ul > li.current:after {
    background-color: #00bcd4;
  }
}
.wizard > .steps .number {
  background-color: #ffffff;
  color: #ccc;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -19px;
  width: 38px;
  height: 38px;
  border: 2px solid #eeeeee;
  font-size: 14px;
  border-radius: 50%;
  z-index: 10;
  line-height: 34px;
  text-align: center;
}
.panel-flat > .wizard > .steps > ul {
  border-top: 1px solid #dddddd;
}
.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  padding: 20px;
  padding-top: 0;
}
.wizard > .actions > ul {
  float: right;
  list-style: none;
  padding: 0;
  margin: 0;
}
.wizard > .actions > ul:after {
  content: '';
  display: table;
  clear: both;
}
.wizard > .actions > ul > li {
  float: left;
}
.wizard > .actions > ul > li + li {
  margin-left: 10px;
}
.wizard > .actions > ul > li > a {
  background: #2196f3;
  color: #fff;
  display: block;
  padding: 7px 12px;
  border-radius: 2px;
  border: 1px solid transparent;
}
.wizard > .actions > ul > li > a:hover,
.wizard > .actions > ul > li > a:focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}
.wizard > .actions > ul > li > a:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.wizard > .actions > ul > li > a[href="#previous"] {
  background-color: #fcfcfc;
  color: #333333;
  border: 1px solid #dddddd;
}
.wizard > .actions > ul > li > a[href="#previous"]:hover,
.wizard > .actions > ul > li > a[href="#previous"]:focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
}
.wizard > .actions > ul > li > a[href="#previous"]:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.04) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.04) inset;
}
.wizard > .actions > ul > li.disabled > a,
.wizard > .actions > ul > li.disabled > a:hover,
.wizard > .actions > ul > li.disabled > a:focus {
  color: #999999;
}
.wizard > .actions > ul > li.disabled > a[href="#previous"],
.wizard > .actions > ul > li.disabled > a[href="#previous"]:hover,
.wizard > .actions > ul > li.disabled > a[href="#previous"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* ------------------------------------------------------------------------------
*
*  # Form wizard
*
*  jQuery plugin which turns a form into a multistep wizard
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.panel-flat > .ui-formwizard {
  border-top: 1px solid #dddddd;
}
.form-wizard-title {
  margin: 0 0 20px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd;
}
.form-wizard-count {
  float: left;
  display: block;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 2px solid #4caf50;
  color: #4caf50;
  text-align: center;
  line-height: 34px;
  margin-right: 10px;
}
.ui-formwizard .step {
  padding: 20px;
  padding-bottom: 0;
}
.form-wizard-actions {
  text-align: right;
  padding: 20px;
  padding-top: 0;
}
.panel-body .form-wizard-actions {
  padding: 0;
}
.form-wizard-actions .btn + .btn {
  margin-left: 5px;
}
/* ------------------------------------------------------------------------------
*
*  # Stepy wizard
*
*  jQuery plugin which generates a customizable wizard from form fieldsets
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.stepy-step {
  padding: 20px;
  padding-top: 0;
}
.stepy-header {
  list-style: none;
  padding: 0;
  margin: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}
.panel-flat > .stepy-header {
  border-top: 1px solid #dddddd;
}
.stepy-header li {
  cursor: pointer;
  display: table-cell;
  vertical-align: top;
  width: auto;
  padding: 20px 0;
  text-align: center;
  position: relative;
}
.stepy-header li span {
  display: block;
  margin-top: 10px;
  color: #999999;
}
.stepy-header li:before,
.stepy-header li:after {
  content: '';
  display: block;
  position: absolute;
  top: 43px;
  width: 50%;
  height: 2px;
  background-color: #00bcd4;
  z-index: 9;
}
.stepy-header li:before {
  left: 0;
}
.stepy-header li:after {
  right: 0;
}
.stepy-header li:first-child:before,
.stepy-header li:last-child:after {
  content: none;
}
.stepy-header li div {
  background-color: #00bcd4;
  font-size: 0;
  position: relative;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  width: 38px;
  height: 38px;
  border: 2px solid #00bcd4;
  z-index: 10;
  line-height: 34px;
  text-align: center;
  border-radius: 50%;
}
.stepy-header li div:after {
  content: '\ed6c';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 34px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.stepy-header li.stepy-active:after,
.stepy-header li.stepy-active ~ li:before,
.stepy-header li.stepy-active ~ li:after {
  background-color: #f5f5f5;
}
.stepy-header li.stepy-active ~ li div {
  border-color: #eeeeee;
  background-color: #fff;
  color: #ccc;
  font-size: 14px;
}
.stepy-header li.stepy-active ~ li div:after {
  content: none;
}
.stepy-header li.stepy-active div {
  cursor: auto;
  border-color: #00bcd4;
  background-color: #fff;
  color: #00bcd4;
}
.stepy-header li.stepy-active div:after {
  content: '\e913';
}
.stepy-header li.stepy-active span {
  color: #333333;
}
@media (max-width: 769px) {
  .stepy-header {
    margin-bottom: 20px;
  }
  .stepy-header li {
    display: block;
    float: left;
    width: 50%;
    padding-bottom: 0;
  }
  .stepy-header li:first-child:before,
  .stepy-header li:last-child:after {
    content: '';
  }
  .stepy-header li.stepy-active:last-child:after {
    background-color: #00bcd4;
  }
}
@media (max-width: 480px) {
  .stepy-header li {
    width: 100%;
  }
  .stepy-header li.stepy-active:after {
    background-color: #00bcd4;
  }
}
.stepy-navigator {
  text-align: right;
}
.stepy-navigator .btn + .btn {
  margin-left: 5px;
}
/* ------------------------------------------------------------------------------
*
*  # Summernote editor
*
*  Super simple WYSIWYG Editor for Bootstrap framework
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.note-editor {
  border: 1px solid #dddddd;
  border-radius: 3px;
}
.note-editor .note-dropzone {
  position: absolute;
  z-index: 1;
  display: none;
  color: #2196f3;
  background-color: #fff;
  border: 2px dashed #2196f3;
  pointer-events: none;
  opacity: 0.95;
  filter: alpha(opacity=95);
}
.note-editor .note-dropzone .note-dropzone-message {
  display: table-cell;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
}
.note-editor .note-dropzone.hover {
  color: #2196f3;
  border: 2px dashed #2196f3;
}
.note-editor.dragover .note-dropzone {
  display: table;
}
.note-editor .note-toolbar {
  background-color: #fcfcfc;
  border-bottom: 1px solid #dddddd;
  padding: 15px 20px;
  padding-top: 0;
  margin: 0;
  border-radius: 3px;
}
.note-editor.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
}
.note-editor.fullscreen .note-editable {
  background-color: #fff;
}
.note-editor.fullscreen .note-resizebar {
  display: none;
}
.note-editor.codeview .note-editable {
  display: none;
}
.note-editor.codeview .note-codable {
  display: block;
}
.note-editor .note-statusbar {
  background-color: #fcfcfc;
}
.note-editor .note-statusbar .note-resizebar {
  width: 100%;
  height: 8px;
  cursor: s-resize;
  border-top: 1px solid #dddddd;
}
.note-editor .note-statusbar .note-resizebar .note-icon-bar {
  width: 20px;
  margin: 1px auto;
  border-top: 1px solid #aaaaaa;
}
.note-editor .note-editable {
  padding: 20px;
  overflow: auto;
  outline: 0;
  min-height: 150px;
}
.note-editor .note-editable[contenteditable="false"] {
  background-color: #eeeeee;
}
.note-editor .note-codable {
  display: none;
  width: 100%;
  padding: 20px;
  margin-bottom: 0;
  font-family: Menlo, Monaco, monospace, sans-serif;
  color: #fff;
  background-color: #333333;
  border: 0;
  border-radius: 0;
  resize: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.note-air-editor {
  outline: 0;
}
.note-popover .popover {
  max-width: none;
}
.note-popover .popover .popover-content {
  padding: 15px;
  padding-top: 0;
  margin: 0;
}
.note-popover .popover .popover-content a {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.note-popover .popover .popover-content > .btn-group:last-child {
  margin-right: 0;
}
.note-popover .popover .arrow {
  left: 20px;
}
.note-popover .popover .popover-content > .btn-group,
.note-toolbar > .btn-group {
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 0;
}
.note-popover .popover .popover-content .note-style h1,
.note-toolbar .note-style h1,
.note-popover .popover .popover-content .note-style h2,
.note-toolbar .note-style h2,
.note-popover .popover .popover-content .note-style h3,
.note-toolbar .note-style h3,
.note-popover .popover .popover-content .note-style h4,
.note-toolbar .note-style h4,
.note-popover .popover .popover-content .note-style h5,
.note-toolbar .note-style h5,
.note-popover .popover .popover-content .note-style h6,
.note-toolbar .note-style h6,
.note-popover .popover .popover-content .note-style blockquote,
.note-toolbar .note-style blockquote {
  margin: 0;
}
.note-popover .popover .popover-content .note-table,
.note-toolbar .note-table {
  min-width: 0;
}
.note-popover .popover .popover-content .note-table.dropdown-menu,
.note-toolbar .note-table.dropdown-menu {
  padding: 10px;
}
.note-popover .popover .popover-content .note-table .note-dimension-picker,
.note-toolbar .note-table .note-dimension-picker {
  font-size: 18px;
  margin-bottom: 10px;
}
.note-popover .popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-mousecatcher,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
  position: absolute!important;
  z-index: 3;
  width: 10em;
  height: 10em;
  cursor: pointer;
}
.note-popover .popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-unhighlighted,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
  position: relative!important;
  z-index: 1;
  width: 5em;
  height: 5em;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat;
}
.note-popover .popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-highlighted,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-highlighted {
  position: absolute!important;
  z-index: 2;
  width: 1em;
  height: 1em;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat;
}
.note-popover .popover .popover-content .note-color .dropdown-menu,
.note-toolbar .note-color .dropdown-menu {
  min-width: 330px;
}
.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group,
.note-toolbar .note-color .dropdown-menu .btn-group {
  margin: 7px 12px;
}
.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-palette-title,
.note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
  margin-bottom: 10px;
  font-weight: 500;
}
.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
  padding: 6px 11px;
  cursor: pointer;
  background-color: #F5F5F5;
  text-align: center;
  margin-bottom: 10px;
}
.note-popover .popover .popover-content .note-para .dropdown-menu,
.note-toolbar .note-para .dropdown-menu {
  min-width: 245px;
  padding: 10px;
  text-align: center;
}
.note-popover .popover .popover-content .note-para .dropdown-menu > div:first-child,
.note-toolbar .note-para .dropdown-menu > div:first-child {
  margin-right: 10px;
}
.note-popover .popover .popover-content .dropdown-menu,
.note-toolbar .dropdown-menu {
  min-width: 90px;
}
.note-popover .popover .popover-content .dropdown-menu.right,
.note-toolbar .dropdown-menu.right {
  right: 0;
  left: auto;
}
.note-popover .popover .popover-content .dropdown-menu.right:before,
.note-toolbar .dropdown-menu.right:before {
  right: 9px;
  left: auto!important;
}
.note-popover .popover .popover-content .dropdown-menu.right:after,
.note-toolbar .dropdown-menu.right:after {
  right: 10px;
  left: auto!important;
}
.note-popover .popover .popover-content .dropdown-menu li a i,
.note-toolbar .dropdown-menu li a i {
  display: none;
}
.note-popover .popover .popover-content .dropdown-menu li a.checked,
.note-toolbar .dropdown-menu li a.checked {
  background-color: #f5f5f5;
}
.note-popover .popover .popover-content .note-color-palette,
.note-toolbar .note-color-palette {
  line-height: 1;
}
.note-popover .popover .popover-content .note-color-palette div .note-color-btn,
.note-toolbar .note-color-palette div .note-color-btn {
  width: 17px;
  height: 17px;
  padding: 0;
  margin: 0;
  border: 1px solid #fff;
}
.note-popover .popover .popover-content .note-color-palette div .note-color-btn:hover,
.note-toolbar .note-color-palette div .note-color-btn:hover {
  border: 1px solid #333333;
}
.note-dialog > div {
  display: none;
}
.note-dialog .note-image-dialog .mote-dropzone {
  min-height: 100px;
  margin-bottom: 10px;
  font-size: 25px;
  color: #eeeeee;
  text-align: center;
  border: 4px dashed #eeeeee;
}
.note-dialog .note-help-dialog {
  color: #ccc;
  background-color: transparent;
  background-color: #333333 !important;
  border: 0;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.note-dialog .note-help-dialog a {
  color: #fff;
}
.note-dialog .note-help-dialog .title {
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  border-bottom: 1px solid #fff;
}
.note-dialog .note-help-dialog .modal-content {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.note-dialog .note-help-dialog .modal-close {
  color: #999999;
  cursor: pointer;
}
.note-dialog .note-help-dialog .note-shortcut-layout {
  width: 100%;
}
.note-dialog .note-help-dialog .note-shortcut-layout td {
  vertical-align: top;
}
.note-dialog .note-help-dialog .note-shortcut {
  margin-top: 8px;
}
.note-dialog .note-help-dialog .note-shortcut th {
  color: #eeeeee;
  text-align: left;
}
.note-dialog .note-help-dialog .note-shortcut td:first-child {
  min-width: 110px;
  padding-right: 10px;
  font-family: "Courier New";
  color: #eeeeee;
  text-align: right;
}
.note-handle .note-control-selection {
  position: absolute;
  display: none;
  border: 1px solid #333333;
}
.note-handle .note-control-selection > div {
  position: absolute;
}
.note-handle .note-control-selection .note-control-selection-bg {
  width: 100%;
  height: 100%;
  background-color: #333333;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.note-handle .note-control-selection .note-control-handle,
.note-handle .note-control-selection .note-control-holder,
.note-handle .note-control-selection .note-control-sizing {
  width: 7px;
  height: 7px;
  border: 1px solid #333333;
}
.note-handle .note-control-selection .note-control-sizing {
  background-color: #fff;
}
.note-handle .note-control-selection .note-control-nw {
  top: -5px;
  left: -5px;
  border-right: 0;
  border-bottom: 0;
}
.note-handle .note-control-selection .note-control-ne {
  top: -5px;
  right: -5px;
  border-bottom: 0;
  border-left: 0;
}
.note-handle .note-control-selection .note-control-sw {
  bottom: -5px;
  left: -5px;
  border-top: 0;
  border-right: 0;
}
.note-handle .note-control-selection .note-control-se {
  right: -5px;
  bottom: -5px;
  cursor: se-resize;
}
.note-handle .note-control-selection .note-control-selection-info {
  right: 0;
  bottom: 0;
  padding: 5px;
  margin: 5px;
  color: #fff;
  background-color: #333333;
  border-radius: 3px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
/* ------------------------------------------------------------------------------
*
*  # WYSIHTML5 editor
*
*  An open source rich text editor based on HTML5 technology
*
*  Version: 1.0
*  Latest update: Mar 10, 2015
*
* ---------------------------------------------------------------------------- */
.wysihtml5 {
  width: 100%;
  height: auto;
  min-height: 350px;
  max-height: 450px;
  resize: vertical;
  background-color: #fff;
  border: 1px solid #dddddd;
  padding: 20px;
  overflow: scroll;
  outline: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.wysihtml5-sandbox {
  width: 100%!important;
}
.bootstrap-wysihtml5-insert-link-modal .modal-dialog,
.bootstrap-wysihtml5-insert-image-modal .modal-dialog {
  width: 566px;
}
.wysihtml5-toolbar {
  margin: 0;
  padding: 15px 20px;
  padding-bottom: 0;
  display: block;
  background-color: #fcfcfc;
  border: 1px solid #dddddd;
  border-bottom: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.wysihtml5-toolbar > li {
  display: inline-block;
  list-style: none;
  margin: 0 15px 15px 0;
}
.wysihtml5-toolbar .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.wysihtml5-toolbar .dropdown-menu .color-select > li > a:hover,
.wysihtml5-toolbar .dropdown-menu .color-select > li > a:focus {
  background-color: #f5f5f5;
  color: #333333;
}
.wysihtml5-commands-disabled .wysihtml5-toolbar .dropdown-menu {
  display: none !important;
}
.wysihtml5-toolbar .dropdown-toggle > [class*=icon-] {
  margin-right: 7px;
}
.wysihtml5-toolbar .dropdown-toggle .caret {
  margin-left: 7px;
}
.wysihtml5-toolbar a[data-wysihtml5-command=bold] {
  font-weight: bold;
}
.wysihtml5-toolbar a[data-wysihtml5-command=italic] {
  font-style: italic;
}
.wysihtml5-toolbar a[data-wysihtml5-command=underline] {
  text-decoration: underline;
}
.wysihtml5-toolbar a.btn.wysihtml5-command-active {
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.wysihtml5-toolbar a.wysihtml5-colors-title {
  padding-left: 40px;
}
.wysihtml5-toolbar .wysihtml5-colors {
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 10px;
  border-radius: 100px;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="black"] {
  background: #000000 !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="silver"] {
  background: #c0c0c0 !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="gray"] {
  background: #808080 !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="maroon"] {
  background: #800000 !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="red"] {
  background: #ff0000 !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="purple"] {
  background: #800080 !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="green"] {
  background: #008000 !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="olive"] {
  background: #808000 !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="navy"] {
  background: #000080 !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="blue"] {
  background: #0000ff !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="orange"] {
  background: #ffa500 !important;
}
.wysihtml5-toolbar .wysiwyg-color-black {
  color: black;
}
.wysihtml5-toolbar .wysiwyg-color-silver {
  color: silver;
}
.wysihtml5-toolbar .wysiwyg-color-gray {
  color: gray;
}
.wysihtml5-toolbar .wysiwyg-color-white {
  color: white;
}
.wysihtml5-toolbar .wysiwyg-color-maroon {
  color: maroon;
}
.wysihtml5-toolbar .wysiwyg-color-red {
  color: red;
}
.wysihtml5-toolbar .wysiwyg-color-purple {
  color: purple;
}
.wysihtml5-toolbar .wysiwyg-color-fuchsia {
  color: fuchsia;
}
.wysihtml5-toolbar .wysiwyg-color-green {
  color: green;
}
.wysihtml5-toolbar .wysiwyg-color-lime {
  color: lime;
}
.wysihtml5-toolbar .wysiwyg-color-olive {
  color: olive;
}
.wysihtml5-toolbar .wysiwyg-color-yellow {
  color: yellow;
}
.wysihtml5-toolbar .wysiwyg-color-navy {
  color: navy;
}
.wysihtml5-toolbar .wysiwyg-color-blue {
  color: blue;
}
.wysihtml5-toolbar .wysiwyg-color-teal {
  color: teal;
}
.wysihtml5-toolbar .wysiwyg-color-aqua {
  color: aqua;
}
.wysihtml5-toolbar .wysiwyg-color-orange {
  color: orange;
}
/* ------------------------------------------------------------------------------
 *
 *  # Ace code editor
 *
 *  An embeddable code editor written in JavaScript
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.ace_editor {
  height: 400px;
  position: relative;
}
/* ------------------------------------------------------------------------------
 *
 *  # Daterange picker
 *
 *  Date range picker component for Bootstrap
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.daterangepicker {
  position: absolute;
  left: 0;
  margin-top: 5px;
  width: auto;
  padding: 0;
}
.daterangepicker.dropdown-menu {
  max-width: none;
  background-color: transparent;
  border: 0;
  z-index: 1000;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.daterangepicker.dropup {
  margin-top: -5px;
}
.daterangepicker .ranges,
.daterangepicker .calendar {
  float: left;
}
.daterangepicker.opensleft .calendars {
  float: left;
}
.daterangepicker.opensright .calendars {
  float: right;
}
.daterangepicker.single .ranges,
.daterangepicker.single .calendar {
  float: none;
}
.daterangepicker.show-calendar .calendar {
  display: block;
}
.daterangepicker .calendar {
  display: none;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin: 5px;
  padding: 10px;
}
.daterangepicker table {
  width: 100%;
  margin: 0;
}
.daterangepicker table tbody th,
.daterangepicker table tbody td {
  cursor: pointer;
}
.daterangepicker th,
.daterangepicker td {
  white-space: nowrap;
  text-align: center;
}
.daterangepicker th.week,
.daterangepicker td.week {
  font-size: 80%;
  color: #ccc;
}
.daterangepicker th {
  color: #999999;
  font-weight: normal;
  font-size: 12px;
  min-width: 36px;
}
.daterangepicker th > i {
  top: 0;
  color: #888888;
}
.daterangepicker th.prev,
.daterangepicker th.next {
  cursor: pointer;
}
.daterangepicker th.available:hover > i,
.daterangepicker th.available:focus > i {
  color: #333333;
}
.daterangepicker td.available:hover,
.daterangepicker td.available:focus {
  background-color: #f5f5f5;
}
.daterangepicker td.off,
.daterangepicker td.disabled {
  color: #ccc;
}
.daterangepicker td.disabled {
  cursor: not-allowed;
}
.daterangepicker td.in-range {
  background-color: #f5f5f5;
}
.daterangepicker td.active,
.daterangepicker td.active:hover,
.daterangepicker td.active:focus {
  background-color: #26a69a;
  color: #fff;
  border-radius: 2px;
}
.daterangepicker .table-condensed tr > th,
.daterangepicker .table-condensed tr > td {
  padding: 10px;
  line-height: 1;
}
.daterangepicker .table-condensed .month {
  font-size: 15px;
  line-height: 1;
  color: #333333;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 400;
}
.daterangepicker select {
  display: inline-block;
}
.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}
.daterangepicker select.yearselect {
  width: 40%;
}
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 60px;
  margin-bottom: 0;
  display: inline-block;
}
.daterangepicker .daterangepicker_input {
  position: relative;
}
.daterangepicker .daterangepicker_input i {
  position: absolute;
  right: 12px;
  top: auto;
  bottom: 10px;
  color: #999999;
}
.daterangepicker .daterangepicker_input .form-control {
  padding-right: 35px;
}
.daterangepicker .calendar-time {
  text-align: center;
  margin: 12px 0;
}
.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}
.ranges {
  background-color: #ffffff;
  position: relative;
  border: 1px solid #dddddd;
  border-radius: 3px;
  width: 200px;
  margin-top: 5px;
}
.opensright .ranges {
  margin-left: 0;
}
.opensleft .ranges {
  margin-right: 0;
}
.ranges ul {
  list-style: none;
  margin: 0;
  padding: 5px 0;
}
.ranges ul + .daterangepicker-inputs {
  border-top: 1px solid #e5e5e5;
}
.ranges ul li {
  color: #333333;
  padding: 8px 12px;
  cursor: pointer;
  margin-top: 1px;
}
.ranges ul li:first-child {
  margin-top: 0;
}
.ranges ul li:hover,
.ranges ul li:focus {
  background-color: #f5f5f5;
}
.ranges ul li.active {
  color: #ffffff;
  background-color: #26a69a;
}
.ranges .daterangepicker-inputs {
  padding: 12px;
  padding-top: 17px;
}
.ranges .daterangepicker-inputs .daterangepicker_input + .daterangepicker_input {
  margin-top: 17px;
}
.ranges .daterangepicker-inputs .daterangepicker_input > span {
  display: block;
  font-size: 12px;
  margin-bottom: 7px;
  color: #999999;
}
.ranges .daterangepicker-inputs + .range_inputs {
  border-top: 1px solid #e5e5e5;
}
.ranges .range_inputs {
  padding: 12px;
}
.ranges .range_inputs .btn {
  display: block;
  width: 100%;
}
.ranges .range_inputs .btn + .btn {
  margin-top: 12px;
}
@media (min-width: 769px) {
  .ranges {
    margin: 5px;
  }
}
.daterange-custom {
  cursor: pointer;
}
.daterange-custom:after {
  content: '';
  display: table;
  clear: both;
}
.daterange-custom .label,
.daterange-custom .badge {
  margin: 4px 0 0 7px;
  vertical-align: top;
}
.daterange-custom .label-icon {
  margin-top: 0;
  margin-right: 5px;
}
.daterange-custom-display {
  display: inline-block;
  position: relative;
  padding-left: 21px;
  line-height: 1;
}
.daterange-custom-display:after {
  content: '\e9c9';
  font-family: 'icomoon';
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}
.daterange-custom.is-opened .daterange-custom-display:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.daterange-custom-display > i {
  display: inline-block;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.015em;
}
.daterange-custom-display b {
  display: inline-block;
  margin-left: 4px;
  font-weight: 400;
}
.daterange-custom-display b > i {
  font-size: 11px;
  display: block;
  line-height: 12px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
}
.daterange-custom-display em {
  line-height: 30px;
  vertical-align: top;
  margin: 0 4px;
}
@media (max-width: 769px) {
  .opensleft,
  .opensright {
    left: 0!important;
    right: 0;
  }
  .opensleft .calendars,
  .opensright .calendars {
    float: none;
  }
  .daterangepicker.opensleft .ranges,
  .daterangepicker.opensright .ranges,
  .daterangepicker.opensleft .calendar,
  .daterangepicker.opensright .calendar,
  .daterangepicker.opensleft .calendars,
  .daterangepicker.opensright .calendars {
    float: none;
  }
  .daterangepicker {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .daterangepicker .calendar {
    margin-left: 0;
    margin-right: 0;
  }
  .daterangepicker .ranges {
    width: 100%;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Pick-a-date - Picker base
*
*  The mobile-friendly, responsive, and lightweight jQuery date & time input picker
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.picker {
  width: 100%;
  text-align: left;
  position: absolute;
  top: 100%;
  margin-top: -1px;
  z-index: 990;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.picker__input {
  cursor: default;
}
.picker__holder {
  width: 100%;
  overflow-y: auto;
  position: absolute;
  display: none;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-top-width: 0;
  border-bottom-width: 0;
  max-width: 280px;
  max-height: 0;
  -webkit-overflow-scrolling: touch;
}
.picker--opened .picker__holder {
  max-height: 480px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  display: block;
}
/* ------------------------------------------------------------------------------
*
*  # Pick-a-date - Date picker
*
*  The mobile-friendly, responsive, and lightweight jQuery date & time input picker
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.picker__box {
  padding: 5px;
}
.picker__header {
  text-align: center;
  position: relative;
  font-size: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.picker__month,
.picker__year {
  font-weight: 500;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.picker__year {
  color: #999999;
  font-size: 12px;
  font-weight: normal;
}
.picker__select--month,
.picker__select--year {
  border-color: #dddddd;
  height: 32px;
  font-size: 13px;
  line-height: 1.6666667;
  margin-left: 5px;
  margin-right: 5px;
  outline: 0;
}
.picker__select--month {
  width: 35%;
}
.picker__select--year {
  width: 22.5%;
}
.picker__nav--prev,
.picker__nav--next {
  position: absolute;
  padding: 8px;
  top: 50%;
  margin-top: -16px;
  border-radius: 2px;
  line-height: 1;
}
.picker__nav--prev:before,
.picker__nav--next:before {
  font-family: 'icomoon';
  display: block;
  font-size: 16px;
  width: 16px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.picker__nav--prev:hover,
.picker__nav--next:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
.picker__nav--prev {
  left: 0;
}
.picker__nav--prev:before {
  content: '\e9c8';
}
.picker__nav--next {
  right: 0;
}
.picker__nav--next:before {
  content: '\e9cb';
}
.picker__nav--disabled,
.picker__nav--disabled:hover,
.picker__nav--disabled:before,
.picker__nav--disabled:before:hover {
  cursor: default;
  background: none;
  border-right-color: #f5f5f5;
  border-left-color: #f5f5f5;
}
.picker__table {
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  font-size: inherit;
  width: 100%;
  margin-bottom: 10px;
}
.picker__table td {
  margin: 0;
  padding: 0;
}
.picker__weekday {
  width: 14.285714286%;
  font-size: 12px;
  text-align: center;
  padding-bottom: 10px;
  color: #999999;
  font-weight: 400;
}
.picker__day {
  padding: 7px;
}
.picker__day--today {
  position: relative;
  background-color: #f5f5f5;
}
.picker__day--today:before {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 0;
  height: 0;
  border-top: 6px solid #26a69a;
  border-left: 6px solid transparent;
}
.picker__day--outfocus {
  color: #ccc;
}
.picker__day--infocus:hover,
.picker__day--outfocus:hover {
  cursor: pointer;
  color: #333333;
  background-color: #f5f5f5;
}
.picker__day--highlighted:before {
  border-top-color: #fff;
}
.picker__day--highlighted,
.picker__day--selected {
  border-radius: 3px;
}
.picker__day--highlighted,
.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted {
  cursor: pointer;
  color: #fff;
  background-color: #26a69a;
}
.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
  background-color: #26a69a;
  color: #fff;
}
.picker__day--disabled,
.picker__day--disabled:hover {
  background: #fafafa;
  color: #999999;
  cursor: default;
}
.picker__day--disabled:before {
  border-top-color: #999;
}
.picker__day--highlighted .picker__day--disabled,
.picker__day--highlighted .picker__day--disabled:hover {
  background-color: #bbbbbb;
}
.picker__footer {
  text-align: center;
}
.picker__footer button {
  border: 0;
  background: #fff;
  padding: 7px 12px;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
}
.picker__footer button:hover,
.picker__footer button:focus {
  outline: 0;
  background-color: #f5f5f5;
}
.picker__footer button:before {
  height: 0;
}
.picker__button--today:before {
  content: '';
  margin-right: 5px;
  position: relative;
  display: inline-block;
  top: -1px;
  width: 0;
  border-top: 6px solid #2196f3;
  border-left: 6px solid transparent;
}
.picker__button--close:before {
  content: '\D7';
  display: inline-block;
  position: relative;
  margin-right: 5px;
  top: 1px;
  font-size: 16px;
}
.picker__button--clear:before {
  content: '';
  display: inline-block;
  position: relative;
  top: -3px;
  width: 8px;
  margin-right: 5px;
  border-top: 2px solid #f44336;
}
/* ------------------------------------------------------------------------------
*
*  # Pick-a-date - Time picker
*
*  The mobile-friendly, responsive, and lightweight jQuery date & time input picker
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.picker--time {
  min-width: 256px;
  max-width: 320px;
}
.picker--time .picker__box {
  padding: 0;
}
.picker__list {
  list-style: none;
  padding: 5px 0;
  margin: 0;
  background-color: #ffffff;
  max-height: 250px;
  overflow-y: auto;
}
.picker__list-item {
  position: relative;
  padding: 7px 12px;
}
.picker__list-item:hover,
.picker__list-item:focus {
  cursor: pointer;
  background-color: #f5f5f5;
  z-index: 10;
}
.picker--time .picker__button--clear {
  display: block;
  width: 100%;
  margin: 0;
  padding: 7px 12px;
  background-color: #fcfcfc;
  margin-top: 1px;
  outline: 0;
  border: 0;
  border-top: 1px solid #eeeeee;
  text-align: center;
  margin-bottom: -5px;
  margin-top: 5px;
}
.picker--time .picker__button--clear:hover,
.picker--time .picker__button--clear:focus {
  background-color: #f5f5f5;
}
.picker__list-item--highlighted {
  z-index: 10;
}
.picker__list-item--highlighted,
.picker__list-item--highlighted:hover,
.picker--focused .picker__list-item--highlighted {
  cursor: pointer;
  color: #fff;
  background-color: #26a69a;
}
.picker__list-item--selected,
.picker__list-item--selected:hover,
.picker--focused .picker__list-item--selected {
  background-color: #26a69a;
  color: #fff;
  z-index: 10;
}
.picker__list-item--disabled,
.picker__list-item--disabled:hover,
.picker--focused .picker__list-item--disabled {
  background-color: #fafafa;
  color: #999999;
  cursor: default;
  z-index: auto;
}
/* ------------------------------------------------------------------------------
*
*  # Anytime picker
*
*  jQuery datepicker/timepicker and a Date/String parse/format utility
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.AnyTime-pkr {
  text-align: center;
}
.AnyTime-pkr * {
  margin: 0;
  padding: 0;
  list-style: none;
}
.AnyTime-pkr .AnyTime-date {
  float: left;
}
.AnyTime-pkr .AnyTime-date + .AnyTime-time {
  margin-left: 50px;
  float: left;
}
.AnyTime-win {
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  display: inline-block;
  border-radius: 3px;
  z-index: 10;
}
.AnyTime-cloak {
  position: absolute;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.AnyTime-hdr {
  font-size: 15px;
  margin: 15px 10px;
  line-height: 1;
}
.AnyTime-x-btn {
  font-size: 0;
  cursor: pointer;
  float: right;
  border-radius: 2px;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.AnyTime-x-btn:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.AnyTime-x-btn:after {
  content: '\ed6b';
  font-family: 'icomoon';
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.AnyTime-lbl {
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: 500;
  margin-top: 25px;
}
.AnyTime-lbl:first-child {
  margin-top: 5px;
}
.AnyTime-yrs .ui-state-highlight,
.AnyTime-yrs .ui-state-highlight:hover {
  background-color: #f44336;
  color: #fff;
}
.AnyTime-mons .ui-state-highlight,
.AnyTime-mons .ui-state-highlight:hover {
  background-color: #26a69a;
  color: #fff;
}
.AnyTime-time .ui-state-highlight,
.AnyTime-time .ui-state-highlight:hover {
  background-color: #607d8b;
  color: #fff;
}
.AnyTime-dom-table .ui-state-highlight,
.AnyTime-body-yr-selector .ui-state-highlight,
.AnyTime-dom-table .ui-state-highlight:hover,
.AnyTime-body-yr-selector .ui-state-highlight:hover {
  background-color: #2196f3;
  color: #fff;
}
.AnyTime-yrs-past-btn,
.AnyTime-yrs-ahead-btn {
  display: inline-block;
}
.AnyTime-yr-prior-btn,
.AnyTime-yr-cur-btn,
.AnyTime-yr-next-btn {
  display: inline-block;
  min-width: 50px;
}
.AnyTime-mons:after {
  content: "";
  display: table;
  clear: both;
}
.AnyTime-dom-table {
  width: 100%;
}
.AnyTime-pkr th.AnyTime-dow {
  color: #999999;
  font-size: 12px;
  padding: 7px;
  font-weight: 400;
}
.AnyTime-mon-btn {
  float: left;
}
.AnyTime-mon7-btn {
  clear: left;
}
.AnyTime-dom-btn:hover {
  background-color: #f5f5f5;
}
.AnyTime-dom-btn-empty {
  display: none;
}
.AnyTime-time,
.AnyTime-hrs-am,
.AnyTime-hrs-pm,
.AnyTime-mins-tens,
.AnyTime-mins-ones,
.AnyTime-secs-tens,
.AnyTime-secs-ones,
.AnyTime-hrs,
.AnyTime-mins,
.AnyTime-secs,
.AnyTime-offs {
  display: inline-block;
}
.AnyTime-btn {
  padding: 7px 12px;
  cursor: pointer;
  border-radius: 2px;
}
.AnyTime-btn:hover {
  background-color: #f5f5f5;
}
.AnyTime-hr-btn,
.AnyTime-min-ten-btn,
.AnyTime-min-one-btn,
.AnyTime-sec-ten-btn,
.AnyTime-sec-one-btn {
  min-width: 40px;
}
.AnyTime-hrs-pm,
.AnyTime-mins-ones,
.AnyTime-secs-ones {
  margin-left: 2px;
}
.AnyTime-mins-tens,
.AnyTime-secs-tens {
  margin-left: 20px;
}
.AnyTime-off-cur-btn {
  display: inline-block;
  overflow: hidden;
}
.AnyTime-off-select-btn {
  display: inline-block;
  vertical-align: top;
}
.AnyTime-off-selector {
  margin: 10px;
  position: absolute;
}
.AnyTime-body-off-selector {
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
}
.AnyTime-off-off-btn {
  text-align: left;
}
.AnyTime-yr-selector {
  position: absolute;
  width: 90%;
}
.AnyTime-body-yr-selector {
  margin: 0;
}
.AnyTime-yr-mil,
.AnyTime-yr-cent,
.AnyTime-yr-dec,
.AnyTime-yr-yr,
.AnyTime-yr-era {
  display: inline-block;
  vertical-align: top;
  width: 20%;
}
.AnyTime-era-btn {
  margin-left: 10px;
}
@media (max-width: 769px) {
  .AnyTime-pkr .AnyTime-date {
    float: none;
  }
  .AnyTime-mon-btn {
    float: none;
    display: inline-block;
  }
  .AnyTime-date + .AnyTime-time {
    margin-left: 0;
    margin-top: 25px;
    float: none;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Spectrum color picker
*
*  Flexible and powerful jQuery colorpicker library
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.sp-sat,
.sp-val,
.sp-top-inner,
.sp-color,
.sp-hue,
.sp-clear-enabled .sp-clear,
.sp-preview-inner,
.sp-alpha-inner,
.sp-thumb-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.sp-container.sp-input-disabled .sp-input-container,
.sp-container.sp-buttons-disabled .sp-button-container,
.sp-container.sp-palette-buttons-disabled .sp-palette-button-container,
.sp-palette-only .sp-picker-container,
.sp-palette-disabled .sp-palette-container,
.sp-initial-disabled .sp-initial {
  display: none;
}
.sp-hidden {
  display: none!important;
}
.sp-cf:before,
.sp-cf:after {
  content: "";
  display: table;
}
.sp-cf:after {
  clear: both;
}
.sp-preview,
.sp-alpha,
.sp-thumb-el {
  position: relative;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
}
.sp-preview-inner,
.sp-alpha-inner,
.sp-thumb-inner {
  display: block;
}
.sp-container {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  z-index: 9999994;
  background-color: #fafafa;
  border: 1px solid #dddddd;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.sp-container.sp-flat {
  position: relative;
  overflow-x: auto;
  max-width: 100%;
  white-space: nowrap;
}
.sp-picker-container,
.sp-palette-container {
  display: block;
  white-space: nowrap;
  vertical-align: top;
  position: relative;
  padding: 10px;
  padding-bottom: 300px;
  margin-bottom: -290px;
}
@media (min-width: 769px) {
  .sp-picker-container,
  .sp-palette-container {
    display: inline-block;
  }
}
.sp-picker-container {
  width: 220px;
}
.sp-container,
.sp-replacer,
.sp-preview,
.sp-dragger,
.sp-slider,
.sp-alpha,
.sp-clear,
.sp-alpha-handle,
.sp-container.sp-dragging .sp-input,
.sp-container button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sp-top {
  position: relative;
  width: 100%;
  display: block;
}
.sp-color {
  right: 20%;
}
.sp-hue {
  left: 85%;
  height: 100%;
}
.sp-clear-enabled .sp-hue {
  top: 40px;
  height: 75%;
}
.sp-fill {
  padding-top: 80%;
}
.sp-alpha-enabled .sp-top {
  margin-bottom: 26px;
}
.sp-alpha-enabled .sp-alpha {
  display: block;
}
.sp-alpha-handle {
  position: absolute;
  top: -4px;
  bottom: -4px;
  width: 5px;
  left: 50%;
  cursor: pointer;
  border: 1px solid #999999;
  background-color: #fff;
  border-radius: 5px;
}
.sp-alpha {
  display: none;
  position: absolute;
  bottom: -16px;
  right: 0;
  left: 0;
  height: 6px;
}
.sp-clear {
  display: none;
}
.sp-clear-display {
  cursor: pointer;
}
.sp-clear-display:after {
  content: '\ee6e';
  display: block;
  font-family: 'icomoon';
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 30px;
}
.sp-preview .sp-clear-display:after,
.sp-initial .sp-clear-display:after {
  content: none;
}
.sp-clear-enabled .sp-clear {
  display: block;
  left: 85%;
  height: 30px;
  text-align: center;
  color: #999;
  -webkit-box-shadow: 0 0 0 1px #dddddd inset;
  box-shadow: 0 0 0 1px #dddddd inset;
}
.sp-input-container {
  margin-top: 10px;
}
.sp-initial-disabled .sp-input-container {
  width: 100%;
}
.sp-input {
  border: 1px solid #dddddd;
  padding: 7px 12px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 3px;
  outline: 0;
  color: #333333;
}
.sp-initial {
  margin-top: 10px;
}
.sp-initial span {
  width: 50%;
  height: 25px;
  display: block;
  float: left;
}
.sp-initial span .sp-thumb-inner {
  height: 25px;
  width: 100%;
  display: block;
}
.sp-dragger {
  height: 5px;
  width: 5px;
  border: 1px solid #fff;
  background-color: #333;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.sp-slider {
  position: absolute;
  top: 0;
  cursor: pointer;
  height: 4px;
  left: -2px;
  right: -2px;
  border: 1px solid #999999;
  background-color: #fff;
  border-radius: 3px;
}
.sp-replacer {
  overflow: hidden;
  cursor: pointer;
  padding: 5px;
  display: inline-block;
  border: 1px solid #dddddd;
  background-color: #fafafa;
  vertical-align: middle;
  border-radius: 3px;
}
.sp-replacer:hover,
.sp-replacer.sp-active {
  border-color: #c4c4c4;
  color: #333333;
}
.sp-replacer:hover .sp-dd,
.sp-replacer.sp-active .sp-dd {
  color: #333333;
}
.sp-replacer[class*=bg-],
.sp-replacer[class*=bg-]:hover,
.sp-replacer[class*=bg-]:focus {
  border-color: transparent;
}
.sp-replacer[class*=bg-] .sp-dd {
  color: #fff;
}
.sp-replacer[class*=bg-].sp-active .sp-dd {
  color: #fff;
}
.sp-replacer.sp-disabled {
  cursor: default;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.sp-replacer.sp-disabled:not([class*=bg-]):hover,
.sp-replacer.sp-disabled:not([class*=bg-]):focus {
  border-color: #dddddd;
}
.sp-replacer.sp-disabled .sp-dd,
.sp-replacer.sp-disabled[class*=bg-] .sp-dd {
  color: #999999;
}
.sp-preview {
  position: relative;
  width: 26px;
  height: 24px;
  margin-right: 5px;
  float: left;
  z-index: 0;
}
.sp-preview .sp-preview-inner,
.sp-preview .sp-clear-display {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.sp-replacer[class*=bg-] .sp-preview .sp-preview-inner {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.sp-dd {
  float: left;
  font-size: 0;
  position: relative;
  margin: 3px 1px;
  color: #999999;
}
.sp-dd:after {
  content: '\e9c5';
  display: block;
  font-family: 'icomoon';
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sp-sat {
  background-image: -webkit-gradient(linear, 0 0, 100% 0, from(#ffffff), to(rgba(204, 154, 129, 0)));
  background-image: -webkit-linear-gradient(left, #ffffff, rgba(204, 154, 129, 0));
  background-image: -moz-linear-gradient(left, #ffffff, rgba(204, 154, 129, 0));
  background-image: -o-linear-gradient(left, #ffffff, rgba(204, 154, 129, 0));
  background-image: -ms-linear-gradient(left, #ffffff, rgba(204, 154, 129, 0));
  background-image: linear-gradient(to right, #ffffff, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType = 1, startColorstr=#FFFFFFFF, endColorstr=#00CC9A81)";
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr='#FFFFFFFF', endColorstr='#00CC9A81');
  -webkit-box-shadow: 0 0 0 1px #cccccc inset;
  box-shadow: 0 0 0 1px #cccccc inset;
}
.sp-val {
  background-image: -webkit-gradient(linear, 0 100%, 0 0, from(#000000), to(rgba(204, 154, 129, 0)));
  background-image: -webkit-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
  background-image: -moz-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
  background-image: -o-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
  background-image: -ms-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
  background-image: linear-gradient(to top, #000000, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00CC9A81, endColorstr=#FF000000)";
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00CC9A81', endColorstr='#FF000000');
}
.sp-hue {
  background: -moz-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -ms-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -o-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#ff0000), color-stop(0.17, #ffff00), color-stop(0.33, #00ff00), color-stop(0.5, #00ffff), color-stop(0.67, #0000ff), color-stop(0.83, #ff00ff), to(#ff0000));
  background: -webkit-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: linear-gradient(to bottom, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
}
.sp-1 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#ffff00');
}
.sp-2 {
  height: 16%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff00', endColorstr='#00ff00');
}
.sp-3 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff00', endColorstr='#00ffff');
}
.sp-4 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffff', endColorstr='#0000ff');
}
.sp-5 {
  height: 16%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0000ff', endColorstr='#ff00ff');
}
.sp-6 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00ff', endColorstr='#ff0000');
}
.sp-palette {
  max-width: 220px;
}
.sp-palette-only .sp-palette-container {
  border: 0;
}
@media (min-width: 769px) {
  .sp-palette-container {
    border-right: 1px solid #dddddd;
  }
}
.sp-thumb-el {
  position: relative;
}
.sp-palette .sp-thumb-el {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.sp-thumb-el .sp-thumb-inner {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}
.sp-thumb-el .sp-thumb-inner:hover {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset;
}
.sp-palette .sp-thumb-el {
  width: 16px;
  height: 16px;
}
.sp-palette .sp-thumb-el + .sp-thumb-el {
  margin-left: 5px;
}
.sp-palette .sp-thumb-el.sp-thumb-active {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1) inset;
}
.sp-palette .sp-thumb-el.sp-thumb-active .sp-thumb-inner {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset;
}
.sp-palette .sp-thumb-active.sp-thumb-dark .sp-thumb-inner:after,
.sp-palette .sp-thumb-active.sp-thumb-light .sp-thumb-inner:after {
  content: '\e600';
  display: block;
  font-family: 'icomoon';
  font-size: 16px;
  line-height: 1;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sp-palette .sp-thumb-active.sp-thumb-light .sp-thumb-inner:after {
  color: #333333;
}
.sp-palette-row {
  font-size: 0;
}
.sp-palette-row + .sp-palette-row {
  margin-top: 5px;
}
.sp-palette-row:empty {
  margin-top: 0;
}
.sp-cancel,
.sp-choose,
.sp-palette-toggle {
  border: 0;
  border-radius: 2px;
  padding: 6px 11px;
  float: left;
  width: 48%;
  text-align: center;
  outline: 0;
}
.sp-palette-button-container,
.sp-button-container {
  margin-top: 10px;
  text-align: center;
}
.sp-container[class*=bg-] .sp-palette-button-container a,
.sp-container[class*=bg-] .sp-button-container a,
.sp-container[class*=bg-] .sp-palette-button-container button,
.sp-container[class*=bg-] .sp-button-container button {
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
  border-color: transparent;
}
.sp-cancel,
.sp-palette-toggle,
.sp-choose {
  border: 1px solid transparent;
}
.sp-cancel:hover,
.sp-palette-toggle:hover,
.sp-choose:hover,
.sp-cancel:focus,
.sp-palette-toggle:focus,
.sp-choose:focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
}
.sp-cancel:active,
.sp-palette-toggle:active,
.sp-choose:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.04) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.04) inset;
}
.sp-cancel,
.sp-palette-toggle {
  color: #333333;
  margin-right: 5px;
  background-color: #fcfcfc;
  margin-right: 4%;
  border-color: #dddddd;
}
.sp-cancel:hover,
.sp-palette-toggle:hover,
.sp-cancel:focus,
.sp-palette-toggle:focus {
  color: #333333;
}
.sp-palette-toggle {
  margin-right: 0;
  width: auto;
  float: none;
}
.sp-choose {
  color: #fff;
  background-color: #607d8b;
}
/* ------------------------------------------------------------------------------
 *
 *  # Datepicker for Bootstrap
 *
 *  Styles for bootstrap datepicker. Comes as a part of datepaginator.js plugin
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.datepicker {
  direction: ltr;
}
.datepicker.datepicker-rtl {
  direction: rtl;
}
.datepicker.datepicker-rtl table tr td span {
  float: right;
}
.datepicker > div {
  display: none;
}
.datepicker .datepicker-days,
.datepicker .datepicker-months,
.datepicker .datepicker-years {
  max-width: 300px;
}
.datepicker.datepicker-dropdown {
  top: 0;
  left: 0;
  margin: 0;
  padding: 5px;
}
.datepicker table {
  margin: 0;
}
.datepicker table tr td.day:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #999999;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background-color: transparent;
  color: #999999;
  cursor: default;
}
.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled:hover {
  background-color: #f5f5f5;
}
.datepicker table tr td.range,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled:hover {
  background-color: #f5f5f5;
  border-radius: 0;
}
.datepicker table tr td.range.today,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.active:hover {
  background-color: #f5f5f5;
}
.datepicker table tr td.active,
.datepicker table tr td span.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td span.active.disabled,
.datepicker table tr td.active:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover {
  background-color: #26a69a;
  color: #fff;
}
.datepicker table tr td span {
  display: block;
  width: 31%;
  padding: 7px 12px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 3px;
}
.datepicker table tr td span:hover {
  background-color: #f5f5f5;
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background-color: transparent;
  color: #999999;
  cursor: default;
}
.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #999999;
}
.datepicker table th.datepicker-switch {
  font-size: 15px;
  font-weight: 400;
}
.datepicker table thead tr:first-child th,
.datepicker table tfoot tr th {
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
}
.datepicker table .cw {
  width: 16px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}
.datepicker table thead tr:first-child th.cw {
  cursor: default;
  background-color: transparent;
}
.datepicker .table-condensed td,
.datepicker .table-condensed th {
  text-align: center;
  padding: 7px 12px;
  border-radius: 3px;
  border: 0;
}
.table-striped .datepicker table tr th,
.table-striped .datepicker table tr td {
  background-color: transparent;
}
/* ------------------------------------------------------------------------------
 *
 *  # Date paginator
 *
 *  A jQuery plugin which adds date selection to the Bootstrap pagination component
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.datepaginator {
  height: 72px;
  text-align: center;
}
.datepaginator-sm {
  height: 68px;
}
.datepaginator-lg {
  height: 76px;
}
.datepaginator .pagination {
  white-space: nowrap;
}
.datepaginator .pagination > li {
  display: inline-block;
}
.datepaginator .pagination > li > a {
  min-width: auto;
  text-align: center;
  border-radius: 3px;
  margin-left: 0;
  border: 0;
}
.datepaginator .pagination > li .dp-nav-square-edges {
  border-radius: 0;
}
.datepaginator .pagination > li .dp-off {
  background-color: #fafafa;
}
.datepaginator .pagination > li .dp-no-select {
  color: #ccc;
  background-color: #fafafa;
}
.datepaginator .pagination > li .dp-no-select:hover {
  background-color: #fafafa;
}
.datepaginator .pagination > li #dp-calendar {
  position: absolute;
  right: 6px;
  top: 6px;
}
.datepaginator .pagination > li > .dp-nav {
  height: 72px;
  padding: 29px 0;
  width: 38px;
  line-height: 1;
  border-radius: 3px;
}
.datepaginator .pagination > li > a.dp-nav-sm {
  height: 68px;
  padding: 27px 0;
}
.datepaginator .pagination > li > a.dp-nav-lg {
  height: 76px;
  padding: 31px 0;
}
.datepaginator .pagination > li > a.dp-item {
  height: 72px;
  padding: 15px 0;
  width: 35px;
}
.datepaginator .pagination > li > a.dp-item-sm {
  height: 68px;
  padding: 13px 0;
}
.datepaginator .pagination > li > a.dp-item-lg {
  height: 76px;
  padding: 17px 0;
}
.datepaginator .pagination > li > .dp-today,
.datepaginator .pagination > li > .dp-today:hover,
.datepaginator .pagination > li > .dp-today:focus {
  background-color: #2196f3;
  color: #fff;
}
.datepaginator .pagination > li > .dp-selected,
.datepaginator .pagination > li > .dp-selected:hover,
.datepaginator .pagination > li > .dp-selected:focus {
  background-color: #26a69a;
  color: #fff;
  width: 140px;
}
/* ------------------------------------------------------------------------------
 *
 *  # PNotify notifications
 *
 *  Styles for pnotify.min.js - a flexible JavaScript notification plugin
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.ui-pnotify {
  top: 20px;
  right: 20px;
  position: fixed;
  height: auto;
  z-index: 9999;
  border-radius: 3px;
}
.ui-pnotify.alert-rounded > .alert {
  border-radius: 5px;
}
.ui-pnotify[class*=bg-] > .alert {
  background-color: inherit;
  border-color: transparent;
  color: #fff;
}
.ui-pnotify.stack-top-left,
.ui-pnotify.stack-bottom-left {
  left: 20px;
  right: auto;
}
.ui-pnotify.stack-bottom-right,
.ui-pnotify.stack-bottom-left {
  bottom: 20px;
  top: auto;
}
.ui-pnotify.stack-custom-right {
  top: auto;
  left: auto;
  bottom: 200px;
  right: 200px;
}
.ui-pnotify.stack-custom-left {
  top: 200px;
  left: 200px;
  right: auto;
  bottom: auto;
}
.ui-pnotify.stack-custom-top {
  right: 0;
  left: 0;
  top: 0;
}
.ui-pnotify.stack-custom-bottom {
  right: 0;
  left: 0;
  bottom: 0;
  top: auto;
}
.ui-pnotify-container {
  height: 100%;
  margin: 0;
  overflow: visible!important;
}
h4.ui-pnotify-title {
  display: block;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 15px;
}
.ui-pnotify-icon {
  display: block;
  float: left;
  margin-right: 10px;
}
.ui-pnotify-closer,
.ui-pnotify-sticker {
  float: right;
  margin-left: 5px;
  font-size: 10px;
  margin-top: 4px;
  line-height: 1;
}
.ui-pnotify-closer > span,
.ui-pnotify-sticker > span {
  font-size: 12px;
}
.ui-pnotify-sharp {
  border-radius: 0;
}
.ui-pnotify-shadow {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.ui-pnotify-history-container {
  position: absolute;
  top: 0;
  right: 18px;
  width: 70px;
  border-top: 0;
  padding: 0;
  z-index: 10000;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.ui-pnotify-history-container.ui-pnotify-history-fixed {
  position: fixed;
}
.ui-pnotify-history-container .ui-pnotify-history-header {
  padding: 2px;
  text-align: center;
}
.ui-pnotify-history-container button {
  cursor: pointer;
  display: block;
  width: 100%;
}
.ui-pnotify-history-container .ui-pnotify-history-pulldown {
  display: block;
  margin: 0 auto;
}
/* ------------------------------------------------------------------------------
 *
 *  # jGrowl notifications
 *
 *  Styles for jgrowl.min.js - an unobtrusive notification system for web applications
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.jGrowl {
  z-index: 2001;
  position: absolute;
}
body > .jGrowl {
  position: fixed;
}
.jGrowl.top-left {
  left: 20px;
  top: 20px;
}
.jGrowl.top-center {
  left: 50%;
  top: 20px;
  margin-left: -150px;
}
.jGrowl.top-right {
  right: 20px;
  top: 20px;
}
.jGrowl.center {
  top: 40%;
  width: 300px;
  left: 50%;
  margin-left: -150px;
  margin-top: -35px;
}
.jGrowl.center .jGrowl-notification,
.jGrowl.center .jGrowl-closer {
  margin-left: auto;
  margin-right: auto;
}
.jGrowl.bottom-left {
  left: 20px;
  bottom: 20px;
}
.jGrowl.bottom-center {
  left: 50%;
  bottom: 20px;
  margin-left: -150px;
}
.jGrowl.bottom-right {
  right: 20px;
  bottom: 20px;
}
@media print {
  .jGrowl {
    display: none;
  }
}
.jGrowl-notification {
  margin-bottom: 10px;
  width: 300px;
  text-align: left;
  display: none;
}
.jGrowl-notification .jGrowl-header {
  font-size: 15px;
  margin-bottom: 5px;
}
.jGrowl-notification .jGrowl-header:empty {
  margin: 0;
}
.jGrowl-notification .jGrowl-close {
  font-weight: 300;
  background: none;
  border: 0;
  font-size: 17px;
  cursor: pointer;
  line-height: 1;
  padding: 0;
  float: right;
  color: inherit;
  outline: 0;
  margin-left: 10px;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.jGrowl-notification .jGrowl-close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.jGrowl-closer {
  padding: 5px 0;
  cursor: pointer;
  margin-top: 5px;
  text-align: center;
  background-color: #fff;
  width: 300px;
  border-radius: 3px;
  border: 1px solid #ddd;
  color: #999999;
}
.alert-rounded + .jGrowl-closer {
  border-radius: 5px;
}
/* ------------------------------------------------------------------------------
 *
 *  # Sweet alerts
 *
 *  Styles for sweet_alert.min.js - A beautiful replacement for JavaScript's "Alert"
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
/* # Core
-------------------------------------------------- */
.sweet-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 1050;
}
.sweet-alert {
  background-color: #fff;
  width: 470px;
  padding: 20px;
  border-radius: 3px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -235px;
  margin-top: -200px;
  overflow: hidden;
  display: none;
  z-index: 1060;
}
.sweet-alert h2 {
  text-align: center;
  display: block;
  position: relative;
}
.sweet-alert p {
  text-align: center;
  position: relative;
}
.sweet-alert button {
  background-color: #2196f3;
  color: #fff;
  border: 0;
  border-radius: 3px;
  padding: 7px 12px;
  margin: 10px 5px 0 5px;
  box-shadow: none!important;
}
.sweet-alert button:hover {
  background-color: #1e88e5;
}
.sweet-alert button:focus {
  outline: 0;
}
.sweet-alert button:active {
  background-color: #42a5f5;
}
.sweet-alert button.cancel {
  background-color: transparent;
  color: #333333;
}
.sweet-alert .icon {
  width: 80px;
  height: 80px;
  border: 4px solid #ddd;
  border-radius: 50%;
  margin: 10px auto 20px auto;
  padding: 0;
  position: relative;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.sweet-alert .icon.error {
  border-color: #f44336;
}
.sweet-alert .icon.error .x-mark {
  position: relative;
  display: block;
}
.sweet-alert .icon.error .line {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: #f44336;
  display: block;
  top: 37px;
  border-radius: 2px;
}
.sweet-alert .icon.error .line.left {
  left: 17px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .icon.error .line.right {
  right: 16px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .icon.success {
  border-color: #4caf50;
}
.sweet-alert .icon.success:before,
.sweet-alert .icon.success:after {
  content: '';
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .icon.success:before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  -moz-transform-origin: 60px 60px;
  -ms-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}
.sweet-alert .icon.success:after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 60px;
  -moz-transform-origin: 0 60px;
  -ms-transform-origin: 0 60px;
  transform-origin: 0 60px;
}
.sweet-alert .icon.success .placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(76, 175, 80, 0.2);
  border-radius: 50%;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.sweet-alert .icon.success .fix {
  width: 5px;
  height: 90px;
  background-color: #fff;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .icon.success .line {
  height: 5px;
  background-color: #4caf50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}
.sweet-alert .icon.success .line.tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .icon.success .line.long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .icon.warning {
  border-color: #ff5722;
}
.sweet-alert .icon.warning .body {
  position: absolute;
  width: 5px;
  height: 47px;
  left: 50%;
  top: 10px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #ff5722;
}
.sweet-alert .icon.warning .dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: 10px;
  background-color: #ff5722;
}
.sweet-alert .icon.info {
  border-color: #2196f3;
}
.sweet-alert .icon.info:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 29px;
  left: 50%;
  bottom: 17px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #2196f3;
}
.sweet-alert .icon.info:after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  top: 19px;
  background-color: #2196f3;
}
.sweet-alert .icon.custom {
  background-size: contain;
  border-radius: 0;
  border: 0;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 480px) {
  .sweet-alert {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    left: 20px;
    right: 20px;
  }
}
/* # Animations
-------------------------------------------------- */
@-webkit-keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.showSweetAlert {
  -webkit-animation: showSweetAlert 0.3s;
  -moz-animation: showSweetAlert 0.3s;
  animation: showSweetAlert 0.3s;
}
@-webkit-keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@-moz-keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
}
.hideSweetAlert {
  -webkit-animation: hideSweetAlert 0.2s;
  -moz-animation: hideSweetAlert 0.2s;
  animation: hideSweetAlert 0.2s;
}
@-webkit-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@-moz-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
.animateSuccessTip {
  -webkit-animation: animateSuccessTip 0.75s;
  -moz-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s;
}
@-webkit-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@-moz-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
.animateSuccessLong {
  -webkit-animation: animateSuccessLong 0.75s;
  -moz-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s;
}
@-webkit-keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@-moz-keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
.icon.success.animate::after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  -moz-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}
@-webkit-keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
    -ms-transform: rotateX(100deg);
    -o-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-moz-keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
    -ms-transform: rotateX(100deg);
    -o-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
    -ms-transform: rotateX(100deg);
    -o-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
.animateErrorIcon {
  -webkit-animation: animateErrorIcon 0.5s;
  -moz-animation: animateErrorIcon 0.5s;
  animation: animateErrorIcon 0.5s;
}
@-webkit-keyframes animateXMark {
  0% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  50% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  80% {
    margin-top: -6px;
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    -o-transform: scale(1.15);
    transform: scale(1.15);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-moz-keyframes animateXMark {
  0% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  50% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  80% {
    margin-top: -6px;
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    -o-transform: scale(1.15);
    transform: scale(1.15);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@keyframes animateXMark {
  0% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  50% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  80% {
    margin-top: -6px;
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    -o-transform: scale(1.15);
    transform: scale(1.15);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
.animateXMark {
  -webkit-animation: animateXMark 0.5s;
  -moz-animation: animateXMark 0.5s;
  animation: animateXMark 0.5s;
}
/* ------------------------------------------------------------------------------
*
*  # jQuery UI slider pips
*
*  Styles for jQuery UI slider pips extension
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
/* # Pips
-------------------------------------------------- */
.ui-slider-pips.ui-slider-horizontal {
  margin-bottom: 32px;
  margin-left: 10px;
  margin-right: 10px;
}
.ui-slider-pips .ui-slider-label,
.ui-slider-pips .ui-slider-pip-hide {
  display: none;
}
.ui-slider-pips .ui-slider-pip-label .ui-slider-label {
  display: block;
}
.ui-slider-pips .ui-slider-pip {
  height: 10px;
  line-height: 10px;
  font-size: 85%;
  width: 20px;
  margin-left: -11px;
  position: absolute;
  overflow: visible;
  text-align: center;
  top: 20px;
  left: 20px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ui-slider-pips .ui-slider-pip:hover .ui-slider-label {
  font-weight: 500;
}
.ui-slider-pips .ui-slider-line {
  background-color: #999;
  width: 1px;
  height: 3px;
  position: absolute;
  left: 50%;
}
.ui-slider-pips .ui-slider-label {
  position: absolute;
  top: 8px;
  left: 50%;
  width: 20px;
  margin-left: -10px;
}
.ui-slider-pips.ui-slider-vertical {
  margin-right: 25px;
}
.ui-slider-pips.ui-slider-vertical .ui-slider-pip {
  text-align: left;
  top: auto;
  left: 20px;
  margin-left: 0;
  margin-bottom: -5px;
}
.ui-slider-pips.ui-slider-vertical .ui-slider-line {
  width: 3px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
}
.ui-slider-pips.ui-slider-vertical .ui-slider-label {
  top: 50%;
  margin-left: 0;
  margin-top: -5px;
  width: 20px;
  left: 5px;
}
/* # Tooltips
-------------------------------------------------- */
.ui-slider-float .ui-slider-handle:focus,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip-label {
  outline: 0;
}
.ui-slider-float .ui-slider-tip,
.ui-slider-float .ui-slider-tip-label {
  position: absolute;
  visibility: hidden;
  top: -45px;
  display: block;
  width: 34px;
  margin-left: -16px;
  left: 50%;
  height: 30px;
  line-height: 32px;
  background: #333333;
  border-radius: 3px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all ease-in-out 0.2s 0.2s;
  -o-transition: all ease-in-out 0.2s 0.2s;
  transition: all ease-in-out 0.2s 0.2s;
}
.ui-slider-float .ui-slider-handle:hover .ui-slider-tip,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip {
  top: -40px;
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.ui-slider-float .ui-slider-pip .ui-slider-tip-label {
  top: 42px;
}
.ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
  top: 32px;
  font-weight: normal;
}
.ui-slider-float .ui-slider-tip:after,
.ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
  content: '';
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #333333;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
}
.ui-slider-float.ui-slider-vertical .ui-slider-tip,
.ui-slider-float.ui-slider-vertical .ui-slider-tip-label {
  top: 50%;
  margin-top: -16px;
  width: 34px;
  margin-left: 0px;
  left: -50px;
  -webkit-transition: all ease-in-out 0.2s 0.2s;
  -o-transition: all ease-in-out 0.2s 0.2s;
  transition: all ease-in-out 0.2s 0.2s;
}
.ui-slider-float.ui-slider-vertical .ui-slider-handle:hover .ui-slider-tip,
.ui-slider-float.ui-slider-vertical .ui-slider-handle:focus .ui-slider-tip,
.ui-slider-float.ui-slider-vertical .ui-slider-pip:hover .ui-slider-tip-label,
.ui-slider-float.ui-slider-vertical .ui-slider-pip:focus .ui-slider-tip-label {
  top: 50%;
  margin-top: -16px;
  left: -45px;
}
.ui-slider-float.ui-slider-vertical .ui-slider-pip .ui-slider-tip-label {
  left: 47px;
}
.ui-slider-float.ui-slider-vertical .ui-slider-pip:hover .ui-slider-tip-label {
  left: 37px;
}
.ui-slider-float.ui-slider-vertical .ui-slider-tip:after,
.ui-slider-float.ui-slider-vertical .ui-slider-pip .ui-slider-tip-label:after {
  border-left-color: #333333;
  bottom: 9px;
  left: auto;
  margin-right: -9px;
  border-top-color: transparent;
  right: 0;
  margin-left: 0;
}
/* ------------------------------------------------------------------------------
*
*  # NoUI slider
*
*  Styles for NoUI range slider plugin
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.noUi-target {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.noUi-target,
.noUi-target * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 100px;
}
.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s;
  -o-transition: left 0.3s;
  transition: left 0.3s;
  -webkit-transition: top 0.3s;
  -o-transition: top 0.3s;
  transition: top 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
.noUi-background {
  background-color: #eeeeee;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.noUi-connect {
  background-color: #999999;
  margin-right: 1px;
  -webkit-transition: background 450ms;
  -o-transition: background 450ms;
  transition: background 450ms;
}
[disabled] .noUi-connect,
[disabled].noUi-connect {
  background-color: #bbb;
}
[disabled] .noUi-handle {
  cursor: not-allowed;
}
.noUi-horizontal {
  height: 6px;
}
.noUi-horizontal .noUi-handle {
  top: -6px;
  left: -9px;
}
.noUi-horizontal.noUi-extended {
  padding: 0 15px;
}
.noUi-horizontal.noUi-extended .noUi-origin {
  right: -15px;
}
.noUi-vertical {
  display: inline-block;
  width: 6px;
  height: 120px;
}
.noUi-vertical + .noUi-vertical {
  margin-left: 20px;
}
.noUi-vertical .noUi-handle {
  top: -9px;
  left: -6px;
}
.noUi-vertical.noUi-extended {
  padding: 15px 0;
}
.noUi-vertical.noUi-extended .noUi-origin {
  bottom: -15px;
}
.noUi-dragable {
  cursor: w-resize;
}
.noUi-vertical .noUi-dragable {
  cursor: n-resize;
}
.noUi-handle {
  width: 18px;
  height: 18px;
  background-color: #fcfcfc;
  cursor: pointer;
  top: -6px;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  border: 1px solid #bbb;
}
.noUi-stacking .noUi-handle {
  z-index: 10;
}
.noUi-handle:after {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -3px;
  background-color: #666666;
  border-radius: 50%;
}
.noUi-handle:hover,
.noUi-handle:focus {
  background-color: #f5f5f5;
}
.noUi-handle:active {
  border-color: #ddd;
}
.noUi-handle:active:after {
  background-color: #999;
}
.noui-slider-white .noUi-handle:after {
  content: none;
}
.noui-slider-lg .noUi-handle {
  width: 24px;
  height: 24px;
  top: -8px;
}
.noui-slider-lg .noUi-handle:after {
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: -4px;
}
.noui-slider-lg.noUi-horizontal {
  height: 8px;
}
.noui-slider-lg.noUi-horizontal .noUi-handle {
  left: -12px;
}
.noui-slider-lg.noUi-vertical {
  width: 8px;
}
.noui-slider-lg.noUi-vertical .noUi-handle {
  top: -12px;
  left: -8px;
}
.noui-slider-sm .noUi-handle,
.noui-slider-xs .noUi-handle {
  width: 12px;
  height: 12px;
  top: -4px;
}
.noui-slider-sm .noUi-handle:after,
.noui-slider-xs .noUi-handle:after {
  width: 4px;
  height: 4px;
  margin-top: -2px;
  margin-left: -2px;
}
.noui-slider-sm.noUi-horizontal {
  height: 4px;
}
.noui-slider-sm.noUi-horizontal .noUi-handle {
  top: -4px;
  left: -6px;
}
.noui-slider-sm.noUi-vertical {
  width: 4px;
}
.noui-slider-sm.noUi-vertical .noUi-handle {
  top: -6px;
  left: -4px;
}
.noui-slider-xs.noUi-horizontal {
  height: 2px;
}
.noui-slider-xs.noUi-horizontal .noUi-handle {
  top: -5px;
  left: -6px;
}
.noui-slider-xs.noUi-vertical {
  width: 2px;
}
.noui-slider-xs.noUi-vertical .noUi-handle {
  top: -6px;
  left: -5px;
}
.noui-slider-solid .noUi-handle {
  background-color: #999999;
  border-color: #999999;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.noui-slider-solid .noUi-handle:after {
  background-color: #fff;
  -webkit-transition: opacity ease-in-out 0.2s;
  -o-transition: opacity ease-in-out 0.2s;
  transition: opacity ease-in-out 0.2s;
}
.noui-slider-solid .noUi-handle:hover:after,
.noui-slider-solid .noUi-handle:focus:after {
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.noui-slider-solid.noui-slider-primary .noUi-handle {
  background-color: #2196f3;
  border-color: #2196f3;
}
.noui-slider-solid.noui-slider-danger .noUi-handle {
  background-color: #f44336;
  border-color: #f44336;
}
.noui-slider-solid.noui-slider-success .noUi-handle {
  background-color: #4caf50;
  border-color: #4caf50;
}
.noui-slider-solid.noui-slider-warning .noUi-handle {
  background-color: #ff5722;
  border-color: #ff5722;
}
.noui-slider-solid.noui-slider-info .noUi-handle {
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.noui-slider-primary .noUi-connect,
.noui-slider-primary.noUi-connect {
  background-color: #2196f3;
}
.noui-slider-danger .noUi-connect,
.noui-slider-danger.noUi-connect {
  background-color: #f44336;
}
.noui-slider-success .noUi-connect,
.noui-slider-success.noUi-connect {
  background-color: #4caf50;
}
.noui-slider-warning .noUi-connect,
.noui-slider-warning.noUi-connect {
  background-color: #ff5722;
}
.noui-slider-info .noUi-connect,
.noui-slider-info.noUi-connect {
  background-color: #00bcd4;
}
.noui-tooltip {
  display: none;
  position: absolute;
  border-radius: 3px;
  background: #333333;
  color: #fff;
  top: -43px;
  padding: 7px 12px;
  left: 50%;
  margin-left: -26px;
  text-align: center;
  width: 52px;
}
.noui-tooltip:after {
  content: '';
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #333333;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
}
.noUi-handle:hover .noui-tooltip {
  display: block;
}
/* ------------------------------------------------------------------------------
 *
 *  # jQuery UI Datepicker
 *
 *  Separate styles for jQuery UI library. Datepicker component
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
/* # Core
-------------------------------------------------- */
.ui-datepicker {
  min-width: 270px;
  background-color: #ffffff;
  padding: 5px;
  display: none;
  border: 1px solid #dddddd;
  border-radius: 3px;
  z-index: 190!important;
}
.sidebar .ui-datepicker {
  min-width: 0;
}
.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: 15px 0 20px 0;
}
.ui-datepicker .ui-datepicker-title {
  margin: 0 40px;
  font-size: 15px;
  text-align: center;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
  font-weight: 500;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-year {
  font-size: 12px;
  color: #999999;
}
.ui-datepicker .ui-datepicker-title select {
  margin: 0 1%;
}
.ui-datepicker .ui-datepicker-title select.ui-datepicker-month,
.ui-datepicker .ui-datepicker-title select.ui-datepicker-year {
  width: 48%;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 10px;
  line-height: 1;
  color: #333333;
  padding: 8px;
  border-radius: 2px;
}
.ui-datepicker .ui-datepicker-prev:after,
.ui-datepicker .ui-datepicker-next:after {
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  width: 16px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: none;
}
.ui-datepicker .ui-datepicker-prev {
  left: 0;
}
.ui-datepicker .ui-datepicker-prev:after {
  content: '\e9c8';
}
.ui-datepicker .ui-datepicker-next {
  right: 0;
}
.ui-datepicker .ui-datepicker-next:after {
  content: '\e9cb';
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  background-color: #f5f5f5;
}
.ui-datepicker table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
}
.ui-datepicker table th {
  text-align: center;
  font-weight: 400;
  border: 0;
  padding-bottom: 10px;
  font-size: 12px;
  color: #999999;
}
.ui-datepicker table td {
  border: 0;
  padding: 1px;
  position: relative;
}
.ui-datepicker table td span,
.ui-datepicker table td a {
  display: block;
  padding: 6px;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  color: #333333;
  min-width: 34px;
}
.ui-datepicker table td .ui-state-hover {
  background-color: #f5f5f5;
}
.ui-datepicker table td.ui-state-disabled span {
  color: #ccc;
}
.ui-datepicker table td.ui-datepicker-week-col {
  padding: 6px;
  color: #999999;
}
.ui-datepicker table td.ui-datepicker-current-day .ui-state-active {
  background-color: #26a69a;
  color: #fff;
}
.ui-datepicker table td.ui-datepicker-today .ui-state-highlight {
  background-color: #2196f3;
  color: #fff;
}
.ui-datepicker table td.ui-datepicker-today .ui-state-highlight:after {
  content: "";
  position: absolute;
  top: 3px;
  right: 3px;
  width: 0;
  height: 0;
  border-top: 6px solid #fff;
  border-left: 6px solid transparent;
}
.ui-datepicker table td a.ui-priority-secondary {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  border: 1px solid #dddddd;
  background-color: #fcfcfc;
  font-weight: 400;
  margin-top: 5px;
  cursor: pointer;
  padding: 7px 12px;
  width: auto;
  overflow: visible;
  outline: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-state-hover {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}
.ui-datepicker .ui-datepicker-buttonpane button:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.04) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.04) inset;
}
.ui-datepicker.ui-datepicker-multi {
  width: auto!important;
}
/* # Optional settings
-------------------------------------------------- */
.datepicker-inline {
  width: 264px;
  max-width: 100%;
  overflow-x: auto;
}
.full-width.datepicker-inline,
.sidebar .datepicker-inline {
  width: 100%;
}
.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 8px;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header {
  border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 46%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}
.ui-datepicker-trigger {
  position: absolute;
  top: 4px;
  right: 5px;
  z-index: 2;
  padding: 6px;
  cursor: pointer;
}
.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}
/* # RTL datepicker
-------------------------------------------------- */
.ui-datepicker-rtl {
  direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
  right: 0;
  left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
  left: 0;
  right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: right;
}
.ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}
/* ------------------------------------------------------------------------------
 *
 *  # jQuery UI Sliders
 *
 *  Separate styles for jQuery UI library. Slider component
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.ui-slider {
  position: relative;
  text-align: left;
  background-color: #eeeeee;
  border-radius: 100px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 18px;
  height: 18px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fcfcfc;
  border: 1px solid #bbb;
}
.ui-slider .ui-slider-handle.ui-state-hover,
.ui-slider .ui-slider-handle.ui-state-focus {
  outline: 0;
  background-color: #f5f5f5;
}
.ui-slider .ui-slider-handle.ui-state-active {
  border-color: #ddd;
}
.ui-slider .ui-slider-handle.ui-state-active:after {
  background-color: #999;
}
.ui-slider .ui-slider-handle:after {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -3px;
  background-color: #666666;
  border-radius: 50%;
}
.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  display: block;
  border: 0;
  background-color: #999999;
  border-radius: 100px;
}
.ui-slider-horizontal {
  height: 6px;
}
.ui-slider-horizontal .ui-slider-handle {
  top: -6px;
  margin-left: -9px;
}
.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}
.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}
.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}
.ui-slider-vertical {
  width: 6px;
  height: 140px;
  display: inline-block;
}
.ui-slider-vertical .ui-slider-handle {
  left: -6px;
  margin-bottom: -9px;
}
.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}
.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}
.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}
.ui-slider-vertical-default > span,
.ui-slider-vertical-range-min > span,
.ui-slider-vertical-range-max > span {
  margin: 0 10px;
}
.ui-slider-vertical-pips > span,
.ui-slider-vertical-labels > span,
.ui-slider-vertical-minmax > span {
  margin: 5px 15px;
}
.ui-slider-solid .ui-slider-handle {
  background-color: #999999;
  border-color: #999999;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui-slider-solid .ui-slider-handle:after {
  background-color: #fff;
  -webkit-transition: opacity ease-in-out 0.2s;
  -o-transition: opacity ease-in-out 0.2s;
  transition: opacity ease-in-out 0.2s;
}
.ui-slider-solid .ui-slider-handle.ui-state-hover:after,
.ui-slider-solid .ui-slider-handle.ui-state-active:after {
  background-color: #fff;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.ui-handle-mark.ui-handle-white .ui-slider-handle:after {
  content: none;
}
.ui-slider-lg .ui-slider-handle {
  width: 24px;
  height: 24px;
}
.ui-slider-lg .ui-slider-handle:after {
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: -4px;
}
.ui-slider-lg.ui-slider-horizontal {
  height: 8px;
}
.ui-slider-lg.ui-slider-horizontal .ui-slider-handle {
  top: -8px;
  margin-left: -12px;
}
.ui-slider-lg.ui-slider-vertical {
  width: 8px;
}
.ui-slider-lg.ui-slider-vertical .ui-slider-handle {
  left: -8px;
  margin-bottom: -12px;
}
.ui-slider-sm .ui-slider-handle,
.ui-slider-xs .ui-slider-handle {
  width: 12px;
  height: 12px;
}
.ui-slider-sm .ui-slider-handle:after,
.ui-slider-xs .ui-slider-handle:after {
  width: 4px;
  height: 4px;
  margin-top: -2px;
  margin-left: -2px;
}
.ui-slider-sm.ui-slider-horizontal {
  height: 4px;
}
.ui-slider-sm.ui-slider-horizontal .ui-slider-handle {
  top: -4px;
  margin-left: -6px;
}
.ui-slider-sm.ui-slider-vertical {
  width: 4px;
}
.ui-slider-sm.ui-slider-vertical .ui-slider-handle {
  left: -4px;
  margin-bottom: -6px;
}
.ui-slider-xs.ui-slider-horizontal {
  height: 2px;
}
.ui-slider-xs.ui-slider-horizontal .ui-slider-handle {
  top: -5px;
  margin-left: -6px;
}
.ui-slider-xs.ui-slider-vertical {
  width: 2px;
}
.ui-slider-xs.ui-slider-vertical .ui-slider-handle {
  left: -5px;
  margin-bottom: -6px;
}
.ui-slider-primary .ui-slider-range,
.ui-slider-solid.ui-slider-primary .ui-slider-handle {
  background-color: #2196f3;
  border-color: #2196f3;
}
.ui-slider-danger .ui-slider-range,
.ui-slider-solid.ui-slider-danger .ui-slider-handle {
  background-color: #f44336;
  border-color: #f44336;
}
.ui-slider-success .ui-slider-range,
.ui-slider-solid.ui-slider-success .ui-slider-handle {
  background-color: #4caf50;
  border-color: #4caf50;
}
.ui-slider-warning .ui-slider-range,
.ui-slider-solid.ui-slider-warning .ui-slider-handle {
  background-color: #ff5722;
  border-color: #ff5722;
}
.ui-slider-info .ui-slider-range,
.ui-slider-solid.ui-slider-info .ui-slider-handle {
  background-color: #00bcd4;
  border-color: #00bcd4;
}
/* ------------------------------------------------------------------------------
 *
 *  # jQuery UI Sortable
 *
 *  Separate styles for jQuery UI library. Sortable component
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.sortable-placeholder {
  position: relative;
}
.sortable-placeholder:before {
  content: '';
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  border: 1px dashed rgba(0, 0, 0, 0.2);
}
.sidebar .sortable-placeholder:before {
  border-left: 0;
  border-right: 0;
  background-color: rgba(0, 0, 0, 0.05);
}
.sidebar-category.ui-sortable-helper {
  background-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0.4);
}
.sidebar-default .sidebar-category.ui-sortable-helper {
  background-color: #fcfcfc;
}
.panel + .sortable-placeholder {
  margin-bottom: 20px;
}
.sortable-placeholder + .panel {
  margin-top: 20px;
}
.panel-group > .sortable-placeholder:before {
  border-radius: 3px;
}
.panel-group .panel + .sortable-placeholder {
  margin-top: 5px;
  margin-bottom: 5px;
}
.panel-group .sortable-placeholder + .panel {
  margin-top: 5px;
}
.table .ui-sortable-helper {
  width: 100%;
  background-color: #fff;
  display: table;
}
.table .sortable-placeholder {
  margin: 0;
}
.table .sortable-placeholder:before {
  content: none;
}
.table.ui-sortable {
  position: relative;
}
/* ------------------------------------------------------------------------------
 *
 *  # jQuery UI Autocomplete
 *
 *  Styles for jQuery UI Autocomplete component
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.ui-autocomplete {
  position: absolute;
  display: none;
  padding: 0;
  z-index: 1000;
  border: 1px solid #dddddd;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
/* ------------------------------------------------------------------------------
 *
 *  # jQuery UI Menu
 *
 *  Styles for jQuery UI Menu component
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
/* # Core
-------------------------------------------------- */
.ui-menu {
  list-style: none;
  padding: 5px 0;
  margin: 0;
  display: block;
  outline: none;
  background-color: #ffffff;
  border-radius: 3px;
}
.ui-menu .ui-menu {
  position: absolute;
}
.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  color: #333333;
  background-color: #f5f5f5;
}
.ui-menu .ui-menu-item {
  position: relative;
  margin: 0;
  padding: 8px 15px;
  cursor: pointer;
  min-height: 0;
  list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
}
.ui-menu .ui-menu-divider {
  margin: 5px 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0;
}
/* ------------------------------------------------------------------------------
 *
 *  # Prism
 *
 *  Lightweight, extensible syntax highlighter
 *
 *  Version: 1.0
 *  Latest update: May 10, 2015
 *
 * ---------------------------------------------------------------------------- */
code[class*="language-"],
pre[class*="language-"] {
  text-shadow: 0 1px #fff;
  word-break: normal;
  line-height: 1.5;
}
@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata,
.token.punctuation {
  color: #999;
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol {
  color: #905;
}
.token.selector,
.token.attr-name,
.token.string,
.token.builtin {
  color: #690;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  color: #a67f59;
  background: rgba(255, 255, 255, 0.5);
}
.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}
.token.function {
  color: #DD4A68;
}
.token.regex,
.token.important {
  color: #e90;
}
.token.important {
  font-weight: bold;
}
.token.entity {
  cursor: help;
}
.namespace {
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.line-highlight {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 1em;
  background: rgba(133, 238, 149, 0.12);
  pointer-events: none;
  white-space: pre;
  line-height: 1.5384616;
}
.line-highlight:before,
.line-highlight:after {
  content: attr(data-start);
  position: absolute;
  top: 0px;
  left: 10px;
  padding: 2px;
  text-align: center;
  font-size: 10px;
  color: #999999;
}
.line-highlight[data-end]:after {
  content: attr(data-end);
  top: auto;
  bottom: 0;
}
pre.line-numbers {
  position: relative;
  padding-left: 50px;
  counter-reset: linenumber;
}
pre.line-numbers > code {
  position: relative;
}
.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -50px;
  width: 36px;
  letter-spacing: -1px;
  border-right: 1px solid #999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.line-numbers .line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber;
}
.line-numbers .line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #999999;
  display: block;
  padding-right: 10px;
  text-align: right;
}
pre[class*="language-"] {
  overflow: auto;
  position: relative;
  border-left-width: 3px;
  border-left-color: #2196f3;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
pre[class*="language-"] > code[data-language] {
  display: block;
}
pre[class*="language-"] > code[data-language]:before {
  content: attr(data-language);
  background-color: #EEE;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 11px;
  border-radius: 0 0 0 2px;
  padding: 4px 8px;
  text-shadow: none;
  color: #333333;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
pre[class*="language-"].line-numbers > code[data-language]:before {
  top: -15px;
  right: -15px;
}
pre[data-line] {
  position: relative;
  padding: 12px 0 12px 36px;
}
/* ------------------------------------------------------------------------------
*
*  # Fancytree
*
*  Tree plugin for jQuery with support for persistence, keyboard, checkboxes,
*  tables (grid), drag'n'drop, and lazy loading
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
/* # Core
-------------------------------------------------- */
.fancytree-container {
  list-style: none;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  overflow: auto;
  position: relative;
}
.fancytree-container:focus {
  outline: 0;
}
.fancytree-container ul {
  list-style: none;
  padding: 0 0 0 20px;
  margin: 0;
}
.fancytree-container ul li {
  margin-top: 1px;
}
.ui-fancytree-disabled .fancytree-container {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.ui-fancytree-disabled .fancytree-container,
.ui-fancytree-disabled .fancytree-title,
.ui-fancytree-disabled .fancytree-expander {
  cursor: not-allowed;
}
.ui-fancytree-disabled .fancytree-treefocus .fancytree-selected .fancytree-title {
  background-color: #f5f5f5;
  color: #333333;
}
.ui-helper-hidden {
  display: none;
}
.fancytree-expander:after,
.fancytree-icon:after,
.fancytree-checkbox:after,
.fancytree-drag-helper-img:after,
.fancytree-drop-before:after,
.fancytree-drop-after:after,
.fancytree-loading .fancytree-expander:after,
.fancytree-statusnode-wait .fancytree-icon:after {
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fancytree-icon,
.fancytree-checkbox,
.fancytree-custom-icon {
  margin-top: 5px;
  float: left;
}
.fancytree-checkbox {
  margin: 4px 10px 0 2px;
}
.fancytree-custom-icon {
  display: inline-block;
}
img.fancytree-icon {
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-top: 3px;
  vertical-align: top;
  border-style: none;
}
.fancytree-expander {
  cursor: pointer;
  float: left;
  margin-top: 5px;
  width: 16px;
  line-height: 1;
}
.fancytree-exp-c .fancytree-expander:after {
  content: '\e9c7';
}
.fancytree-exp-cl .fancytree-expander:after {
  content: '\e9c7';
}
.fancytree-exp-cd .fancytree-expander:after,
.fancytree-exp-cdl .fancytree-expander:after {
  content: '\e9c7';
}
.fancytree-exp-e .fancytree-expander:after,
.fancytree-exp-ed .fancytree-expander:after {
  content: '\e9c5';
}
.fancytree-exp-el .fancytree-expander:after,
.fancytree-exp-edl .fancytree-expander:after {
  content: '\e9c5';
}
.fancytree-statusnode-error .fancytree-icon:after {
  content: '\ed63';
}
.fancytree-loading .fancytree-expander,
.fancytree-statusnode-wait .fancytree-icon {
  margin-left: auto;
  margin-right: auto;
  width: 16px;
  height: 16px;
  display: inline-block;
  text-align: center;
}
.fancytree-loading .fancytree-expander:after,
.fancytree-statusnode-wait .fancytree-icon:after {
  content: '\ed6c';
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.fancytree-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
}
.fancytree-ico-c .fancytree-icon:after,
.fancytree-ico-e .fancytree-icon:after {
  content: '\ea1a';
}
.fancytree-has-children.fancytree-ico-c .fancytree-icon:after {
  content: '\ea0f';
}
.fancytree-has-children.fancytree-ico-e .fancytree-icon:after {
  content: '\ea11';
}
.fancytree-ico-cf .fancytree-icon:after,
.fancytree-ico-ef .fancytree-icon:after {
  content: '\ea3d';
}
.fancytree-has-children.fancytree-ico-cf .fancytree-icon:after {
  content: '\ea41';
}
.fancytree-has-children.fancytree-ico-ef .fancytree-icon:after {
  content: '\ea43';
}
.fancytree-checkbox {
  width: 18px;
  height: 18px;
  border: 2px solid #777777;
  display: block;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.fancytree-checkbox:after,
.fancytree-partsel.fancytree-selected .fancytree-checkbox:after {
  content: "\e600";
  margin-top: -1px;
  margin-left: -1px;
  display: none;
}
.fancytree-partsel > .fancytree-checkbox:after {
  content: "";
  width: 8px;
  height: 8px;
  display: none;
  background-color: #888888;
  margin: 3px;
}
.fancytree-partsel.fancytree-selected .fancytree-checkbox:after {
  background-color: transparent;
  width: auto;
  height: auto;
}
.fancytree-selected .fancytree-checkbox:after,
.fancytree-partsel .fancytree-checkbox:after,
.fancytree-partsel.fancytree-selected .fancytree-checkbox:after {
  display: block;
}
.fancytree-radio .fancytree-checkbox {
  border-radius: 100%;
}
.fancytree-radio .fancytree-checkbox:after {
  content: '';
  width: 8px;
  height: 8px;
  background-color: #888888;
  margin: 3px;
  border-radius: 100px;
}
/* # Other styles
-------------------------------------------------- */
.fancytree-drag-helper {
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 4px 5px;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.fancytree-drag-helper-img:after {
  position: absolute;
  top: 50%;
  margin-top: -9px;
  right: -26px;
}
.fancytree-drop-accept .fancytree-drag-helper-img:after {
  content: '\ee73';
  color: #4caf50;
}
.fancytree-drop-reject .fancytree-drag-helper-img:after {
  content: '\ed63';
  color: #f44336;
}
#fancytree-drop-marker.fancytree-drop-before,
#fancytree-drop-marker.fancytree-drop-after {
  width: 200px;
  border-top: 1px solid #333333;
  position: absolute!important;
}
.fancytree-drag-source {
  background-color: #dddddd;
}
.fancytree-drag-source .fancytree.title {
  color: #333333;
}
.fancytree-drop-target.fancytree-drop-accept a {
  background-color: #2196f3 !important;
  color: #fff !important;
  text-decoration: none;
}
.fancytree-node {
  display: inherit;
  width: 100%;
  position: relative;
}
.fancytree-title {
  border: 0;
  padding: 4px 6px;
  border-radius: 2px;
  margin-left: 4px;
  display: inline-block;
  cursor: pointer;
}
.fancytree-title > input {
  border: 0;
  outline: 0;
  padding: 0;
  background-color: transparent;
}
.fancytree-selected .fancytree-title {
  background-color: #dddddd;
  color: #333333;
}
.fancytree-treefocus .fancytree-selected .fancytree-title {
  background-color: #2196f3;
  color: #fff;
}
.fancytree-active .fancytree-title {
  background-color: #dddddd;
}
.sidebar:not(.sidebar-default) .fancytree-selected .fancytree-title,
.sidebar:not(.sidebar-default) .fancytree-active .fancytree-title {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.sidebar:not(.sidebar-default) .fancytree-treefocus .fancytree-selected .fancytree-title {
  background-color: #2196f3;
  color: #fff;
}
.fancytree-ext-table .fancytree-node {
  display: inline-block;
  width: auto;
}
.fancytree-ext-table .fancytree-title {
  display: inline;
}
.fancytree-ext-table.fancytree-container {
  white-space: normal;
}
.fancytree-ext-table .fancytree-expander,
.fancytree-ext-table .fancytree-icon,
.fancytree-ext-table .fancytree-custom-icon {
  margin-top: 1px;
}
.fancytree-ext-table .fancytree-checkbox {
  margin: 0 auto;
  float: none;
}
.fancytree-ext-table .checker {
  display: block;
  margin: 0 auto;
}
.fancytree-ext-table tbody tr td:first-child {
  text-align: center;
}
.fancytree-ext-table tbody tr.fancytree-focused {
  background-color: #f5f5f5;
}
.fancytree-ext-table tbody tr.fancytree-active {
  background-color: #f5f5f5;
}
.fancytree-ext-table tbody tr.fancytree-selected {
  background-color: #2196f3;
  color: #fff;
}
.fancytree-ext-table tbody tr.fancytree-selected .fancytree-checkbox {
  color: #fff;
  border-color: #fff;
}
.fancytree-ext-table tbody tr.fancytree-selected .checker span {
  border-color: #fff;
  color: #fff;
}
/* ------------------------------------------------------------------------------
*
*  # Ladda progress buttons
*
*  Buttons with built-in loading indicators
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.btn-ladda,
.btn-ladda .ladda-spinner,
.btn-ladda .ladda-label {
  -webkit-transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
  -o-transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
}
.btn-ladda .ladda-spinner {
  position: absolute;
  z-index: 2;
  display: inline-block;
  top: 50%;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda .ladda-label {
  z-index: 3;
}
.btn-ladda .ladda-progress {
  position: absolute;
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  visibility: hidden;
  -webkit-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
  transition: all linear 0.3s;
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-loading] .ladda-progress {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=zoom-in],
.btn-ladda[data-style=zoom-out] {
  overflow: hidden;
}
.btn-ladda[data-style=zoom-in],
.btn-ladda[data-style=zoom-out],
.btn-ladda[data-style=zoom-in] .ladda-spinner,
.btn-ladda[data-style=zoom-out] .ladda-spinner,
.btn-ladda[data-style=zoom-in] .ladda-label,
.btn-ladda[data-style=zoom-out] .ladda-label {
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
}
.btn-ladda[data-style=zoom-in] .ladda-label,
.btn-ladda[data-style=zoom-out] .ladda-label {
  position: relative;
  display: inline-block;
}
.btn-ladda[data-style=zoom-in] .ladda-spinner,
.btn-ladda[data-style=zoom-out] .ladda-spinner {
  left: 50%;
}
.btn-ladda[data-style=zoom-in][data-loading] .ladda-label,
.btn-ladda[data-style=zoom-out][data-loading] .ladda-label {
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-style=zoom-in][data-loading] .ladda-spinner,
.btn-ladda[data-style=zoom-out][data-loading] .ladda-spinner {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=zoom-in] .ladda-spinner {
  -webkit-transform: scale(0.2);
  -ms-transform: scale(0.2);
  -o-transform: scale(0.2);
  transform: scale(0.2);
}
.btn-ladda[data-style=zoom-in][data-loading] .ladda-label {
  -webkit-transform: scale(2.2);
  -ms-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
}
.btn-ladda[data-style=zoom-out] .ladda-spinner {
  -webkit-transform: scale(2.5);
  -ms-transform: scale(2.5);
  -o-transform: scale(2.5);
  transform: scale(2.5);
}
.btn-ladda[data-style=zoom-out][data-loading] .ladda-label {
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
}
.btn-ladda[data-style=expand-left] {
  overflow: hidden;
}
.btn-ladda[data-style=expand-left] .ladda-spinner {
  left: 20px;
}
.btn-ladda[data-style=expand-left][data-loading] {
  padding-left: 35px;
}
.btn-ladda[data-style=expand-left][data-loading] .ladda-spinner {
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=expand-right] {
  overflow: hidden;
}
.btn-ladda[data-style=expand-right] .ladda-spinner {
  right: 20px;
}
.btn-ladda[data-style=expand-right][data-loading] {
  padding-right: 35px;
}
.btn-ladda[data-style=expand-right][data-loading] .ladda-spinner {
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=expand-up] {
  overflow: hidden;
}
.btn-ladda[data-style=expand-up] .ladda-spinner {
  top: -16px;
  left: 50%;
}
.btn-ladda[data-style=expand-up][data-loading] {
  padding-top: 40px;
}
.btn-ladda[data-style=expand-up][data-loading] .ladda-spinner {
  top: 12px;
  margin-top: 8px;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=expand-down] {
  overflow: hidden;
}
.btn-ladda[data-style=expand-down] .ladda-spinner {
  top: 44px;
  left: 50%;
}
.btn-ladda[data-style=expand-down][data-loading] {
  padding-bottom: 40px;
}
.btn-ladda[data-style=expand-down][data-loading] .ladda-spinner {
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=slide-left] {
  overflow: hidden;
}
.btn-ladda[data-style=slide-left] .ladda-label {
  left: 0;
  position: relative;
}
.btn-ladda[data-style=slide-left] .ladda-spinner {
  right: 0;
}
.btn-ladda[data-style=slide-left][data-loading] .ladda-label {
  left: -100%;
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-style=slide-left][data-loading] .ladda-spinner {
  right: 50%;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=slide-right] {
  overflow: hidden;
}
.btn-ladda[data-style=slide-right] .ladda-label {
  right: 0;
  position: relative;
}
.btn-ladda[data-style=slide-right] .ladda-spinner {
  left: 0;
}
.btn-ladda[data-style=slide-right][data-loading] .ladda-label {
  right: -100%;
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-style=slide-right][data-loading] .ladda-spinner {
  left: 50%;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=slide-up] {
  overflow: hidden;
}
.btn-ladda[data-style=slide-up] .ladda-label {
  top: 0;
  position: relative;
}
.btn-ladda[data-style=slide-up] .ladda-spinner {
  top: 100%;
  left: 50%;
}
.btn-ladda[data-style=slide-up][data-loading] .ladda-label {
  top: -16px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-style=slide-up][data-loading] .ladda-spinner {
  top: 16px;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=slide-down] {
  overflow: hidden;
}
.btn-ladda[data-style=slide-down] .ladda-label {
  top: 0;
  position: relative;
}
.btn-ladda[data-style=slide-down] .ladda-spinner {
  top: -100%;
  left: 50%;
}
.btn-ladda[data-style=slide-down][data-loading] .ladda-label {
  top: 16px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-style=slide-down][data-loading] .ladda-spinner {
  top: 16px;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=fade] {
  overflow: hidden;
}
.btn-ladda[data-style=fade] .ladda-spinner {
  left: 50%;
}
.btn-ladda[data-style=fade][data-loading] .ladda-label {
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-style=fade][data-loading] .ladda-spinner {
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=radius] {
  overflow: hidden;
  border-radius: 2px;
  -webkit-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}
.btn-ladda[data-style=radius] .ladda-spinner {
  left: 50%;
}
.btn-ladda[data-style=radius][data-loading] {
  border-radius: 5px;
}
.btn-ladda[data-style=radius][data-loading] .ladda-label {
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-style=radius][data-loading] .ladda-spinner {
  opacity: 1;
  filter: alpha(opacity=100);
}
/* ------------------------------------------------------------------------------
 *
 *  # Headroom
 *
 *  Hide BS navbar component on page scroll
 *
 *  Version: 1.0
 *  Latest update: Sep 1, 2015
 *
 * ---------------------------------------------------------------------------- */
.headroom {
  -webkit-transition: top 0.2s ease-in-out, bottom 0.2s ease-in-out;
  -o-transition: top 0.2s ease-in-out, bottom 0.2s ease-in-out;
  transition: top 0.2s ease-in-out, bottom 0.2s ease-in-out;
}
.headroom-top-pinned {
  top: 0;
}
.headroom-top-unpinned {
  top: -46px;
}
.headroom-bottom-pinned {
  bottom: -46px;
}
.headroom-bottom-unpinned {
  bottom: 0;
}
/* ------------------------------------------------------------------------------
 *
 *  # FullCalendar
 *
 *  A JavaScript event calendar. Customizable and open source
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.fc {
  direction: ltr;
  text-align: left;
}
.fc-rtl {
  text-align: right;
}
.fc-view {
  max-width: 100%;
  overflow: auto;
}
.fc-view > table {
  min-width: 769px;
}
.fc-events-container {
  background-color: #fcfcfc;
  border: 1px solid #dddddd;
  padding: 10px;
}
.fc-events-container .fc-event {
  margin: 0;
  padding: 4px 11px;
}
.fc-events-container .fc-event + .fc-event {
  margin-top: 10px;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed hr,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #dddddd;
}
.fc-unthemed thead,
.fc-unthemed thead td,
.fc-unthemed thead th {
  border-bottom-color: #bbbbbb;
}
.fc-unthemed hr {
  background-color: #f5f5f5;
}
.fc-unthemed .fc-popover {
  background-color: #fff;
  border-radius: 3px;
}
.fc-unthemed .fc-popover .fc-header {
  background-color: #f5f5f5;
}
.fc-unthemed .fc-today {
  background-color: #e8f5e9;
}
.fc-highlight {
  background-color: #e5e5e5;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.fc-bgevent {
  background-color: #81c784;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.fc-nonbusiness {
  background-color: #ccc;
}
.fc-icon {
  display: block;
}
.fc-icon:after {
  display: inline-block;
  font-family: "icomoon";
  line-height: 1;
  font-size: 16px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fc-icon-left-single-arrow:after {
  content: "\e9c0";
}
.fc-icon-right-single-arrow:after {
  content: "\e9c3";
}
.fc-icon-left-double-arrow:after {
  content: "\000AB";
}
.fc-icon-right-double-arrow:after {
  content: "\000BB";
}
.fc-icon-x:after {
  content: "\ed6b";
  font-size: 12px;
  display: block;
}
.fc button {
  margin: 0;
  border: 1px solid #dddddd;
  padding: 7px 12px;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
}
.fc button::-moz-focus-inner {
  margin: 0;
  padding: 0;
}
.fc-state-default {
  border: 1px solid;
  background-color: #fcfcfc;
}
.fc-state-default.fc-corner-left {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.fc-state-default.fc-corner-right {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.fc-state-hover {
  background-color: #f8f8f8;
}
.fc-state-down,
.fc-state-active {
  background-color: #f5f5f5;
}
.fc-button.fc-state-down,
.fc-button.fc-state-active {
  border-color: #dddddd;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset;
}
.fc-state-disabled {
  color: #999999;
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.fc-button-group {
  display: inline-block;
}
.fc .fc-button-group > * {
  float: left;
  margin: 0 0 0 -1px;
}
.fc .fc-button-group > :first-child {
  margin-left: 0;
}
.fc-rtl .fc-button-group > * {
  float: right;
  margin: 0 -1px 0 0;
}
.fc-popover {
  position: absolute;
  border: 1px solid #dddddd;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
}
.fc-popover .fc-header {
  padding: 10px;
}
.fc-popover .fc-header .fc-close {
  cursor: pointer;
}
.fc-ltr .fc-popover .fc-header .fc-title {
  float: left;
}
.fc-ltr .fc-popover .fc-header .fc-close {
  float: right;
}
.fc-rtl .fc-popover .fc-header .fc-title {
  float: right;
}
.fc-rtl .fc-popover .fc-header .fc-close {
  float: left;
}
.fc-unthemed .fc-popover .fc-header .fc-close {
  margin-top: 2px;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.fc-unthemed .fc-popover .fc-header .fc-close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.fc hr {
  height: 0;
  margin: 0;
  padding: 0 0 2px;
  border-style: solid;
  border-width: 1px 0;
}
.fc-clear {
  clear: both;
}
.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc-bg {
  bottom: 0;
}
.fc-bg table {
  height: 100%;
}
.fc table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}
.fc th,
.fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top;
}
.fc th {
  padding: 12px 20px;
  font-weight: 500;
  text-align: center;
}
.fc td.fc-today {
  border-style: double;
}
.fc-row {
  border-style: solid;
  border-width: 0;
  position: relative;
}
.fc-row table {
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent;
  border-bottom: 0 hidden transparent;
}
.fc-row:first-child table {
  border-top: 0 hidden transparent;
}
.fc-row .fc-bg {
  z-index: 1;
}
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0;
}
.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%;
}
.fc-row .fc-bgevent-skeleton td,
.fc-row .fc-highlight-skeleton td {
  border-color: transparent;
  border: 0;
}
.fc-row .fc-bgevent-skeleton {
  z-index: 2;
}
.fc-row .fc-highlight-skeleton {
  z-index: 3;
}
.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  background: none;
  border-color: transparent;
  border-bottom: 0;
}
.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
  border-top: 0;
}
.fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
}
.fc-row .fc-helper-skeleton {
  z-index: 5;
}
.fc-scroller {
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 500px;
}
.fc-scroller > * {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.fc-event {
  position: relative;
  display: block;
  border: 1px solid #039be5;
  background-color: #039be5;
  font-weight: normal;
  border-radius: 2px;
}
.fc-event,
.fc-event:hover {
  color: #fff;
  text-decoration: none;
}
.fc-event[href],
.fc-event.fc-draggable {
  cursor: pointer;
}
.fc-not-allowed,
.fc-not-allowed .fc-event {
  cursor: not-allowed;
}
.fc-day-grid-event {
  margin: 0 5px 5px 5px;
  padding: 4px 8px;
}
.fc-day-grid-event > .fc-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fc-day-grid-event .fc-time {
  font-weight: 500;
}
.fc-day-grid-event .fc-resizer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 7px;
}
.fc-ltr .fc-day-grid-event .fc-resizer {
  right: -3px;
  cursor: e-resize;
}
.fc-rtl .fc-day-grid-event .fc-resizer {
  left: -3px;
  cursor: w-resize;
}
@media (min-width: 1200px) {
  .fc-day-grid-event > .fc-content {
    background-color: inherit;
  }
  .fc-day-grid-event .fc-time {
    float: right;
    padding-left: 5px;
    display: inline-block;
    z-index: 2;
    position: relative;
    background-color: inherit;
  }
  .fc-rtl .fc-day-grid-event .fc-time {
    float: left;
    padding-left: 0;
    padding-right: 5px;
  }
  .fc-day-grid-event .fc-title {
    z-index: 1;
    position: relative;
  }
}
a.fc-more {
  margin: 1px 5px;
  background-color: #fafafa;
  display: block;
  padding: 5px;
  text-align: center;
  border-radius: 2px;
  color: #999999;
  font-size: 12px;
}
a.fc-more:hover {
  background-color: #f5f5f5;
  color: #333333;
}
.fc-limited {
  display: none;
}
.fc-day-grid .fc-row {
  z-index: 1;
}
.fc-more-popover {
  z-index: 2;
  width: 220px;
}
.fc-more-popover .fc-event-container {
  padding: 10px 5px;
}
.fc-toolbar {
  text-align: center;
  margin-bottom: 20px;
}
.fc-toolbar h2 {
  margin: 5px 0;
  font-size: 17px;
}
.fc-toolbar .fc-left {
  float: left;
  width: 33%;
}
.fc-rtl .fc-toolbar .fc-left {
  float: right;
}
.fc-toolbar .fc-right {
  float: right;
  width: 33%;
}
.fc-rtl .fc-toolbar .fc-right {
  float: left;
}
.fc-toolbar .fc-right .fc-button-group {
  float: right;
}
.fc-rtl .fc-toolbar .fc-right .fc-button-group {
  float: left;
}
.fc-toolbar .fc-left > .fc-button,
.fc-toolbar .fc-right > .fc-button {
  margin-left: 10px;
}
.fc-rtl .fc-toolbar .fc-left > .fc-button,
.fc-rtl .fc-toolbar .fc-right > .fc-button {
  margin-right: 10px;
  margin-left: 0;
}
.fc-toolbar .fc-center {
  display: inline-block;
  vertical-align: middle;
}
.fc-toolbar > * > * {
  float: left;
}
.fc-rtl .fc-toolbar > * > * {
  float: right;
}
.fc-toolbar > * > :first-child {
  margin-left: 0;
}
.fc-toolbar button {
  position: relative;
}
.fc-toolbar button:focus {
  z-index: 5;
}
.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2;
}
.fc-toolbar .fc-state-down {
  z-index: 3;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4;
}
.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.fc-view,
.fc-view > table {
  position: relative;
  z-index: 1;
}
.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
  padding-top: 5px;
  padding-bottom: 10px;
}
.fc-basic-view tbody .fc-row {
  min-height: 100px;
  min-width: 100px;
}
.fc-row.fc-rigid {
  overflow: hidden;
}
.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number {
  padding: 10px;
}
.fc-basic-view .fc-week-number {
  text-align: center;
}
.fc-basic-view .fc-week-number span {
  display: inline-block;
  min-width: 1.25em;
}
.fc-basic-view .fc-day-number {
  text-align: right;
}
.fc-rtl .fc-basic-view .fc-day-number {
  text-align: left;
}
.fc-basic-view .fc-day-number.fc-other-month {
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2;
}
.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em;
}
.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-top: 5px;
  padding-bottom: 0;
}
.fc-axis {
  text-align: right;
}
.fc-rtl .fc-axis {
  text-align: left;
}
.fc .fc-axis {
  vertical-align: middle;
  padding: 10px;
  white-space: nowrap;
}
.fc-time-grid-container,
.fc-time-grid {
  position: relative;
  z-index: 1;
}
.fc-time-grid {
  min-height: 100%;
}
.fc-time-grid table {
  border: 0 hidden transparent;
}
.fc-time-grid > .fc-bg {
  z-index: 1;
}
.fc-time-grid .fc-slats,
.fc-time-grid > hr {
  position: relative;
  z-index: 2;
}
.fc-time-grid .fc-bgevent-skeleton,
.fc-time-grid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc-time-grid .fc-bgevent-skeleton {
  z-index: 3;
}
.fc-time-grid .fc-highlight-skeleton {
  z-index: 4;
}
.fc-time-grid .fc-content-skeleton {
  z-index: 5;
}
.fc-time-grid .fc-helper-skeleton {
  z-index: 6;
}
.fc-slats td {
  border-bottom: 0;
}
.fc-slats .fc-minor td {
  border-top-style: dotted;
}
.fc-time-grid .fc-highlight-container {
  position: relative;
}
.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
}
.fc-time-grid .fc-event-container,
.fc-time-grid .fc-bgevent-container {
  position: relative;
}
.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;
  margin: 5px;
  padding: 10px;
  min-height: 45px;
  -webkit-box-shadow: 0 0 0 1px #ffffff;
  box-shadow: 0 0 0 1px #ffffff;
}
.fc-time-grid .fc-bgevent {
  left: 0;
  right: 0;
}
.fc-time-grid-event {
  overflow: hidden;
}
.fc-time-grid-event.fc-not-start {
  border-top-width: 0;
  padding-top: 1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.fc-time-grid-event.fc-not-end {
  border-bottom-width: 0;
  padding-bottom: 1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.fc-time-grid-event > .fc-content {
  position: relative;
  z-index: 2;
}
.fc-time-grid-event .fc-time {
  font-weight: 500;
}
.fc-time-grid-event.fc-short .fc-content {
  white-space: nowrap;
}
.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  display: inline-block;
  vertical-align: top;
}
.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start);
}
.fc-time-grid-event.fc-short .fc-time:after {
  content: "\000A0-\000A0";
}
.fc-time-grid-event.fc-short .fc-time span {
  display: none;
}
.fc-time-grid-event.fc-short .fc-title {
  font-size: .85em;
  padding: 0;
}
.fc-time-grid-event .fc-resizer {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 10px;
  font-size: 12px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1px;
}
.fc-time-grid-event .fc-resizer:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.fc-time-grid-event .fc-resizer:after {
  content: "=";
}
@media (max-width: 768px) {
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-left > .fc-button,
  .fc-toolbar .fc-right,
  .fc-toolbar .fc-right > .fc-button,
  .fc-toolbar .fc-left .fc-button-group,
  .fc-toolbar .fc-right .fc-button-group {
    float: none;
    width: auto;
    vertical-align: top;
  }
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-right {
    display: block;
    margin-bottom: 10px;
  }
  .fc-view-container {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  .fc-view-container > .fc-view > table {
    margin-left: -1px;
  }
  .fc th,
  .fc td {
    border-right-width: 0;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Plupload multiple file uploader
 *
 *  Multi runtime single and multiple file uploader
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.plupload_wrapper {
  width: 100%;
  border: 2px dashed #dddddd;
  border-radius: 3px;
}
.panel > div > .plupload_wrapper {
  border: 0;
}
.panel.panel-flat > div > .plupload_wrapper {
  border-top: 1px solid #dddddd;
}
.plupload_header:after,
.plupload_filelist:empty:before,
.plupload_filelist li.plupload_droptext:before,
.plupload_file_action:after,
.plupload_delete a:after,
.plupload_failed a:after,
.plupload_done a:after {
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.plupload_header {
  display: none;
  position: relative;
  background-color: #0097a7;
  margin: -1px -1px 0 -1px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.plupload_header:after {
  content: '\ec78';
  color: #fff;
  font-size: 32px;
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -16px;
}
.plupload_header_content {
  padding: 15px 20px;
  padding-left: 72px;
  color: #fff;
}
.plupload_header_title {
  font-size: 15px;
  font-weight: 500;
}
.plupload_header_text {
  font-size: 11px;
  line-height: 1.82;
}
.plupload_clear,
.plupload_clearer {
  clear: both;
}
.plupload_clearer,
.plupload_progress_bar {
  display: block;
  font-size: 0;
  line-height: 0;
}
.plupload_button {
  display: inline-block;
  color: #fff;
  background-color: #2196f3;
  border: 1px solid transparent;
  padding: 6px 11px;
  border-radius: 2px;
  margin-right: 5px;
}
.plupload_button:hover,
.plupload_button:focus {
  color: #fff;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.plupload_button:active {
  opacity: 1;
  filter: alpha(opacity=100);
}
a.plupload_disabled,
a.plupload_disabled:hover,
a.plupload_disabled:focus {
  color: #999999;
  box-shadow: none;
  border-color: #ddd;
  background: #fcfcfc;
  cursor: default;
  opacity: 1;
  filter: alpha(opacity=100);
}
.plupload_start {
  background-color: #26a69a;
}
.plupload_filelist {
  margin: 0;
  padding: 0;
  list-style: none;
}
.plupload_scroll .plupload_filelist {
  height: 250px;
  background: #fff;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .plupload_scroll .plupload_filelist {
    height: 200px;
  }
}
.plupload_filelist li {
  padding: 12px 20px;
  background-color: #fff;
}
.plupload_filelist li:hover {
  background-color: #f5f5f5;
}
.plupload_filelist li.plupload_droptext:hover {
  background-color: transparent;
}
.plupload_filelist:empty,
.plupload_filelist li.plupload_droptext {
  position: relative;
  height: 250px;
  text-align: center;
  background-color: transparent;
  text-indent: -99999px;
}
.plupload_filelist:empty:before,
.plupload_filelist li.plupload_droptext:before {
  content: '\ea0e';
  font-size: 80px;
  padding-top: 65px;
  position: absolute;
  top: 0;
  width: 80px;
  height: 80px;
  left: 50%;
  margin-left: -40px;
  z-index: 2;
  color: #ddd;
  text-indent: 0;
  font-weight: normal;
}
.plupload_filelist:empty:after,
.plupload_filelist li.plupload_droptext:after {
  content: 'Drag files to upload';
  font-size: 19px;
  position: absolute;
  top: 50%;
  padding-top: 145px;
  left: 50%;
  margin: -100px 0 0 -150px;
  width: 300px;
  height: 200px;
  color: #bbb;
  text-indent: 0;
}
@media (max-width: 768px) {
  .plupload_filelist:empty,
  .plupload_filelist li.plupload_droptext {
    height: 200px;
  }
  .plupload_filelist:empty:before,
  .plupload_filelist li.plupload_droptext:before,
  .plupload_filelist:empty:after,
  .plupload_filelist li.plupload_droptext:after {
    width: 100%;
    height: 200px;
    left: 0;
    margin: 0;
    top: 0;
  }
  .plupload_filelist:empty:before,
  .plupload_filelist li.plupload_droptext:before {
    font-size: 64px;
    padding-top: 40px;
  }
  .plupload_filelist:empty:after,
  .plupload_filelist li.plupload_droptext:after {
    font-size: 17px;
    border-width: 3px 0;
    padding-top: 120px;
  }
}
.plupload_filelist:empty:after {
  content: 'Add files to upload';
}
.plupload_filelist_header {
  padding: 15px 20px;
}
.plupload_filelist_header .plupload_file_name,
.plupload_filelist_header .plupload_file_size,
.plupload_filelist_header .plupload_file_status,
.plupload_filelist_header .plupload_file_action {
  font-weight: 500;
}
.plupload_filelist_footer {
  padding: 15px 20px;
}
.plupload_filelist_footer .plupload_file_size,
.plupload_filelist_footer .plupload_file_status,
.plupload_filelist_footer .plupload_upload_status {
  padding: 7px 0;
  font-weight: 500;
}
@media (max-width: 768px) {
  .plupload_filelist_footer .plupload_file_action,
  .plupload_filelist_footer .plupload_file_status,
  .plupload_filelist_footer .plupload_file_size,
  .plupload_filelist_footer .plupload_progress {
    display: none;
  }
}
.plupload_file_action {
  position: relative;
  float: right;
  margin-left: 30px;
  margin-top: 2px;
  color: #999999;
  width: 16px;
  height: 16px;
}
.plupload_file_action * {
  display: none;
}
.plupload_file_action:after {
  content: '\ec67';
  position: absolute;
  left: 0;
  top: 0;
}
.plupload_filelist_footer .plupload_file_action {
  margin-top: 9px;
}
.plupload_filelist .plupload_file_action:after {
  content: none;
}
.plupload_delete .plupload_file_action > a {
  color: #333333;
  line-height: 1;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.plupload_delete .plupload_file_action > a:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.plupload_uploading {
  background-color: #e8f5e9;
}
.plupload_delete a:after {
  content: '\ed6b';
}
.plupload_failed a {
  color: #f44336;
  cursor: default;
}
.plupload_failed a:after {
  content: '\e9cf';
}
.plupload_done {
  color: #999999;
}
.plupload_done a {
  color: #4db6ac;
  cursor: default;
}
.plupload_done a:after {
  content: '\e9db';
}
.plupload_progress,
.plupload_upload_status {
  display: none;
}
.plupload_progress_container {
  margin-top: 14px;
  background-color: #eeeeee;
}
.plupload_progress_bar {
  width: 0px;
  height: 4px;
  background: #4db6ac;
  border-radius: 5px;
}
@media (min-width: 769px) {
  .plupload_file_name {
    float: left;
    overflow: hidden;
  }
  .plupload_filelist .plupload_file_name {
    width: 205px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .plupload_file_size,
  .plupload_file_status,
  .plupload_progress {
    float: right;
    width: 80px;
  }
  .plupload_file_size,
  .plupload_file_status,
  .plupload_file_action {
    text-align: right;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Bootstrap file input
 *
 *  File input styling for Bootstrap 3.0
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.file-input {
  overflow-x: auto;
  display: inline-block;
  width: 100%;
}
.file-input .btn[disabled],
.file-input .btn .disabled {
  cursor: not-allowed;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  background: none repeat scroll 0 0 transparent;
  cursor: inherit;
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
}
.file-caption > span {
  display: inline-block;
  float: left;
  margin-top: 3px;
  margin-right: 6px;
}
.file-caption [class*=icon-] {
  display: none;
}
.file-caption-name {
  display: inline-block;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 85%;
}
.file-error-message {
  background-color: #f5f5f5;
  color: #999999;
  text-align: center;
  border-radius: 2px;
  padding: 5px;
  font-size: 12px;
}
.file-caption-disabled {
  background-color: #fafafa;
  cursor: not-allowed;
}
.file-preview {
  border-radius: 2px;
  border: 1px solid #dddddd;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}
.file-preview .close {
  font-weight: 400;
  font-size: 17px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #FFF;
  padding: 8px;
  line-height: 0.6;
  border-radius: 3px;
  color: #555555;
  opacity: 1;
  filter: alpha(opacity=100);
}
.file-preview.loading:before {
  content: "";
  display: inline-block;
  background-color: #263238;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -16px 0 0 -16px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 5px rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 5px rgba(255, 255, 255, 0.5);
}
.file-preview.loading:after {
  content: "\eb55";
  font-family: "icomoon";
  display: inline-block;
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
.file-preview-status {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #333333;
  background-color: rgba(250, 250, 250, 0.8);
  border-top: 1px solid #ddd;
  padding: 7px 12px;
  font-size: 12px;
}
.file-preview-status:empty {
  padding: 0;
  background-color: transparent;
  border: 0;
}
.file-preview-frame {
  margin: 5px;
  border-radius: 3px;
  text-align: center;
}
.file-preview-frame:hover {
  border-color: #555;
}
.file-preview-frame > object {
  max-height: 200px;
}
@media (min-width: 769px) {
  .file-preview-frame {
    float: left;
    height: 200px;
    max-height: 200px;
  }
}
.file-preview-image {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
}
@media (min-width: 769px) {
  .file-preview-image {
    height: 200px;
    min-height: 200px;
  }
}
.file-preview-text {
  display: table-cell;
  height: 200px;
  color: #2196f3;
  font-size: 12px;
  vertical-align: middle;
  text-align: center;
}
.file-preview-other {
  display: table-cell;
  height: 200px;
  font-family: Monaco, Consolas, monospace;
  font-size: 12px;
  vertical-align: middle;
  text-align: center;
}
.file-input-new .file-preview,
.file-input-new .close,
.file-input-new .fileinput-remove-button,
.file-input-new .fileinput-upload-button {
  display: none;
}
.wrap-indicator {
  font-weight: 500;
  color: #2196f3;
  cursor: pointer;
}
/* ------------------------------------------------------------------------------
 *
 *  # Dropzone file uploader
 *
 *  Open source library that provides drag’n’drop file uploads with image previews
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.dropzone {
  position: relative;
  border: 3px dashed #eeeeee;
  min-height: 250px;
  border-radius: 2px;
  background-color: #fcfcfc;
  padding: 5px;
}
.dropzone.dz-clickable,
.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message span {
  cursor: pointer;
}
.dropzone.dz-clickable * {
  cursor: default;
}
.dropzone .dz-message {
  opacity: 1;
  filter: alpha(opacity=100);
}
.dropzone.dz-started .dz-message {
  display: none;
}
.dropzone.dz-drag-hover {
  border-color: rgba(0, 0, 0, 0.15);
  background: rgba(0, 0, 0, 0.04);
}
.sidebar .dropzone {
  min-height: 190px;
  text-align: center;
  border-width: 2px;
}
.sidebar .dropzone .dz-default.dz-message:before {
  font-size: 64px;
  width: 64px;
  height: 64px;
  margin-left: -32px;
  top: 50px;
}
.sidebar .dropzone .dz-default.dz-message span {
  font-size: 15px;
}
.sidebar .dropzone .dz-default.dz-message > span {
  margin-top: 130px;
}
@media (min-width: 769px) {
  .dropzone {
    min-height: 320px;
  }
}
.dropzone .dz-default.dz-message {
  position: absolute;
  height: 230px;
  width: 100%;
  margin-top: -115px;
  top: 50%;
  left: 0;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.dropzone .dz-default.dz-message:before {
  content: '\ea0e';
  font-family: 'icomoon';
  font-size: 64px;
  position: absolute;
  top: 48px;
  width: 64px;
  height: 64px;
  display: inline-block;
  left: 50%;
  margin-left: -32px;
  line-height: 1;
  z-index: 2;
  color: #ddd;
  text-indent: 0;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dropzone .dz-default.dz-message span {
  font-size: 15px;
  color: #bbb;
  text-align: center;
  margin-top: 120px;
  display: block;
  font-weight: 500;
  text-shadow: 0 1px 1px #fff;
}
.dropzone .dz-default.dz-message span > span {
  margin-top: 2px;
  display: block;
  color: #ccc;
  font-size: 15px;
}
@media (min-width: 769px) {
  .dropzone .dz-default.dz-message:before {
    font-size: 80px;
    top: 48px;
    width: 80px;
    height: 80px;
    margin-left: -32px;
  }
  .dropzone .dz-default.dz-message span {
    font-size: 19px;
    margin-top: 130px;
  }
}
.dropzone.dz-drag-hover .dz-message {
  opacity: 0.15;
  filter: alpha(opacity=15);
}
.dropzone.dz-started .dz-message {
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
}
.dropzone .dz-preview,
.dropzone-previews .dz-preview {
  background-color: #fff;
  position: relative;
  display: inline-block;
  margin: 10px;
  vertical-align: top;
  border: 1px solid #dddddd;
  padding: 6px;
  border-radius: 2px;
}
.dropzone .dz-preview.dz-file-preview [data-dz-thumbnail],
.dropzone-previews .dz-preview.dz-file-preview [data-dz-thumbnail] {
  display: none;
}
.dropzone .dz-preview .dz-details,
.dropzone-previews .dz-preview .dz-details {
  width: 120px;
  height: 120px;
  position: relative;
  background-color: #f5f5f5;
  padding: 8px;
  margin-bottom: 25px;
}
.dropzone .dz-preview .dz-details .dz-filename,
.dropzone-previews .dz-preview .dz-details .dz-filename {
  overflow: hidden;
  height: 100%;
}
.dropzone .dz-preview .dz-details img,
.dropzone-previews .dz-preview .dz-details img {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
}
.dropzone .dz-preview .dz-details .dz-size,
.dropzone-previews .dz-preview .dz-details .dz-size {
  position: absolute;
  bottom: -26px;
  left: 5px;
}
.dropzone .dz-preview:hover .dz-details img,
.dropzone-previews .dz-preview:hover .dz-details img {
  display: none;
}
.dropzone .dz-preview .dz-error-mark,
.dropzone-previews .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-success-mark,
.dropzone-previews .dz-preview .dz-success-mark {
  display: none;
  position: absolute;
  right: 8px;
  bottom: 10px;
  z-index: 9;
}
.dropzone .dz-preview .dz-error-mark:after,
.dropzone-previews .dz-preview .dz-error-mark:after,
.dropzone .dz-preview .dz-success-mark:after,
.dropzone-previews .dz-preview .dz-success-mark:after {
  font-family: 'icomoon';
  display: block;
  font-size: 12px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dropzone .dz-preview .dz-error-mark span,
.dropzone-previews .dz-preview .dz-error-mark span,
.dropzone .dz-preview .dz-success-mark span,
.dropzone-previews .dz-preview .dz-success-mark span,
.dropzone .dz-preview .dz-error-mark svg,
.dropzone-previews .dz-preview .dz-error-mark svg,
.dropzone .dz-preview .dz-success-mark svg,
.dropzone-previews .dz-preview .dz-success-mark svg {
  display: none;
}
.dropzone .dz-preview .dz-error-mark,
.dropzone-previews .dz-preview .dz-error-mark {
  color: #f44336;
}
.dropzone .dz-preview .dz-error-mark:after,
.dropzone-previews .dz-preview .dz-error-mark:after {
  content: '\ed6a';
}
.dropzone .dz-preview .dz-success-mark,
.dropzone-previews .dz-preview .dz-success-mark {
  color: #4caf50;
}
.dropzone .dz-preview .dz-success-mark:after,
.dropzone-previews .dz-preview .dz-success-mark:after {
  content: '\ed6e';
}
.dropzone .dz-preview.dz-error .dz-error-mark,
.dropzone-previews .dz-preview.dz-error .dz-error-mark,
.dropzone .dz-preview.dz-success .dz-success-mark,
.dropzone-previews .dz-preview.dz-success .dz-success-mark {
  display: block;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dropzone .dz-preview .dz-progress,
.dropzone-previews .dz-preview .dz-progress {
  position: absolute;
  top: 118px;
  left: 8px;
  right: 8px;
  height: 4px;
  background-color: #eeeeee;
  display: none;
  border-radius: 2px;
}
.dropzone .dz-preview .dz-progress .dz-upload,
.dropzone-previews .dz-preview .dz-progress .dz-upload {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #00bcd4;
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
.dropzone .dz-preview.dz-processing .dz-progress,
.dropzone-previews .dz-preview.dz-processing .dz-progress {
  display: block;
}
.dropzone .dz-preview.dz-success .dz-progress,
.dropzone-previews .dz-preview.dz-success .dz-progress {
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}
.dropzone .dz-preview .dz-error-message,
.dropzone-previews .dz-preview .dz-error-message {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(245, 245, 245, 0.8);
  margin: 8px;
  padding: 8px;
  color: #f44336;
  max-width: 100%;
  z-index: 5;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message,
.dropzone-previews .dz-preview.dz-error:hover .dz-error-message {
  display: block;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dropzone .dz-preview.dz-error .dz-progress .dz-upload,
.dropzone-previews .dz-preview.dz-error .dz-progress .dz-upload {
  background: #f44336;
}
.dropzone .dz-preview.dz-image-preview:hover .dz-details img,
.dropzone-previews .dz-preview.dz-image-preview:hover .dz-details img {
  display: block;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dropzone .dz-preview a.dz-remove,
.dropzone-previews .dz-preview a.dz-remove {
  background-color: #fafafa;
  border-radius: 2px;
  border: 1px solid #eeeeee;
  display: block;
  padding: 5px;
  text-align: center;
  color: #999999;
  margin-top: 35px;
  cursor: pointer;
}
.dropzone .dz-preview a.dz-remove:hover,
.dropzone-previews .dz-preview a.dz-remove:hover {
  color: #333333;
  background-color: #f5f5f5;
}
/* ------------------------------------------------------------------------------
 *
 *  # Image cropper
 *
 *  Styles for cropper.min.js - a simple jQuery image cropping plugin
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.image-cropper-container {
  height: 350px;
  width: 100%;
  overflow: hidden;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}
.eg-preview {
  text-align: center;
  font-size: 0;
}
.eg-preview .preview {
  margin: 10px auto 0 auto;
  display: block;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 2px;
}
@media (min-width: 769px) {
  .eg-preview .preview {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
  }
  .eg-preview .preview + .preview {
    margin-left: 15px;
  }
}
.eg-preview .preview-lg {
  width: 150px;
  height: 150px;
}
.eg-preview .preview-md {
  width: 120px;
  height: 120px;
}
.eg-preview .preview-sm {
  width: 90px;
  height: 90px;
}
.eg-preview .preview-xs {
  width: 60px;
  height: 60px;
}
.eg-preview .preview-xxs {
  width: 40px;
  height: 40px;
}
@media (min-width: 769px) {
  .eg-preview {
    white-space: nowrap;
    max-width: 100%;
    overflow: auto;
  }
}
.cropper-container {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cropper-container > img {
  width: 100%;
  height: 100%;
}
.cropper-hidden {
  display: none !important;
}
.cropper-invisible {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: auto !important;
  max-width: none !important;
  height: auto !important;
  max-height: none !important;
  opacity: 0;
  filter: alpha(opacity=0);
}
.cropper-modal,
.cropper-canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cropper-modal {
  background-color: #333333;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.cropper-canvas {
  cursor: crosshair;
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
}
.cropper-dragger {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
}
.cropper-viewer {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline-width: 1px;
  outline-style: solid;
  outline-color: rgba(33, 150, 243, 0.75);
}
.cropper-viewer > img {
  max-width: none !important;
  max-height: none !important;
}
.cropper-dashed {
  position: absolute;
  display: block;
  border: 0 dashed #fff;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.cropper-dashed.dashed-h {
  top: 33.3%;
  left: 0;
  width: 100%;
  height: 33.3%;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.cropper-dashed.dashed-v {
  top: 0;
  left: 33.3%;
  width: 33.3%;
  height: 100%;
  border-right-width: 1px;
  border-left-width: 1px;
}
.cropper-face,
.cropper-line,
.cropper-point {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.cropper-face {
  top: 0;
  left: 0;
  cursor: move;
  background-color: #fff;
}
.cropper-line {
  background-color: #f44336;
}
.cropper-line.line-e {
  top: 0;
  right: -3px;
  width: 5px;
  cursor: e-resize;
}
.cropper-line.line-n {
  top: -3px;
  left: 0;
  height: 5px;
  cursor: n-resize;
}
.cropper-line.line-w {
  top: 0;
  left: -3px;
  width: 5px;
  cursor: w-resize;
}
.cropper-line.line-s {
  bottom: -3px;
  left: 0;
  height: 5px;
  cursor: s-resize;
}
.cropper-point {
  width: 5px;
  height: 5px;
  background-color: #2196f3;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.cropper-point.point-e {
  top: 50%;
  right: -3px;
  margin-top: -3px;
  cursor: e-resize;
}
.cropper-point.point-n {
  top: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: n-resize;
}
.cropper-point.point-w {
  top: 50%;
  left: -3px;
  margin-top: -3px;
  cursor: w-resize;
}
.cropper-point.point-s {
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: s-resize;
}
.cropper-point.point-ne {
  top: -3px;
  right: -3px;
  cursor: ne-resize;
}
.cropper-point.point-nw {
  top: -3px;
  left: -3px;
  cursor: nw-resize;
}
.cropper-point.point-sw {
  bottom: -3px;
  left: -3px;
  cursor: sw-resize;
}
.cropper-point.point-se {
  right: -3px;
  bottom: -3px;
  width: 20px;
  height: 20px;
  cursor: se-resize;
  opacity: 1;
  filter: alpha(opacity=100);
}
.cropper-point.point-se:before {
  content: " ";
  position: absolute;
  right: -50%;
  bottom: -50%;
  display: block;
  width: 200%;
  height: 200%;
  background-color: #2196f3;
  opacity: 0;
  filter: alpha(opacity=0);
}
@media (min-width: 769px) {
  .cropper-point {
    width: 15px;
    height: 15px;
  }
}
@media (min-width: 1025px) {
  .cropper-point {
    width: 10px;
    height: 10px;
  }
}
@media (min-width: 1200px) {
  .cropper-point {
    width: 5px;
    height: 5px;
    opacity: 0.75;
    filter: alpha(opacity=75);
  }
}
/* ------------------------------------------------------------------------------
*
*  # Fancybox lightbox
*
*  Mac-style "lightbox" plugin that floats overtop of web page.
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}
.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}
.fancybox-skin {
  position: relative;
  background-color: #fff;
  color: #333333;
  border-radius: 3px;
}
.fancybox-opened {
  z-index: 8030;
}
.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
}
.fancybox-outer,
.fancybox-inner {
  position: relative;
}
.fancybox-inner {
  overflow: hidden;
}
.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}
.fancybox-image,
.fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}
.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}
#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -16px;
  background-color: #fff;
  cursor: pointer;
  z-index: 8060;
  opacity: 0.8;
  filter: alpha(opacity=80);
  -webkit-animation: rotation 1s ease infinite;
  -o-animation: rotation 1s ease infinite;
  animation: rotation 1s ease infinite;
}
#fancybox-loading div {
  width: 32px;
  height: 32px;
}
#fancybox-loading div:after {
  content: '\eb55';
  font-family: 'icomoon';
  font-size: 16px;
  margin-top: -8px;
  margin-left: -8px;
  display: block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fancybox-close {
  position: absolute;
  right: -32px;
  top: -32px;
  padding: 8px;
  z-index: 8061;
  cursor: pointer;
  line-height: 1;
  color: #fff;
  border-radius: 3px;
}
.fancybox-close:hover,
.fancybox-close:focus {
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
}
.fancybox-close:after {
  content: '\ed6a';
  font-family: 'icomoon';
  font-size: 16px;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fancybox-error {
  color: #f44336;
  font-weight: 500;
  margin: 0;
  padding: 20px;
  white-space: nowrap;
}
.fancybox-title {
  visibility: hidden;
  font-weight: 500;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}
.fancybox-opened .fancybox-title {
  visibility: visible;
}
.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -45px;
  z-index: 8050;
  text-align: center;
}
.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 7px 12px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  white-space: nowrap;
}
.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}
.fancybox-title-inside-wrap {
  padding-top: 10px;
}
.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background-color: transparent;
}
.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  z-index: 8040;
}
.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  cursor: pointer;
  padding: 8px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  text-align: center;
  z-index: 8040;
  visibility: hidden;
  border-radius: 3px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.fancybox-nav span:after {
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fancybox-nav:hover span {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
}
.fancybox-prev {
  left: 0;
}
.fancybox-prev span {
  left: 10px;
}
.fancybox-prev span:after {
  content: '\e9c8';
}
.fancybox-next {
  right: 0;
}
.fancybox-next span {
  right: 10px;
}
.fancybox-next span:after {
  content: '\e9cb';
}
.fancybox-lock {
  overflow: hidden !important;
  width: auto;
}
.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}
.fancybox-lock body {
  overflow: hidden !important;
}
.fancybox-lock-test {
  overflow-y: hidden !important;
}
.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background-color: rgba(0, 0, 0, 0.8);
}
.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}
/* ------------------------------------------------------------------------------
*
*  # Footable
*
*  jQuery plugin that aims to make HTML tables on smaller devices look awesome.
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.footable {
  width: 100%;
}
.footable > thead > tr > th {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.footable.breakpoint > tbody > tr.footable-detail-show > td {
  border-bottom: 0;
}
.footable.breakpoint > tbody > tr.footable-row-detail:hover {
  background-color: transparent;
}
.footable.breakpoint > tbody > tr:hover:not(.footable-row-detail) {
  cursor: pointer;
}
.footable.breakpoint > tbody > tr > .footable-cell-detail {
  background-color: #eeeeee;
  border-top: 0;
}
.footable.breakpoint > tbody > tr .footable-toggle:before {
  content: "\e9e4";
  display: inline-block;
  font-family: 'icomoon';
  font-size: 16px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 10px;
}
.footable.breakpoint > tbody > tr.footable-detail-show .footable-toggle:before {
  content: "\e9e7";
}
.footable-row-detail-inner {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.footable-row-detail-row {
  display: table-row;
}
.footable-row-detail-group {
  display: block;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 7px;
}
.footable-row-detail-group:first-child {
  margin-top: 7px;
}
.footable-row-detail-name {
  display: table-cell;
  padding-right: 40px;
  font-weight: 500;
}
.footable-row-detail-value {
  display: table-cell;
  padding: 7px 0;
}
@media (max-width: 480px) {
  .footable-row-detail-inner {
    position: relative;
  }
  .footable-row-detail .dropdown,
  .footable-row-detail .dropup,
  .footable-row-detail .btn-group {
    position: static;
  }
  .footable-row-detail .dropdown-menu {
    left: 0;
    right: 0;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Datatables library
 *
 *  Add advanced interaction controls to any HTML table
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.dataTable {
  margin: 0;
  max-width: none;
}
.dataTable thead th,
.dataTable thead td {
  outline: 0;
  position: relative;
}
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting {
  cursor: pointer;
}
.dataTable thead .sorting,
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting_asc_disabled,
.dataTable thead .sorting_desc_disabled {
  padding-right: 40px;
}
.dataTable thead .sorting:before,
.dataTable thead .sorting:after,
.dataTable thead .sorting_asc:after,
.dataTable thead .sorting_desc:after,
.dataTable thead .sorting_asc_disabled:after,
.dataTable thead .sorting_desc_disabled:after {
  content: '';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 12px;
  margin-top: -6px;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dataTable thead .sorting:before {
  content: '\edbf';
  margin-top: -2px;
  color: #999999;
}
.dataTable thead .sorting:after {
  content: '\edb7';
  margin-top: -10px;
  color: #999999;
}
.dataTable thead .sorting_asc:after {
  content: '\edb7';
}
.dataTable thead .sorting_desc:after {
  content: '\edbf';
}
.dataTable thead .sorting_asc_disabled:after {
  content: '\edb7';
  color: #ccc;
}
.dataTable thead .sorting_desc_disabled:after {
  content: '\edbf';
  color: #ccc;
}
.dataTable .dataTables_empty {
  text-align: center;
}
.dataTables_wrapper {
  position: relative;
  clear: both;
}
.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}
.dataTables_wrapper .table-bordered {
  border-top: 0;
}
.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  background-color: #fff;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
}
.datatable-header,
.datatable-footer {
  padding-top: 20px;
}
.datatable-header:after,
.datatable-footer:after {
  content: "";
  display: table;
  clear: both;
}
.datatable-header > div:first-child,
.datatable-footer > div:first-child {
  margin-left: 0;
}
.panel > .dataTables_wrapper .datatable-header,
.panel > .dataTables_wrapper .datatable-footer {
  padding-left: 20px;
  padding-right: 20px;
}
.datatable-header {
  border-bottom: 1px solid #dddddd;
}
.datatable-footer {
  border-top: 1px solid #bbbbbb;
}
.dataTables_length {
  float: right;
  display: inline-block;
  margin: 0 0 20px 20px;
}
.dataTables_length > label {
  margin-bottom: 0;
}
.dataTables_length > label > span {
  float: left;
  margin: 8px 15px;
  margin-left: 0;
}
.length-left .dataTables_length {
  float: left;
}
.dataTables_length .select2-container {
  width: auto;
}
.dataTables_length .select2-choice {
  min-width: 60px;
}
.dataTables_filter {
  position: relative;
  display: block;
  float: left;
  margin: 0 0 20px 20px;
}
.dataTables_filter > label {
  margin-bottom: 0;
  position: relative;
}
.dataTables_filter > label:after {
  content: "\e98e";
  font-family: 'icomoon';
  font-size: 12px;
  display: inline-block;
  position: absolute;
  top: 12px;
  right: 12px;
  color: #999999;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dataTables_filter > label > span {
  float: left;
  margin: 8px 15px;
  margin-left: 0;
}
.dataTables_filter input {
  outline: 0;
  width: 200px;
  height: 36px;
  padding: 7px 12px;
  padding-right: 34px;
  font-size: 13px;
  line-height: 1.5384616;
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 3px;
}
.filter-right .dataTables_filter {
  float: right;
}
.dataTables_info {
  float: left;
  padding: 8px 0;
  margin-bottom: 20px;
}
.info-right .dataTables_info {
  float: right;
}
.dataTables_paginate {
  float: right;
  text-align: right;
  margin: 0 0 20px 20px;
}
.dataTables_paginate .paginate_button {
  display: inline-block;
  padding: 7px 12px;
  min-width: 36px;
  margin-left: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #333333;
  border: 1px solid transparent;
  border-radius: 3px;
}
.dataTables_paginate .paginate_button:first-child {
  margin-left: 0;
}
.dataTables_paginate .paginate_button:hover,
.dataTables_paginate .paginate_button:focus {
  background-color: #f5f5f5;
}
.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button.current:hover,
.dataTables_paginate .paginate_button.current:focus {
  color: #fff;
  background-color: #455a64;
}
.dataTables_paginate .paginate_button.disabled,
.dataTables_paginate .paginate_button.disabled:hover,
.dataTables_paginate .paginate_button.disabled:focus {
  cursor: default;
  background-color: transparent;
  color: #bbbbbb;
}
.paginate-left .dataTables_paginate {
  float: left;
}
.paging_simple .paginate_button:hover,
.paging_simple .paginate_button:focus {
  color: #fff;
  background-color: #455a64;
}
.dataTables_scroll {
  clear: both;
}
.dataTables_scroll .dataTables_scrollHead table {
  border-bottom: 0;
}
.dataTables_scroll .dataTables_scrollHead th,
.dataTables_scroll .dataTables_scrollHead td {
  white-space: nowrap;
}
.dataTables_scroll .dataTables_scrollBody {
  -webkit-overflow-scrolling: touch;
}
.dataTables_scroll .dataTables_scrollBody table {
  border-bottom: 0;
}
.dataTables_scroll .dataTables_scrollBody table thead th[class*=sorting]:before,
.dataTables_scroll .dataTables_scrollBody table thead th[class*=sorting]:after {
  content: none;
}
.dataTables_scroll .dataTables_scrollBody table tbody tr:first-child > td {
  border-top: 0;
}
.dataTables_scroll .dataTables_scrollBody th,
.dataTables_scroll .dataTables_scrollBody td {
  white-space: nowrap;
}
.dataTables_scroll .dataTables_scrollBody th > .dataTables_sizing,
.dataTables_scroll .dataTables_scrollBody td > .dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.panel-body + .dataTables_wrapper {
  border-top: 1px solid #dddddd;
}
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_length,
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_filter,
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_info,
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_paginate {
  margin-bottom: 0;
}
.panel-flat > .panel-heading + .dataTables_wrapper {
  border-top: 1px solid #dddddd;
}
.panel > .dataTables_wrapper .table-bordered {
  border: 0;
}
.panel > .dataTables_wrapper .table-bordered > thead > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > thead > tr > th:first-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > th:first-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > th:first-child {
  border-left: 0;
}
.panel > .dataTables_wrapper .table-bordered > thead > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > thead > tr > th:last-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > th:last-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > th:last-child {
  border-right: 0;
}
.panel > .dataTables_wrapper .table-bordered > tbody > tr:last-child > th,
.panel > .dataTables_wrapper .table-bordered > tbody > tr:last-child > td {
  border-bottom: 0;
}
.datatable-scroll-lg,
.datatable-scroll,
.datatable-scroll-sm {
  min-height: .01%;
}
.datatable-scroll-wrap {
  width: 100%;
  overflow-x: scroll;
}
@media (max-width: 768px) {
  .datatable-scroll-sm {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll-sm th,
  .datatable-scroll-sm td {
    white-space: nowrap;
  }
}
@media (max-width: 1024px) {
  .datatable-scroll {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll th,
  .datatable-scroll td {
    white-space: nowrap;
  }
}
@media (max-width: 1199px) {
  .datatable-scroll-lg {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll-lg th,
  .datatable-scroll-lg td {
    white-space: nowrap;
  }
}
@media (max-width: 768px) {
  .dataTables_info,
  .dataTables_paginate,
  .dataTables_length,
  .dataTables_filter,
  .DTTT_container,
  .ColVis {
    float: none!important;
    text-align: center;
    margin-left: 0;
  }
  .dataTables_info,
  .dataTables_paginate {
    margin-top: 0;
  }
  .datatable-header {
    text-align: center;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Columns reorder
*
*  Easily modify the column order of a table through drop-and-drag of column headers
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.DTCR_clonedTable {
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 202;
  cursor: move;
}
.DTCR_clonedTable th,
.DTCR_clonedTable td {
  border: 1px solid #dddddd !important;
}
.DTCR_pointer {
  width: 1px;
  background-color: #2196f3;
  z-index: 201;
}
/* ------------------------------------------------------------------------------
*
*  # Fixed columns
*
*  Extension that "freezes" in place the left most columns in a scrolling DataTable
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.DTFC_Cloned {
  background-color: #fff;
  border-bottom: 0;
}
.DTFC_LeftWrapper .DTFC_Cloned.table {
  border-right: 1px solid #dddddd;
}
.DTFC_RightWrapper .DTFC_Cloned.table {
  border-left: 1px solid #dddddd;
}
.DTFC_LeftBodyWrapper .DTFC_Cloned thead th:before,
.DTFC_RightBodyWrapper .DTFC_Cloned thead th:before,
.DTFC_LeftBodyWrapper .DTFC_Cloned thead th:after,
.DTFC_RightBodyWrapper .DTFC_Cloned thead th:after {
  content: none;
}
.DTFC_LeftBodyWrapper .DTFC_Cloned tbody > tr:first-child > td,
.DTFC_RightBodyWrapper .DTFC_Cloned tbody > tr:first-child > td,
.DTFC_LeftBodyWrapper .DTFC_Cloned tbody > tr:first-child > th,
.DTFC_RightBodyWrapper .DTFC_Cloned tbody > tr:first-child > th {
  border-top: 0;
}
.DTFC_Blocker {
  background-color: white;
}
@media (max-width: 768px) {
  .DTFC_LeftWrapper,
  .DTFC_RightWrapper {
    display: none;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Columns visibility
*
*  Extensions allows the end user to enable or disable table column visibility
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.ColVis {
  float: right;
  margin: 0 0 20px 20px;
}
.colvis-left .ColVis {
  float: left;
}
.ColVis_catcher {
  position: absolute;
  z-index: 1101;
}
.ColVis_Button {
  position: relative;
  outline: 0;
}
.ColVis_collectionBackground {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #333;
  z-index: 1100;
}
.ColVis_collection {
  list-style: none;
  min-width: 180px;
  padding: 5px 0;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  overflow: hidden;
  z-index: 2002;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.ColVis_collection li {
  position: relative;
  padding: 8px 15px;
  display: block;
  cursor: pointer;
}
.ColVis_collection li:hover {
  color: #333333;
  background-color: #f5f5f5;
}
.ColVis_collection li > label {
  padding-left: 28px;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}
.ColVis_collection :not(.ColVis_Special) + .ColVis_Special {
  margin-top: 10px;
}
.ColVis_collection :not(.ColVis_Special) + .ColVis_Special:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  display: inline-block;
  width: 100%;
  background-color: #e5e5e5;
  margin-top: -5px;
}
.ColVis_collection .checker,
.ColVis_collection .choice {
  position: absolute;
  left: 0;
  top: 1px;
}
@media (max-width: 768px) {
  .ColVis_collection {
    width: 100%;
    border-radius: 0;
    left: 0 !important;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Fancybox lightbox
*
*  Add a toolbar to a table to provide options for saving files (CSV, XLS, PDF),
*  copying to system Clipboard, or printing the table
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.DTTT_container {
  position: relative;
  float: right;
  margin: 0 0 20px 20px;
}
.tools-left .DTTT_container {
  float: left;
}
.DTTT_container .DTTT_button embed {
  outline: none;
}
@media (max-width: 768px) {
  .DTTT_container {
    float: none !important;
    text-align: center;
    display: block;
    margin-left: 0;
  }
  .DTTT_container > .btn {
    text-align: center;
    float: none;
  }
}
.DTTT_selectable tbody tr {
  cursor: pointer;
}
.DTTT_print_info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  margin-top: -100px;
  text-align: center;
  color: #fff;
  padding: 20px;
  background-color: #455a64;
  border-radius: 3px;
  z-index: 90;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.DTTT_print_info h6 {
  font-weight: 300;
  font-size: 17px;
}
/* ------------------------------------------------------------------------------
*
*  # Datatables Scroller
*
*  Drawing the rows required for the current display only, for fast operation
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.DTS tbody th,
.DTS tbody td {
  white-space: nowrap;
}
.DTS .DTS_Loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 20px;
  margin-top: -20px;
  margin-left: -100px;
  z-index: 1;
  border: 1px solid #999;
  padding: 20px 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
}
.DTS .dataTables_scrollHead,
.DTS .dataTables_scrollFoot {
  background-color: #fff;
}
.DTS .dataTables_scrollBody {
  z-index: 2;
}
/* ------------------------------------------------------------------------------
*
*  # Responsive extension
*
*  Optimise the table's layout for different screen sizes through the dynamic 
*  insertion and removal of columns from the table
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.dtr-inline.collapsed tbody tr td:before,
.dtr-inline.collapsed tbody tr th:before {
  margin-right: 10px;
}
.dtr-inline.collapsed tbody tr td:first-child,
.dtr-inline.collapsed tbody tr th:first-child {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.dtr-inline.collapsed tbody tr td:first-child.dataTables_empty:before,
.dtr-inline.collapsed tbody tr th:first-child.dataTables_empty:before {
  display: none;
}
.dtr-column tbody td.control,
.dtr-column tbody th.control {
  position: relative;
  cursor: pointer;
}
.dtr-inline.collapsed tbody tr td:first-child:before,
.dtr-inline.collapsed tbody tr th:first-child:before,
.dtr-column tbody tr td.control:before,
.dtr-column tbody tr th.control:before {
  content: '\e9e4';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  width: 16px;
  line-height: 1;
  position: relative;
  top: -1px;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dataTable.dtr-inline.collapsed tbody tr.parent td:first-child:before,
.dataTable.dtr-inline.collapsed tbody tr.parent th:first-child:before,
.dataTable.dtr-column tbody tr.parent td.control:before,
.dataTable.dtr-column tbody tr.parent th.control:before {
  content: '\e9e7';
}
.dtr-inline.collapsed tbody tr.child td:before {
  display: none;
}
.dataTable tr.child:hover {
  background-color: transparent;
}
.dataTable tr.child .dtr-title {
  display: table-cell;
  font-weight: 500;
  padding-right: 40px;
}
.dataTable tr.child .dtr-data {
  display: table-cell;
  padding: 8px 0;
}
.dataTable tr td.child {
  white-space: normal;
  position: relative;
}
.dataTable tr td.child > ul {
  display: table;
  table-layout: fixed;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
.dataTable tr td.child > ul > li {
  display: table-row;
}
@media (max-width: 480px) {
  .dataTable tr td.child > ul > li .dropdown,
  .dataTable tr td.child > ul > li .dropup,
  .dataTable tr td.child > ul > li .btn-group {
    position: static;
  }
  .dataTable tr td.child > ul > li .dropdown-menu {
    left: 0;
    right: 0;
    border-radius: 0;
    border-width: 1px 0;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Google maps base
 *
 *  Styles related to Google Maps: containers, sizing etc.
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.map-wrapper {
  width: 100%;
  height: 400px;
}
.sidebar .map-wrapper {
  height: 200px;
  border: 1px solid #dddddd;
}
/* ------------------------------------------------------------------------------
 *
 *  # Google Maps overrides
 *
 *  Override Google Maps default styles
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.map-container {
  height: 500px;
}
.gm-style {
  font-size: 13px;
}
.gm-style .gm-style-iw {
  font-size: 13px;
  font-weight: 400;
}
/* ------------------------------------------------------------------------------
 *
 *  # Vector maps
 *
 *  Vector map container, tooltip and zooming
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.vector-map-container {
  height: 500px;
}
.jvectormap-label {
  position: absolute;
  display: none;
  border-radius: 2px;
  background-color: #333333;
  color: #ffffff;
  padding: 7px 12px;
}
.jvectormap-zoomin,
.jvectormap-zoomout {
  position: absolute;
  left: 10px;
  border-radius: 2px;
  padding: 8px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  line-height: 10px;
  background-color: #37474f;
  text-align: center;
}
.jvectormap-zoomin:hover,
.jvectormap-zoomout:hover {
  background-color: #455a64;
}
.jvectormap-zoomin {
  top: 10px;
}
.jvectormap-zoomout {
  top: 40px;
}
/* ------------------------------------------------------------------------------
*
*  # Chart styling
*
*  Charts base - container and sizing setup
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.chart-container {
  position: relative;
  width: 100%;
}
.chart-container.has-scroll {
  overflow-x: scroll;
  overflow-y: visible;
  max-width: 100%;
}
@media (max-width: 768px) {
  .chart-container {
    overflow-x: scroll;
    overflow-y: visible;
    max-width: 100%;
  }
}
.chart {
  position: relative;
  display: block;
  width: 100%;
}
.chart.has-minimum-width {
  min-width: 600px;
}
.has-fixed-height {
  height: 400px;
}
.chart-pie {
  width: 100%;
  height: 400px;
  min-width: 500px;
}
/* ------------------------------------------------------------------------------
*
*  # C3 charts
*
*  Styles for C3.js visualization library
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.c3 svg {
  font-size: 12px;
}
.c3 path,
.c3 line {
  fill: none;
  stroke: #999999;
}
.c3 path.domain {
  shape-rendering: crispEdges;
}
.c3 text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}
.c3-chart-arc path {
  stroke: #fff;
}
.c3-chart-arc text {
  fill: #fff;
  font-size: 13px;
}
.c3-grid line {
  stroke: #aaa;
}
.c3-grid text {
  fill: #aaa;
}
.c3-xgrid,
.c3-ygrid {
  stroke-dasharray: 3 3;
}
.c3-text {
  font-weight: 500;
}
.c3-text.c3-empty {
  fill: #808080;
  font-size: 2em;
}
.c3-line {
  stroke-width: 2px;
}
.c3-area {
  stroke-width: 0;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.c3-circle._expanded_ {
  stroke-width: 1.5px;
  stroke: #fff;
}
.c3-selected-circle {
  fill: #fff;
  stroke-width: 2px;
}
.c3-bar {
  stroke-width: 0;
}
.c3-bar._expanded_ {
  fill-opacity: 0.75;
}
.c3-chart-arcs-title {
  font-size: 1.3em;
}
.c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: none;
}
.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #333333;
  font-size: 15px;
}
.c3-chart-arcs .c3-chart-arcs-gauge-max,
.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #999999;
}
.c3-chart-arc .c3-gauge-value {
  fill: #333333;
  font-size: 28px;
}
.c3-target.c3-focused {
  opacity: 1;
  filter: alpha(opacity=100);
}
.c3-target.c3-focused path.c3-line,
.c3-target.c3-focused path.c3-step {
  stroke-width: 2px;
}
.c3-target.c3-defocused {
  opacity: 0.3 !important;
  filter: alpha(opacity=30) !important;
}
.c3-region {
  fill: #546e7a;
  fill-opacity: .1;
}
.c3-brush .extent {
  fill-opacity: .1;
}
.c3-legend-item {
  font-size: 12px;
}
.c3-legend-background {
  fill: #fff;
  stroke: lightgray;
  stroke-width: 1;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  empty-cells: show;
  opacity: 0.95;
  filter: alpha(opacity=95);
}
.c3-tooltip th {
  background-color: #333;
  padding: 4px 10px;
  text-align: center;
  color: #fff;
  border: 1px solid #333;
  font-weight: 500;
}
.c3-tooltip td {
  font-size: 12px;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}
.c3-tooltip td > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}
.c3-tooltip td.value {
  text-align: right;
}
/* ------------------------------------------------------------------------------
*
*  # D3.js library
*
*  Basic styles for D3.js visualization library
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.d3-axis path {
  fill: none;
  stroke: #999999;
  shape-rendering: crispEdges;
}
.d3-axis line {
  stroke: #eeeeee;
  shape-rendering: crispEdges;
}
.d3-axis .tick,
.d3-axis .tick text {
  font-size: 12px;
  fill: #333333;
}
.d3-axis-solid path,
.d3-axis-solid line {
  stroke: #ccc;
}
.d3-axis-solid .tick {
  fill: #999999;
}
.d3-axis-solid .tick text {
  fill: #999999;
}
.d3-axis-strong path,
.d3-axis-strong line {
  stroke: #ccc;
}
.d3-axis-strong .tick {
  fill: #333333;
}
.d3-axis-strong .tick text {
  fill: #333333;
}
.d3-axis-transparent path {
  stroke: none;
}
.d3-axis-transparent line {
  stroke: #e5e5e5;
}
.d3-axis-transparent .tick {
  fill: #999999;
}
.d3-axis-transparent .tick text {
  fill: #999999;
}
.d3-tip {
  position: absolute;
  padding: 8px 15px;
  border-radius: 3px;
  background-color: #333333;
  color: #ffffff;
  margin-bottom: -3px;
  font-size: 12px;
  z-index: 1070;
}
.d3-tip .d3-tip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.d3-tip.n .d3-tip-arrow {
  bottom: -4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #333333;
}
.d3-tip.e .d3-tip-arrow {
  top: 50%;
  left: -4px;
  margin-top: -4px;
  border-width: 4px 4px 4px 0;
  border-right-color: #333333;
}
.d3-tip.w .d3-tip-arrow {
  top: 50%;
  right: -4px;
  margin-top: -4px;
  border-width: 4px 0 4px 4px;
  border-left-color: #333333;
}
.d3-tip.s .d3-tip-arrow {
  top: 0;
  left: 50%;
  margin-left: -4px;
  border-width: 0 4px 4px;
  border-bottom-color: #333333;
}
.venntooltip {
  position: absolute;
  text-align: center;
  min-width: 60px;
  white-space: nowrap;
  background-color: #333333;
  color: #ffffff;
  padding: 7px 12px;
  border-radius: 3px;
  display: none;
}
.d3-line {
  fill: none;
}
.d3-line-thin {
  stroke-width: 1px;
}
.d3-line-medium {
  stroke-width: 1.5px;
}
.d3-line-strong {
  stroke-width: 2px;
}
.d3-line-circle {
  fill: none;
  cursor: pointer;
}
.d3-line-circle-thin {
  stroke-width: 1px;
}
.d3-line-circle-medium {
  stroke-width: 1.5px;
}
.d3-line-circle-strong {
  stroke-width: 2px;
}
.d3-grid .tick line {
  stroke-width: 1px;
  stroke: #e5e5e5;
  shape-rendering: crispEdges;
}
.d3-grid .tick text {
  fill: #333333;
  stroke: none;
}
.d3-grid-dashed .tick {
  stroke-dasharray: 4,2;
  stroke-width: 1px;
  stroke: #e5e5e5;
  shape-rendering: crispEdges;
}
.d3-grid-dashed path {
  stroke-width: 0;
}
.d3-crosshair-overlay {
  fill: none;
  pointer-events: all;
}
.bullet-tick line {
  stroke: #999999;
  stroke-width: 1px;
  shape-rendering: crispEdges;
}
.bullet-tick text {
  fill: #999999;
  font-size: 12px;
}
.bullet-marker {
  stroke-width: 2px;
  shape-rendering: crispEdges;
}
.bullet-title {
  font-size: 13px;
  font-weight: 500;
}
.bullet-subtitle {
  fill: #999999;
}
.bullet-1 .bullet-range-1 {
  fill: rgba(96, 125, 139, 0.8);
}
.bullet-1 .bullet-range-2 {
  fill: rgba(96, 125, 139, 0.4);
}
.bullet-1 .bullet-range-3 {
  fill: #607d8b;
}
.bullet-1 .bullet-measure-1 {
  fill: rgba(84, 110, 122, 0.9);
}
.bullet-1 .bullet-measure-2 {
  fill: #fff;
}
.bullet-1 .bullet-marker {
  stroke: #37474f;
}
.bullet-2 .bullet-range-1 {
  fill: rgba(255, 112, 67, 0.6);
}
.bullet-2 .bullet-range-2 {
  fill: rgba(255, 112, 67, 0.3);
}
.bullet-2 .bullet-range-3 {
  fill: #ff7043;
}
.bullet-2 .bullet-measure-1 {
  fill: rgba(244, 81, 30, 0.9);
}
.bullet-2 .bullet-measure-2 {
  fill: #fff;
}
.bullet-2 .bullet-marker {
  stroke: #d84315;
}
.bullet-3 .bullet-range-1 {
  fill: rgba(102, 187, 106, 0.7);
}
.bullet-3 .bullet-range-2 {
  fill: rgba(102, 187, 106, 0.35);
}
.bullet-3 .bullet-range-3 {
  fill: #66bb6a;
}
.bullet-3 .bullet-measure-1 {
  fill: rgba(67, 160, 71, 0.9);
}
.bullet-3 .bullet-measure-2 {
  fill: #fff;
}
.bullet-3 .bullet-marker {
  stroke: #2e7d32;
}
.counter-icon {
  font-size: 32px;
  position: absolute;
  left: 50%;
  margin-left: -16px;
}
/* ------------------------------------------------------------------------------
 *
 *  # Sparklines library
 *
 *  Generate sparklines (small inline charts) directly in the browser
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.jqsfield {
  font-size: 12px !important;
  font-family: 'Roboto', sans-serif !important;
}
.jqstooltip {
  font-size: 12px !important;
  font-family: 'Roboto', sans-serif !important;
  padding: 7px !important;
  line-height: 1.6666667 !important;
  border: 0 !important;
  border-radius: 3px !important;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
/* ------------------------------------------------------------------------------
 *
 *  # Task manager
 *
 *  Styles related to task manager - detailed task, list and grid
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.timer-weekdays {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.timer-weekdays > li {
  display: inline-block;
  margin: 2px 0;
}
.timer-weekdays > li > a {
  display: block;
  margin: 0 1px;
}
.timer-weekdays .label-default {
  background-color: #ccc;
  border-color: #ccc;
}
.timer-weekdays .label-default:hover,
.timer-weekdays .label-default:focus {
  border-color: #999999;
}
.timer {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}
.timer > li {
  font-size: 46px;
  text-align: center;
  display: inline-block;
  color: #555555;
  font-weight: 300;
}
.timer > li.dots {
  font-size: 25px;
  vertical-align: top;
  padding: 16.66666667px 0;
}
.timer > li > span {
  display: block;
  font-size: 13px;
}
.task-details {
  float: right;
  margin: 0;
}
@media (max-width: 1024px) {
  .task-details {
    float: none;
    margin-top: 15px;
    list-style: none;
    padding: 0;
  }
  .task-details > li {
    float: left;
    position: relative;
    padding-right: 20px;
    margin-top: 0;
  }
  .task-details > li:before {
    content: '•';
    position: absolute;
    right: 8px;
    color: #999;
  }
  .task-details > li:last-child:before {
    content: none;
  }
}
@media (max-width: 768px) {
  .task-details > li {
    float: none;
  }
  .task-details > li:before {
    content: none;
  }
  .task-details > li + li {
    margin-top: 10px;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Invoice template
 *
 *  General styles for invoice, mainly responsive setup
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
@media (min-width: 769px) {
  .invoice-details,
  .invoice-payment-details > li span {
    float: right;
    text-align: right;
  }
}
.invoice-grid ul,
.invoice-grid ol {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .invoice-grid .text-right {
    text-align: inherit;
  }
  .invoice-grid [class*=col-] + [class*=col-] {
    margin-top: 20px;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # User list
 *
 *  Styles for user lists - layouts, contact details etc.
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.contact-details {
  padding: 15px;
  padding-left: 30px;
  background-color: #fcfcfc;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
.media-list-bordered .contact-details {
  border-bottom: 0;
}
.panel-body > .media-list .contact-details {
  padding-left: 15px;
  background-color: transparent;
  margin-top: 15px;
}
.panel-body > .media-list-bordered .contact-details {
  padding-bottom: 0;
}
.contact-details > ul,
.contact-details > ul > li:first-child {
  margin: 0;
}
/* ------------------------------------------------------------------------------
 *
 *  # User profile
 *
 *  Styles for all user profile layouts
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.profile-cover {
  position: relative;
}
.profile-cover .media {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}
.profile-cover .media .btn-default {
  border-color: #fcfcfc;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
.profile-cover .media-body,
.profile-cover small {
  color: #fff;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}
@media (max-width: 768px) {
  .profile-cover .media {
    text-align: center;
    margin-top: 0;
    bottom: auto;
    top: 40px;
  }
  .profile-cover .media-left,
  .profile-cover .media-right {
    padding: 0;
    display: block;
    overflow-x: auto;
  }
  .profile-cover .media-body {
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }
  .profile-cover .media-body > h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
.profile-cover-img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
}
@media (max-width: 768px) {
  .profile-cover-img {
    height: 320px;
  }
}
.media .profile-thumb img {
  width: 100px;
  height: 100px;
  border: 3px solid #fff;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
/* ------------------------------------------------------------------------------
 *
 *  # Login and related forms
 *
 *  Styles related to user login - logins, registration, password revovery, unlock etc.
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.login-container {
  display: table;
  width: 100%;
}
.login-container .page-content {
  display: table-row;
  height: 100%;
}
.login-container .login-form {
  width: 320px;
}
.login-container .login-form,
.login-container .registration-form {
  margin: 0 auto 20px auto;
}
@media (max-width: 480px) {
  .login-container .login-form,
  .login-container .registration-form {
    width: 100%;
  }
}
.login-container .footer {
  left: 0;
  right: 0;
  text-align: center;
}
.login-container .content-wrapper {
  vertical-align: middle;
  display: table-cell;
}
@media (max-width: 480px) {
  .login-options,
  .login-options .text-right {
    text-align: center;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Timeline
 *
 *  Styles for timeline in 3 layouts: left, right and centered
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.timeline {
  position: relative;
  height: 100%;
}
.timeline:before,
.timeline:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  background-color: #cccccc;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  z-index: 1;
}
.timeline:after {
  top: auto;
  bottom: 0;
}
.timeline-container {
  position: relative;
  padding-top: 10px;
  margin-top: -10px;
  padding-bottom: 1px;
}
.timeline-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  background-color: #cccccc;
  height: 100%;
  width: 2px;
  z-index: -1;
}
.timeline-row {
  position: relative;
}
.timeline-content {
  margin-bottom: 20px;
}
.timeline-date {
  text-align: center;
  background-color: #f5f5f5;
  position: relative;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.timeline-date:before,
.timeline-date:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  background-color: #cccccc;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  z-index: 1;
}
.timeline-date:after {
  top: auto;
  bottom: 0;
}
.panel .timeline-date {
  background-color: #ffffff;
}
.timeline-time {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f5f5f5;
  position: relative;
  margin-bottom: 20px;
}
.timeline-time:before,
.timeline-time:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  background-color: #cccccc;
  height: 4px;
  width: 4px;
  border-radius: 50%;
}
.timeline-time:after {
  top: auto;
  bottom: 0;
}
.panel .timeline-time {
  background-color: #ffffff;
}
.timeline-time span {
  display: block;
  color: #999999;
  font-size: 12px;
}
.timeline-time span:before {
  content: '•';
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 1px;
}
.timeline-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: 0 auto 20px auto;
  background-color: #fff;
  border: 4px solid #f5f5f5;
}
.panel .timeline-icon {
  border-color: #ffffff;
}
.timeline-icon div {
  border-radius: 50%;
  height: 100%;
  -webkit-box-shadow: 0 0 0 2px #cccccc inset;
  box-shadow: 0 0 0 2px #cccccc inset;
}
.timeline-icon div[class*=bg-] {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.timeline-icon div > i {
  display: block;
  line-height: 40px;
  top: 0;
}
.timeline-icon img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
@media (min-width: 1025px) {
  .timeline-center .timeline-row {
    margin-right: 50%;
    padding-right: 44px;
  }
  .timeline-center .post-even {
    margin-right: 0;
    padding-right: 0;
    margin-left: 50%;
    padding-left: 44px;
  }
  .timeline-center .post-full {
    margin: 0;
    padding: 0;
  }
  .timeline-center .post-full .timeline-content {
    position: relative;
  }
  .timeline-center .post-full .timeline-content:before,
  .timeline-center .post-full .timeline-content:after {
    content: '';
    border-style: solid;
    border-color: #f5f5f5;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
  }
  .timeline-center .post-full .timeline-content:before {
    border-width: 4px 0 0 0;
    top: -5px;
  }
  .timeline-center .post-full .timeline-content:after {
    border-width: 0 0 4px 0;
    bottom: -5px;
  }
  .timeline-left .timeline-container:after,
  .timeline-left:before,
  .timeline-left:after,
  .timeline-left .timeline-date:before,
  .timeline-left .timeline-date:after {
    left: 24px;
  }
  .timeline-left .timeline-row,
  .timeline-left .timeline-date {
    padding-left: 68px;
  }
  .timeline-right .timeline-container:after,
  .timeline-right:before,
  .timeline-right:after,
  .timeline-right .timeline-date:before,
  .timeline-right .timeline-date:after {
    left: auto;
    right: 24px;
    margin-left: 0;
    margin-right: -2px;
  }
  .timeline-right .timeline-container:after {
    margin-right: -1px;
  }
  .timeline-right .timeline-row,
  .timeline-right .timeline-date {
    padding-right: 68px;
  }
  .timeline-left .timeline-date,
  .timeline-right .timeline-date {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .timeline-icon {
    position: absolute;
    top: 10px;
  }
  .timeline-icon:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    height: 2px;
    width: 16px;
    background-color: #cccccc;
    z-index: 1;
  }
  .timeline-left .timeline-icon {
    left: 0;
  }
  .timeline-left .timeline-icon:after {
    left: 100%;
    margin-left: 4px;
  }
  .timeline-right .timeline-icon {
    right: 0;
  }
  .timeline-right .timeline-icon:after {
    right: 100%;
    margin-right: 4px;
  }
  .timeline-center .timeline-row:not(.post-full) .timeline-icon {
    left: 100%;
    margin-left: -24px;
  }
  .timeline-center .timeline-row.post-even .timeline-icon {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: -24px;
  }
  .timeline-center .timeline-icon:after {
    right: 100%;
    margin-right: 4px;
  }
  .timeline-center .post-even .timeline-icon:after {
    left: 100%;
    margin-left: 4px;
  }
  .timeline-center .post-full .timeline-icon {
    position: static;
  }
  .timeline-center .post-full .timeline-icon:after {
    content: none;
  }
  .timeline-time {
    padding: 0;
    text-align: inherit;
    background-color: transparent;
  }
  .timeline-time:before {
    content: none;
  }
  .timeline-left .timeline-time,
  .timeline-right .timeline-time {
    padding-top: 10px;
    margin-bottom: 20px;
    padding-left: 10px;
  }
  .timeline-right .timeline-time {
    text-align: right;
    padding-left: 0;
    padding-right: 10px;
  }
  .timeline-center .timeline-time,
  .timeline-center .post-full .timeline-time {
    position: absolute;
    left: 100%;
    top: 15px;
    width: 100%;
    padding-left: 44px;
  }
  .timeline-center .post-even .timeline-time,
  .timeline-center .post-full .timeline-time {
    left: auto;
    right: 100%;
    padding-left: 0;
    padding-right: 44px;
    text-align: right;
  }
  .timeline-center .post-full .timeline-time {
    right: 50%;
    top: 5px;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Chat layouts
 *
 *  Conversation chat styles - layouts, chat elements, colors, options
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.chat-list,
.chat-stacked {
  max-height: 520px;
  overflow: auto;
}
.chat-list .media-left {
  padding-right: 20px;
}
.chat-list .media-right {
  padding-left: 20px;
}
.chat-list .media-content {
  position: relative;
  padding: 10px 12px;
  width: auto;
  border-radius: 3px;
  display: inline-block;
}
.chat-list .media-content:not([class*=bg-]) {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.chat-list .media-content > i {
  top: 0;
  margin: 2px;
}
.chat-list .reversed .media-content {
  text-align: left;
  color: #fff;
}
.chat-list .reversed .media-content:not([class*=bg-]) {
  background-color: #42a5f5;
  border-color: #42a5f5;
}
.chat-list .reversed .media-content > a {
  color: #fff;
}
.chat-list .reversed .media-body {
  text-align: right;
}
.chat-list .date-step.media {
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  color: #999;
}
.chat-list .chat-thumbs img {
  margin-top: 5px;
  margin-bottom: 5px;
  max-height: 100px;
  height: auto;
  max-width: 100%;
  display: inline-block;
  border-radius: 3px;
}
.chat-list .media-content:before,
.chat-list .reversed .media-content:before {
  content: '';
  left: -5px;
  top: 15px;
  position: absolute;
  margin-left: 0;
}
.chat-list .media-content:before {
  border-top: 5px solid transparent;
  border-right: 5px solid;
  border-right-color: inherit;
  border-bottom: 5px solid transparent;
}
.chat-list .reversed .media-content:before {
  left: auto;
  right: -5px;
  border-right: 0;
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-left-color: inherit;
  border-bottom: 5px solid transparent;
}
@media (min-width: 769px) {
  .chat-list .media {
    margin-right: 20%;
  }
  .chat-list .media.reversed {
    margin-right: 0;
    margin-left: 20%;
  }
}
.chat-list-inverse .media-content:not([class*=bg-]) {
  color: #fff;
  background-color: #42a5f5;
  border-color: #42a5f5;
}
.chat-list-inverse .media-content:before {
  border-right: 0;
  border-top: 5px solid transparent;
  border-right: 5px solid #42a5f5;
  border-bottom: 5px solid transparent;
}
.chat-list-inverse .media-content > a {
  color: #fff;
}
.chat-list-inverse .reversed .media-content:not([class*=bg-]) {
  background-color: #f5f5f5;
  color: inherit;
}
.chat-list-inverse .reversed .media-content:before {
  border-top: 5px solid transparent;
  border-left: 5px solid #f5f5f5;
  border-bottom: 5px solid transparent;
}
/* ------------------------------------------------------------------------------
 *
 *  # Search
 *
 *  Styles for search results and input
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.panel .main-search input[type=text] {
  background-color: #fafafa;
}
.search-results-list .list-inline {
  margin-bottom: 5px;
}
.related-searches {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
.website-search-results {
  padding: 20px;
  padding-bottom: 0;
  border-bottom: 1px solid #eeeeee;
}
.media-preview {
  width: auto;
  height: auto;
  max-width: none;
  max-height: 90px;
}
@media (max-width: 768px) {
  .search-option-buttons {
    text-align: center;
  }
  .search-option-buttons > .text-right {
    text-align: inherit;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Error pages
 *
 *  Styles for error and offline pages
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.error-title {
  color: #fff;
  font-size: 200px;
  line-height: 1;
  margin-bottom: 20px;
  font-weight: 300;
  text-stroke: 1px transparent;
  display: block;
  text-shadow: 0 1px 0 #cccccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbbbbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaaaaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
}
@media (max-width: 768px) {
  .error-title {
    font-size: 130px;
  }
}
.offline-title {
  font-size: 120px;
}
@media (max-width: 768px) {
  .offline-title {
    font-size: 90px;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Heading elmeents
*
*  Display default and custom components in page header and panel heading
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.heading-elements-toggle,
.breadcrumb-elements-toggle {
  cursor: pointer;
  display: block;
  line-height: 1;
  position: absolute;
  top: 50%;
  margin-top: -8px;
}
.heading-elements-toggle,
.breadcrumb-elements-toggle,
.heading-elements-toggle:hover,
.breadcrumb-elements-toggle:hover,
.heading-elements-toggle:focus,
.breadcrumb-elements-toggle:focus {
  color: inherit;
}
.heading-elements-toggle > i,
.breadcrumb-elements-toggle > i {
  top: 0;
}
@media (min-width: 769px) {
  .heading-elements-toggle,
  .breadcrumb-elements-toggle {
    display: none;
  }
}
.heading-elements-toggle {
  right: 20px;
}
.breadcrumb-elements-toggle {
  right: 20px;
}
.breadcrumb-line-component .breadcrumb-elements-toggle {
  right: 15px;
}
.heading-elements {
  background-color: inherit;
  position: absolute;
  top: 50%;
  right: 20px;
  height: 36px;
  margin-top: -18px;
}
.panel-body > .heading-elements {
  top: 0;
  margin-top: 14px;
  z-index: 10;
}
@media (max-width: 768px) {
  .panel-body > .heading-elements-toggle {
    top: 20px;
    margin-top: 0;
  }
  .panel-body > .heading-elements.visible {
    top: 56px;
    background-color: #ffffff;
  }
}
.heading-elements .heading-btn + .heading-btn,
.page-header .heading-elements .icons-list > li + li,
.thumbnail-heading .heading-elements .icons-list > li + li {
  margin-left: 10px;
}
.heading-elements .pagination,
.heading-elements .pager {
  margin-top: 0;
  margin-bottom: 0;
}
.heading-elements .breadcrumb {
  padding-top: 8px;
  padding-bottom: 8px;
}
.heading-elements.panel-tabs .nav > li > a,
.heading-elements.panel-pills .nav > li > a {
  text-align: left;
}
.heading-elements .btn-float.btn-link {
  padding-top: 0;
  padding-bottom: 0;
}
.heading-elements .daterange-custom {
  margin-top: 4px;
}
.heading-elements .heading-btn-group {
  font-size: 0;
}
.heading-elements .heading-btn-group > .btn + .btn:not(.btn-link) {
  margin-left: 10px;
}
@media (max-width: 768px) {
  .heading-elements {
    text-align: center;
  }
  .heading-elements .heading-text,
  .heading-elements .heading-btn,
  .heading-elements .heading-btn-group > .btn,
  .heading-elements .ui-slider,
  .heading-elements .noui-slider,
  .heading-elements .nav-tabs,
  .heading-elements .nav-pills,
  .heading-elements .pagination,
  .heading-elements .progress,
  .heading-elements .icons-list,
  .heading-elements .pager,
  .heading-elements .breadcrumb,
  .heading-elements .daterange-custom,
  .heading-elements .heading-form .form-group,
  .heading-elements > .btn-group {
    margin-bottom: 20px;
  }
  .heading-elements .heading-text:not(.label):not(.badge) {
    display: block;
  }
  .heading-elements .select2-container,
  .heading-elements .selectboxit-container,
  .heading-elements .selectboxit-options,
  .heading-elements .multiselect + .btn-group,
  .heading-elements .bootstrap-select {
    width: 100%!important;
  }
  .heading-elements .input-group,
  .heading-elements .input-group-btn,
  .heading-elements .btn-group,
  .heading-elements .dropdown,
  .heading-elements .dropup {
    position: static;
  }
  .heading-elements .dropdown-menu {
    left: -1px;
    right: -1px;
    margin-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}
@media (min-width: 769px) {
  .heading-elements .heading-text,
  .heading-elements .heading-btn,
  .heading-elements .ui-slider,
  .heading-elements .noui-slider,
  .heading-elements .nav-tabs,
  .heading-elements .nav-pills,
  .heading-elements .pagination,
  .heading-elements .progress,
  .heading-elements .icons-list,
  .heading-elements .breadcrumb,
  .heading-elements .pager,
  .heading-elements .heading-form,
  .heading-elements .daterange-custom,
  .heading-elements > .btn-group {
    float: left;
    margin-left: 20px;
  }
  .heading-elements .heading-text {
    display: inline-block;
  }
  .heading-elements .heading-text + .heading-text {
    margin-left: 20px;
  }
  .heading-elements .selectbox-fixed + .selectboxit-container,
  .heading-elements .selectbox-fixed + .selectboxit-options,
  .heading-elements .progress,
  .heading-elements .ui-slider,
  .heading-elements .noui-slider,
  .heading-elements .uploader,
  .heading-elements .input-group,
  .heading-elements .selectboxit-container .selectboxit-options,
  .heading-elements .heading-form .form-control {
    width: 220px;
  }
  .heading-elements .select-sm,
  .heading-elements .input-sm,
  .heading-elements .input-group-sm,
  .heading-elements .uploader-sm,
  .heading-elements .pagination-sm,
  .heading-elements .pager-sm,
  .heading-elements .selectbox-sm + .selectboxit-container,
  .heading-elements .btn-sm,
  .heading-elements .btn-group-sm > .btn {
    margin-top: 1px;
  }
  .heading-elements .select-xs,
  .heading-elements .input-xs,
  .heading-elements .input-group-xs,
  .heading-elements .uploader-xs,
  .heading-elements .pagination-xs,
  .heading-elements .pager-xs,
  .heading-elements .selectbox-xs + .selectboxit-container,
  .heading-elements .btn-xs,
  .heading-elements .btn-group-xs > .btn {
    margin-top: 2px;
  }
  .heading-elements .btn-float {
    margin-top: -6px;
  }
  .heading-elements .btn-float.has-text {
    margin-top: -17.5px;
  }
  .heading-elements .btn-float.btn-link {
    margin-top: -3.5px;
  }
  .heading-elements .ui-slider,
  .heading-elements .noui-slider {
    margin-top: 15px;
  }
  .heading-elements .ui-slider-lg,
  .heading-elements .noui-slider-lg {
    margin-top: 14px;
  }
  .heading-elements .ui-slider-sm,
  .heading-elements .noui-slider-sm {
    margin-top: 16px;
  }
  .heading-elements .ui-slider-xs,
  .heading-elements .noui-slider-xs {
    margin-top: 17px;
  }
  .heading-elements .progress {
    margin-top: 9px;
  }
  .heading-elements .progress-lg {
    margin-top: 7px;
  }
  .heading-elements .progress-sm {
    margin-top: 11px;
  }
  .heading-elements .progress-xs {
    margin-top: 13px;
  }
  .heading-elements .progress-xxs {
    margin-top: 15px;
  }
  .heading-elements .progress-micro {
    margin-top: 17px;
  }
  .heading-elements .icons-list {
    margin-top: 9px;
  }
  .heading-elements .heading-text {
    margin-top: 8px;
  }
  .heading-elements.panel-tabs {
    bottom: 0;
    top: auto;
    height: auto;
    margin-top: 0;
  }
  .heading-elements.panel-tabs > .nav-tabs {
    border-bottom: 0;
    margin-bottom: 0;
  }
  .heading-elements.panel-tabs > .nav-tabs > li > a {
    padding: 15px 20px;
  }
  .panel-flat .heading-elements.panel-tabs {
    bottom: auto;
    top: 0;
  }
  .panel-flat .heading-elements.panel-tabs > .nav-tabs > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .heading-elements.panel-pills > .nav-pills {
    margin-bottom: 0;
    margin-top: -1px;
  }
  .heading-elements.panel-pills .nav-sm {
    margin-top: 1px;
  }
  .heading-elements.panel-pills .nav-xs {
    margin-top: 3px;
  }
}
.heading-form .form-group {
  margin-bottom: 0;
}
.heading-form .checkbox-switchery,
.heading-form .checkbox-switchery[class*="switchery-"] {
  margin-bottom: 0;
}
.heading-form .input-group .form-control {
  width: 100%;
  margin-top: 0;
}
.heading-form .input-group.input-group-sm .btn,
.heading-form .input-group.input-group-xs .btn {
  margin-top: 0;
}
@media (min-width: 769px) {
  .heading-form .form-group {
    display: inline-block;
  }
  .heading-form .form-group + .form-group {
    margin-left: 15px;
  }
  .heading-form .checkbox-inline,
  .heading-form .radio-inline {
    margin-top: 8px;
  }
  .heading-form .checkbox-switch {
    margin-top: 2px;
  }
  .heading-form .checkbox-switch-sm {
    margin-top: 3px;
  }
  .heading-form .checkbox-switch-xs {
    margin-top: 4px;
  }
}
@media (max-width: 768px) {
  .heading-elements,
  .breadcrumb-elements {
    display: none;
  }
  .heading-elements.visible,
  .breadcrumb-elements.visible {
    display: block;
    margin-top: 0;
    top: 100%;
    height: auto;
    left: -1px;
    right: -1px;
    padding: 20px;
    padding-bottom: 0;
    border: 1px solid #dddddd;
    z-index: 997;
  }
  .panel[class*=bg-] .heading-elements.visible,
  .panel-heading[class*=bg-] .heading-elements.visible {
    border-width: 1px 0;
    border-color: rgba(255, 255, 255, 0.5);
    left: 0;
    right: 0;
    background-color: inherit;
  }
  .page-header .heading-elements.visible {
    border-width: 1px 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  .breadcrumb-elements.visible {
    padding: 0;
    z-index: 9;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Helper classes
*
*  Custom helper classes
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.no-edge-top {
  top: 0;
}
.no-edge-bottom {
  bottom: 0;
}
.no-edge-left {
  left: 0;
}
.no-edge-right {
  right: 0;
}
@media (min-width: 1200px) {
  .pull-right-lg {
    float: right;
  }
}
@media (min-width: 1025px) {
  .pull-right-md {
    float: right;
  }
}
@media (min-width: 769px) {
  .pull-right-sm {
    float: right;
  }
}
@media (min-width: 480px) {
  .pull-right-xs {
    float: right;
  }
}
.valign-top {
  vertical-align: top;
}
.valign-middle {
  vertical-align: middle;
}
.valign-bottom {
  vertical-align: bottom;
}
.valign-baseline {
  vertical-align: baseline;
}
.valign-text-top {
  vertical-align: top;
}
.valign-text-bottom {
  vertical-align: text-bottom;
}
.position-relative {
  position: relative;
}
.position-static {
  position: static;
}
.display-block,
label.display-block {
  display: block;
}
.display-inline-block {
  display: inline-block;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.cursor-default {
  cursor: default;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.overflow-auto {
  overflow: auto;
}
.content-group-xs {
  margin-bottom: 10px !important;
}
.content-group-sm {
  margin-bottom: 15px !important;
}
.content-group {
  margin-bottom: 20px !important;
}
.content-group-lg {
  margin-bottom: 30px !important;
}
.no-margin {
  margin: 0!important;
}
.no-margin-top {
  margin-top: 0!important;
}
.no-margin-bottom {
  margin-bottom: 0!important;
}
.no-margin-left {
  margin-left: 0!important;
}
.no-margin-right {
  margin-right: 0!important;
}
.m-5 {
  margin: 5px!important;
}
.m-10 {
  margin: 10px!important;
}
.m-15 {
  margin: 15px!important;
}
.m-20 {
  margin: 20px!important;
}
.mt-5 {
  margin-top: 5px!important;
}
.mt-10 {
  margin-top: 10px!important;
}
.mt-15 {
  margin-top: 15px!important;
}
.mt-20 {
  margin-top: 20px!important;
}
.mb-5 {
  margin-bottom: 5px!important;
}
.mb-10 {
  margin-bottom: 10px!important;
}
.mb-15 {
  margin-bottom: 15px!important;
}
.mb-20 {
  margin-bottom: 20px!important;
}
.ml-5 {
  margin-left: 5px!important;
}
.ml-10 {
  margin-left: 10px!important;
}
.ml-15 {
  margin-left: 15px!important;
}
.ml-20 {
  margin-left: 20px!important;
}
.mr-5 {
  margin-right: 5px!important;
}
.mr-10 {
  margin-right: 10px!important;
}
.mr-15 {
  margin-right: 15px!important;
}
.mr-20 {
  margin-right: 20px!important;
}
.no-padding {
  padding: 0!important;
}
.no-padding-top {
  padding-top: 0!important;
}
.no-padding-bottom {
  padding-bottom: 0!important;
}
.no-padding-left {
  padding-left: 0!important;
}
.no-padding-right {
  padding-right: 0!important;
}
.p-5 {
  padding: 5px!important;
}
.p-10 {
  padding: 10px!important;
}
.p-15 {
  padding: 15px!important;
}
.p-20 {
  padding: 20px!important;
}
.pt-5 {
  padding-top: 5px!important;
}
.pt-10 {
  padding-top: 10px!important;
}
.pt-15 {
  padding-top: 15px!important;
}
.pt-20 {
  padding-top: 20px!important;
}
.pb-5 {
  padding-bottom: 5px!important;
}
.pb-10 {
  padding-bottom: 10px!important;
}
.pb-15 {
  padding-bottom: 15px!important;
}
.pb-20 {
  padding-bottom: 20px!important;
}
.pl-5 {
  padding-left: 5px!important;
}
.pl-10 {
  padding-left: 10px!important;
}
.pl-15 {
  padding-left: 15px!important;
}
.pl-20 {
  padding-left: 20px!important;
}
.pr-5 {
  padding-right: 5px!important;
}
.pr-10 {
  padding-right: 10px!important;
}
.pr-15 {
  padding-right: 15px!important;
}
.pr-20 {
  padding-right: 20px!important;
}
.no-border {
  border: 0!important;
}
.no-border-top {
  border-top: 0!important;
}
.no-border-bottom {
  border-bottom: 0!important;
}
.no-border-left {
  border-left: 0!important;
}
.no-border-right {
  border-right: 0!important;
}
.no-border-radius {
  border-radius: 0;
}
.no-border-radius-top {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.no-border-radius-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.no-border-radius-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.no-border-radius-right {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.border-top {
  border-top: 1px solid;
}
.border-bottom {
  border-bottom: 1px solid;
}
.border-left {
  border-left: 1px solid;
}
.border-right {
  border-right: 1px solid;
}
.border-lg {
  border-width: 2px;
}
.border-top-lg {
  border-top: 2px solid;
}
.border-bottom-lg {
  border-bottom: 2px solid;
}
.border-left-lg {
  border-left: 2px solid;
}
.border-right-lg {
  border-right: 2px solid;
}
.border-xlg {
  border-width: 3px;
}
.border-top-xlg {
  border-top: 3px solid;
}
.border-bottom-xlg {
  border-bottom: 3px solid;
}
.border-left-xlg {
  border-left: 3px solid;
}
.border-right-xlg {
  border-right: 3px solid;
}
.full-width {
  width: 100%;
}
@media (min-width: 769px) {
  .width-200 {
    min-width: 200px;
  }
  .width-250 {
    min-width: 250px;
  }
  .width-300 {
    min-width: 300px;
  }
  .width-350 {
    min-width: 350px;
  }
  .width-400 {
    min-width: 400px;
  }
  .width-450 {
    min-width: 450px;
  }
  .width-500 {
    min-width: 500px;
  }
  .width-550 {
    min-width: 550px;
  }
  .width-600 {
    min-width: 600px;
  }
  .width-650 {
    min-width: 650px;
  }
}
.img-lg {
  width: 44px!important;
  height: 44px!important;
}
.img-sm {
  width: 36px!important;
  height: 36px!important;
}
.img-xs {
  width: 32px!important;
  height: 32px!important;
}
/* ------------------------------------------------------------------------------
*
*  # Snippets
*
*  Custom snippets set
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.progress-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.progress-list > li + li {
  margin-top: 20px;
}
.progress-list > li > label {
  display: block;
}
.progress-list > li span {
  float: right;
  display: block;
}
